import React, { useEffect, useState } from 'react';
import InvitUser from "./components/InvitUser";
import CreateOrganisme from "./components/CreateOrganisme";
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import NavbarAdmin from "./components/NavbarAdmin";
import SwitchOrganisme from "./components/SwitchOrganisme";
import SiphonnageDataOrg from './components/SiphonnageDataOrg';
import CreateBudget from "./components/CreateBudget";
import DeleteOrganisme from "./components/DeleteOrganisme";
import SetupYearsApplication from "./components/SetupYearsApplication";
import { useHistory } from 'react-router-dom';
import axios from 'axios';


const Adminpage = (props) =>
{
    let history = useHistory();
    const purple = '#6571FE'

    useEffect(() => {
		props.getUser();
        console.log(props);
    }, [])


    function tout_faire()
    {
        // axios.get("https://app-slfinance.fr/api/cron_session_token.php")
        // .then((r) => {
        //     console.log(r.data)
        // })
        // .catch((err) => {
        //     console.log(err)
        // })
    }


	return (
        <Access needConnection={true} type_user={[1]}>
            <Navbar />
            <NavbarAdmin />
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <div style={{display: 'flex'}}>

                        <div style={{flex: 1, textAlign: 'center'}}>
                            <div style={{margin: 20}}>
                                <SwitchOrganisme user={props.user} getUser={props.getUser}/>
                            </div>
                            <div style={{flex: 1, margin: 20}}>
                                <InvitUser />
                            </div>

                            {props.user && props.user.email === 'victor.castera@strategies-locales.fr'?
                            <div>
                                <div style={{margin: 20}}>
                                    <button className='shadow-md p-2 rounded-md' style={{backgroundColor: purple, color: 'white', fontSize: 14, fontSize: 14}}
                                    onClick={() => {tout_faire()}}>Le bouton à tout faire
                                    </button>
                                </div>
                                <div style={{margin: 20}}>
                                    <button className='shadow-md p-2 rounded-md' style={{backgroundColor: purple, color: 'white', fontSize: 14, fontSize: 14}}
                                    onClick={() => {history.push('/massivemailing')}}>Massive Mailing
                                    </button>
                                </div>
                            </div>
                            :null}
                        </div>


                        <div style={{flex: 1}}>
                            <div style={{margin: 20}}>
                                <CreateOrganisme />
                            </div>
                            <div style={{margin: 20}}>
                                <CreateBudget />
                            </div>
                        </div>


                        <div style={{flex: 1, textAlign: 'center'}}>
                            <div style={{margin: 20, border: '2px solid orange', borderRadius: 5}}>
                                <SiphonnageDataOrg user={props.user} getUser={props.getUser}/>
                            </div>
                            <div style={{flex: 1, margin: 20, border: '2px solid orange', borderRadius: 5}}>
                                <SetupYearsApplication />
                            </div>
                            <div style={{margin: 20, border: '2px solid red', borderRadius: 5}}>
                                <DeleteOrganisme />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Access>
	);
}

export default Adminpage;
