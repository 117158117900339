import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { addSpace, rescueHtml } from '../components/Tools';


const Page11RestitutionInv = (props) =>
{
    const blue = '#069BD3'
    const smoothGrey = '#f4f4f4'

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={Logo} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{marginLeft: 25, borderBottom: '1px solid '+blue}}>
                <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginLeft: 25, height: '70%'}}>
                <div style={{flex: 1, border: '1px solid '+blue, borderRadius: 2, marginTop: 25, marginRight: 25}}>
                    <div style={{flex: 1, backgroundColor: blue, color: 'white', display: 'flex'}}>

                        <div style={{flex: 3.5, textAlign: 'center'}}>
                            <p style={{fontSize: 13}}>Montants en €</p>
                        </div>

                        {props.annees !== null?
                        props.annees.map((an, j) =>
                        <div key={j} style={{flex: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                            <p style={{fontSize: 13}}>{an}</p>
                        </div>
                        ):null}

                    </div>

                    {props.restitution !== null?
                    props.restitution.map((line, i) => 
                    <div key={i} style={line[0].chapitre === '1'?{flex: 1, borderTop: '1px solid white', display: 'flex', backgroundColor: blue}:i%2?{flex: 1, borderTop: '1px solid white', display: 'flex', backgroundColor: smoothGrey}:{flex: 1, borderTop: '1px solid white', display: 'flex'}}>
                        <div style={{flex: 3.5, justifyContent: 'center'}}>
                            <p style={line[0].matricule === 'RetroInv60' || line[0].matricule === 'RetroInv65' || line[0].matricule === 'RetroInv110'?{fontSize: 13, paddingLeft: (line[0].indentation + 1)*2, color: blue}:line[0].chapitre === '1'?{fontSize: 13, paddingLeft: 2, color: 'white'}:line[0].indentation === '2'?{fontSize: 10, paddingLeft: (line[0].indentation + 1)*2, fontStyle: 'italic'}:{fontSize: 11, paddingLeft: (line[0].indentation + 1)*2}}>{line[0].intitule}</p>
                        </div>
                        
                        {line.map((col, l) =>
                        l !== 0?
                        <div key={l} style={{flex: 1, justifyContent: 'center', borderLeft: '1px solid white'}}>
                            <p style={line[0].matricule === 'RetroInv60' || line[0].matricule === 'RetroInv65' || line[0].matricule === 'RetroInv110'?{fontSize: 13, textAlign: 'right', paddingRight: 2, color: blue}:line[0].chapitre === '1'?{fontSize: 13, textAlign: 'right', paddingRight: 2, color: 'white'}:line[0].indentation === '2'?{fontSize: 10, textAlign: 'right', paddingRight: 2, fontStyle: 'italic'}:{fontSize: 11, textAlign: 'right', paddingRight: 2}}>
                                {col[1] != 0?
                                    line[0].matricule === 'RetroInv130' || line[0].matricule === 'ProspInv130'
                                    || line[0].matricule === 'M4RetroInv130' || line[0].matricule === 'M4ProspInv115'
                                    || line[0].matricule === 'M41RetroInv130' || line[0].matricule === 'M41ProspInv115'
                                    || line[0].matricule === 'M43RetroInv130' || line[0].matricule === 'M43ProspInv115'
                                    || line[0].matricule === 'M49RetroInv130' || line[0].matricule === 'M49ProspInv115'
                                    ?
                                        addSpace(parseFloat(Math.round(col[1]*100)/100))+' an(s)'
                                    :
                                    line[0].matricule === 'RetroInv135' || line[0].matricule === 'ProspInv135'
                                    || line[0].matricule === 'M4RetroInv135' || line[0].matricule === 'M4ProspInv120'
                                    || line[0].matricule === 'M41RetroInv135' || line[0].matricule === 'M41ProspInv120'
                                    || line[0].matricule === 'M43RetroInv135' || line[0].matricule === 'M43ProspInv120'
                                    || line[0].matricule === 'M49RetroInv135' || line[0].matricule === 'M49ProspInv120'?
                                        addSpace(parseFloat(Math.round(col[1])), true)+' %'
                                    :
                                    line[0].matricule === 'RetroInv140' || line[0].matricule === 'ProspInv140'
                                    || line[0].matricule === 'M4RetroInv140' || line[0].matricule === 'M4ProspInv125'
                                    || line[0].matricule === 'M41RetroInv140' || line[0].matricule === 'M41ProspInv125'
                                    || line[0].matricule === 'M43RetroInv140' || line[0].matricule === 'M43ProspInv125'
                                    || line[0].matricule === 'M49RetroInv140' || line[0].matricule === 'M49ProspInv125'?
                                        addSpace(parseFloat(Math.round(col[1])), true)+' %'
                                    :
                                    line[0].matricule === 'RetroInv145' || line[0].matricule === 'ProspInv145'
                                    || line[0].matricule === 'M4RetroInv145' || line[0].matricule === 'M4ProspInv130'
                                    || line[0].matricule === 'M41RetroInv145' || line[0].matricule === 'M41ProspInv130'
                                    || line[0].matricule === 'M43RetroInv145' || line[0].matricule === 'M43ProspInv130'
                                    || line[0].matricule === 'M49RetroInv145' || line[0].matricule === 'M49ProspInv130'?
                                        addSpace(parseFloat(Math.round(col[1])), true)+' jour(s)'
                                    :
                                        addSpace(parseFloat(Math.round(col[1])), true)
                                :
                                    '.'
                                }
                            </p>
                        </div>
                        :null)}
                    </div>
                    )
                    :
                    <div style={{flex: 10, borderTop: '1px solid white', display: 'flex'}}></div>
                    }
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
}
  
  export default Page11RestitutionInv;