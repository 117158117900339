import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import { ReactComponent as Trash } from '../Img/icon-trash.svg';
import Dialog from "./Dialog";
import { rescueHtml, lorem } from './Tools';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingLeft: 75,
        paddingRight: 75,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        overflow: 'hidden',
        background: "linear-gradient(#FAFAFA, #FFFFFF)"
    },
};


const ModalBlocNotes = (props) =>
{
    const purple = '#6571FE';
    
    const [aff, setAff] = useState(false);
    const [comment, setComment] = useState('');
    const [param, setParam] = useState('');
    const pathname = window.location.pathname;
    const width = window.innerWidth/1.5;
    const height = window.innerHeight/1.5;
    
    useEffect(() => {
        var param = '';
        if (pathname === '/saisie/retrospective/fct/recette') {
            param = 'retro_fct_rec';
        }
        else if (pathname === '/saisie/retrospective/fct/depense') {
            param = 'retro_fct_dep';
        }
        else if (pathname === '/saisie/retrospective/inv/recette') {
            param = 'retro_inv_rec';
        }
        else if (pathname === '/saisie/retrospective/inv/depense') {
            param = 'retro_inv_dep';
        }
        else if (pathname === '/saisie/retrospective/fiscalite') {
            param = 'retro_fiscalite';
        }
        else if (pathname === '/saisie/dette') {
            param = 'retro_dette';
        }
        else if (pathname === '/saisie/prospective/fct/recette') {
            param = 'prosp_fct_rec';
        }
        else if (pathname === '/saisie/prospective/fct/depense') {
            param = 'prosp_fct_dep';
        }
        else if (pathname === '/saisie/prospective/fiscalite') {
            param = 'prosp_fiscalite';
        }
        else if (pathname === '/tableaudebord' || pathname === '/graphtableaudebord') {
            param = 'tableau_bord';
        }
        else if (pathname === '/emprunts/prospective/manuel' || pathname === '/emprunts/auto') {
            param = 'emprunts';
        }
        else if (pathname === '/organismepage') {
            param = 'global';
        }
        setParam(param);

        var params = {
            'getby': true,
            'id_budget': props.user.id_budget,
            'column': param
        }
        axios.post('https://app-slfinance.fr/api/comments.php', qs.stringify(params))
        .then((res) => {
            setComment(res.data);
        })

    }, [props])

    function closeModal() {
        props.setModalVisible(false);
    }

    async function validation()
    {
        let tmp = '';
        if (comment) {
            tmp = comment
                .trim()
                .replace(/[ \t]+/g, ' ');
        }
        await setComment(tmp);

        await axios.post('https://app-slfinance.fr/api/comments.php', qs.stringify({
            'update': true,
            'id_budget': props.user.id_budget,
            'column': param,
            'value': tmp
        }))
    }

    return (
        <Modal
        isOpen={props.modalVisible}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={closeModal}
        >

            <p style={{fontWeight: 'bold', color: purple}}>Commentaire</p>
            <p>
                {pathname
                    .trim() // Supprime les espaces inutiles au début/fin
                    .replace(/\//g, ' ') // Remplace les '/' par des espaces
                    .replace('fct', 'fonctionnement')
                    .replace('inv', 'investissement')
                    .replace('depense', 'dépenses')
                    .replace('recette', 'recettes')
                    .replace('fiscalite', 'fiscalité')
                    .replace('organismepage', 'note générale')
                    .replace('tableaudebord', 'tableau de bord')
                    .replace('graphtableau de bord', 'graphiques tableau de bord')
                    .replace(/\s+/, ' ') // Remplace les espaces multiples par un seul espace
                    .replace(/^\s*(\S)/, (match, firstChar) => firstChar.toUpperCase()) // Met en majuscule la première lettre significative
                }
            </p>

            <textarea
            style={{marginTop: 25, width: width, height: height, padding: 5, borderRadius: 3, border: '1px solid #bdc3c7'}}
            value={comment?comment:''}
            onChange={(e) => {
                setComment(e.target.value);
            }}
            onBlur={() => {
                validation();
            }}
            />
            
        </Modal>
    );
};

export default ModalBlocNotes;