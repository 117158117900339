import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { addSpace, rescueHtml } from '../components/Tools';


const Page15FiscaliteRetro = (props) =>
{
    const blue = '#069BD3'
    const smoothGrey = '#f4f4f4'
    const blueTx = '#DEF3FF'
    const greyChapter = '#DBDBDB'

    function emptyLine(line)
    {
        var ret = true
        line[1].forEach((element, i) => {
            if (i > 0) {
                if (element[1][0] !== '' && element[1][0] !== '0' && element[1][0] !== 0) {
                    ret = false
                }
            }
        });
        return ret;
    }

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={Logo} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginLeft: 25, height: '80%', fontSize: 12}}>
                <div style={{margin: 25, border: '1px solid '+blue, borderRadius: 5}}>
                    <div style={{flex: 1, backgroundColor: blue, color: 'white', display: 'flex', fontSize: 14}}>

                        <div style={{flex: 1.6, textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: 2}}>
                            <p>Montants en €</p>
                        </div>

                        {props.annees?
                        props.annees.map((an, j) =>
                        <div key={j} style={{flex: 1, display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                            {j>0?
                            <div style={{flex: 0.7, borderLeft: '1px solid white'}}>
                                <p style={{}}>Evol %</p>
                            </div>
                            :null}
                            <div style={j === 0?{flex: 1, borderLeft: '1px solid white'}:{flex: 1}}>
                                <p style={{}}>{an}</p>
                            </div>
                        </div>

                        ):null}

                    </div>

                    {props.restitution?
                    props.restitution.map((line, i) =>
                    !emptyLine(line) || line[0].display === '1'?
                    <div key={i}
                    style={
                    line[0].nom === 'Bases d\'imposition' || line[0].nom === 'Taux d\'imposition' || line[0].nom === 'Produits d\'imposition'?
                        {flex: 1, display: 'flex', borderTop: '1px solid '+smoothGrey, backgroundColor: greyChapter}
                    :
                        {flex: 1, display: 'flex', borderTop: '1px solid '+smoothGrey}
                    }>
                        <div style={{flex: 1.6, justifyContent: 'center', marginLeft: 2}}>
                            <p style={
                            line[0].nom === 'Bases d\'imposition' || line[0].nom === 'Taux d\'imposition' || line[0].nom === 'Produits d\'imposition'?
                                {}
                            :
                                {marginLeft: 5}
                            }>{line[0].nom}</p>
                        </div>

                        {line[1].map((col, j) => 
                        <div key={j} style={{flex: 1, display: 'flex'}}>
                            {j>0?
                            <div style={
                            line[0].nom === 'Bases d\'imposition' || line[0].nom === 'Taux d\'imposition' || line[0].nom === 'Produits d\'imposition'?
                                {flex: 0.7, borderLeft: '1px solid '+smoothGrey, textAlign: 'center'}
                            :
                                {flex: 0.7, textAlign: 'center', backgroundColor: blueTx}
                            }>
                                <p style={{fontSize: 10, fontStyle: 'italic'}}>
                                    {line[1][j-1] && (line[0].nom !== 'Bases d\'imposition' && line[0].nom !== 'Taux d\'imposition')?
                                    addSpace(Math.round(line[1][j-1][1][1]*100)/100)+"%"
                                    :null}
                                </p>
                            </div>
                            :null}

                            <div style={{flex: 1, textAlign: 'right'}}>
                                <p style={{paddingRight: 3}}>
                                    {line[0].matricule.includes('TX')?
                                        col[1][0] != 0 && col[1][0] != ''?
                                            line[0].matricule === 'REITXTASCOM'?
                                                addSpace((col[1][0]))
                                            :
                                                addSpace((col[1][0]), true)+"%"
                                        :
                                            line[0].matricule !== 'REITX'?
                                                line[0].matricule === 'REITXTASCOM'?
                                                    '0.00'
                                                :
                                                    "0.00%"
                                            :
                                                null
                                    :
                                        col[1][0] != 0 && col[1][0] != ''?
                                            addSpace(Math.round(col[1][0]), true)
                                        :
                                            null
                                    }
                                    </p>
                            </div>
                        </div>
                        )}
                    </div>
                    :null
                    ):null}
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
}
  
  export default Page15FiscaliteRetro;