import React, { useEffect, useState } from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import axios from 'axios';
import qs from 'qs';
import { addSpace } from '../components/Tools.js';


const Page5BilanFinancier = (props) =>
{
    const blue = '#069BD3';
    const smoothGrey = '#f4f4f4';
    const greyChapter = '#DBDBDB';

    const fontsize = (props.height*12/890);
    const sizelogo = props.width/6;

    const [objet, setObjet] = useState([])

    async function mount()
    {
        await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
            'getSumIncidencesFinancieres': true,
            'annees': props.annees,
            'id_budget': props.user.id_budget,
            'hypothese': props.hypothese
        }))
        .then(async (res) => {
            var total = [];
            await res.data.forEach((element, i) => {
                total[i] = 0;
                element.forEach((elem, j) => {
                    if (j > 0) {
                        elem[1] = parseFloat(elem[1]);
                        total[i] += (elem[1]);
                    }
                });
            });
            await res.data.forEach((line, k) => {
                res.data[k].push(['Total', total[k]])
            });
            await setObjet(res.data)
        })
    }


    useEffect(() => {
        if (props.user.id_budget && props.hypothese && props.annees.length > 0) {
            mount();
        }
    }, [props.annees]);
    

    return (
        <div style={{width: '100%', height: '100%', padding: 20}}>
            
            {/* Header */}
            <div style={{display: 'flex', height: '20%'}}>
                <div style={{flex: 0.4, display: 'flex', marginLeft: 10}}>
                {props.logo && (
                    <img style={{maxHeight: props.height/8, borderRadius: 5}} src={props.logo} alt="Logo" />
                )}
                </div>

                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <p style={{color: blue, fontSize: (fontsize+10)}}>{props.nom}</p>
                </div>

                <div style={{flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25}}>
                    <p style={{color: 'grey', fontSize: (fontsize+4)}}>Synthèse globale des projets</p>
                </div>
            </div>


            {/* Container */}
            <div style={{flex: 1, height: '60%', fontSize: fontsize}}>
                
                {/* Entete */}
                <div style={{display: 'flex', backgroundColor: blue, color: 'white', textAlign: 'center', fontSize: (fontsize + 4), borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                    <div style={{flex: 1, padding: 3}}>
                        <p></p>
                    </div>
                    {props.annees.map((annee, i) => 
                    <div key={i} style={{flex: 0.5, borderLeft: '1px solid white'}}>
                        <p>{annee}</p>
                    </div>)}
                    <div style={{flex: 0.5, borderLeft: '1px solid white', fontWeight: 'bold', backgroundColor: '#2980b9', borderTopRightRadius: 5}}>
                        <p>Total</p>
                    </div>
                </div>

                {/* Lignes */}
                <div style={{flex: 1, border: '1px solid '+blue, borderBottomLeftRadius: 2, borderBottomRightRadius: 2}}>
                    {objet.length !== 0?
                    objet.map((line, i) =>
                    <div key={i} style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?i === 0?{display: 'flex'}:{display: 'flex', borderTop: '1px solid '+blue}:{display: 'flex', borderTop: '1px solid '+blue}}>
                        {line.map((val, j) =>
                            /* Intitulé des lignes */
                            j === 0?
                            <div key={j} style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{flex: 1, padding: 3, minWidth: 50, backgroundColor: greyChapter}:{flex: 1, padding: 3, minWidth: 50, backgroundColor: smoothGrey}}>
                                <p style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{fontWeight: 'bold'}:{textAlign: 'right', fontSize: (fontsize)}}>
                                    {val
                                    .replace('_', "'")
                                    .replace('Subventions', "Subv.")
                                    .replace('équipement', "équip.")
                                    .replace("Autres recettes d'investissement", "Autres recettes d'invest.")
                                    }
                                </p>
                            </div>
                            :
                            /* Montants des lignes */
                            j > 0?
                            <div key={j} style={
                                val[0] !== 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                {flex: 0.5, display: 'flex', fontSize: (fontsize), borderLeft: '1px solid '+blue, minWidth: 50, backgroundColor: greyChapter}
                                :
                                val[0] === 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                {flex: 0.5, display: 'flex', fontSize: (fontsize), borderLeft: '1px solid '+blue, minWidth: 50, backgroundColor: '#BABABA', fontWeight: 'bold'}
                                :
                                val[0] === 'Total'?
                                {flex: 0.5, display: 'flex', fontSize: (fontsize), borderLeft: '1px solid '+blue, minWidth: 50, backgroundColor: greyChapter, fontWeight: 'bold'}
                                :
                                {flex: 0.5, display: 'flex', fontSize: (fontsize), borderLeft: '1px solid '+blue, minWidth: 50}
                            }>
                                <input
                                type="float"
                                style={val[0] === 'Total' || line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50, fontWeight: 'bold'}:{flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50}}
                                value={val[1] === null || val[1] === '' || val[1] === '0' || val[1] === 0?'':addSpace(Math.round(val[1] * 100) / 100, true)}
                                disabled
                                />
                            </div>
                            :null
                        )}
                    </div>)
                    :null}
                </div>
            </div>
            
                        
            {/* Footer */}
            <div style={{display: 'flex', height: '20%', position: 'relative'}}>
                <div style={{position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200}}>
                    <p style={{fontSize: (fontsize-2)}}>{props.date}</p>
                </div>
                <div style={{position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <p style={{fontSize: fontsize}}>page : {props.pagination} / {props.countPageTotal}</p>
                </div>
                <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                    <img style={{width: sizelogo}} src={Logo} />
                </div>
            </div>
            
        </div>
    );
}
  
  export default Page5BilanFinancier;