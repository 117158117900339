import React, { useEffect, useState } from 'react';
import { addSpace } from '../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function HistogramMontantPriorite(props)
{
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({});

	function mount()
	{
        /* Initialisation des options avec les années */
        var option_tmp = {
            chart: {
                toolbar: {
                    show: false,
				},
                type: 'bar',
                height: props.height/2,
                stacked: true,
            },
            title: {
                text: 'Dépenses d\'invest. par politique pub. par priorité',
                style: {
                    fontSize: (props.fontsize + 4)
                }
            },
            xaxis: {
                categories: ['priorité 1', 'priorité 2', 'priorité 3'],
                labels: {
                    style: {
                        fontSize: (props.fontsize)
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return addSpace(Math.round(value*100)/100, true) + " €";
                    },
                    style: {
                        fontSize: (props.fontsize)
                    }
                },
            },
			dataLabels: {
				formatter: (val) => {
					return addSpace(val, true) + " €";
				},
                style: {
                    fontSize: (props.fontsize)
                }
			},
			fill: {
			  	opacity: 1
			},
            legend: {
				position: 'top',
				horizontalAlign: 'left',
                fontSize: (props.fontsize),
                markers: {
                    width: props.fontsize, // Largeur des marqueurs de la légende
                    height: props.fontsize, // Hauteur des marqueurs de la légende
                },
            }
        }
        setOptions(option_tmp)
        
        // récupérer les données du graph bar des dépenses d'investissement par priorité
        const params = {
            'depenses_investissement_par_priorite': true,
            'id_budget': props.user.id_budget,
            'hypothese': props.hypothese
        };
        axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
        .then(async (res) => {
			await setSeries(res.data);
        })
        .catch(err => console.log(err));
	}

    useEffect(() => {
		mount();
    }, [props.user, props.hypothese])


	return (
		<div style={{margin: 5}}>
			{series.length > 0 && (
			<ApexCharts
				series={series}
				options={options}
				height={props.height/2}
				type="bar"
			/>
			)}
		</div>
	)
}

export default HistogramMontantPriorite;
