import React, { useEffect, useState, useRef } from 'react';
import { toPng } from 'html-to-image';
import { Document, PDFViewer } from '@react-pdf/renderer';
import { ReactComponent as IconPdfWhite } from '../Img/icon-pdf-white.svg';
import { ReactComponent as IconScrollUp } from '../Img/scroll-up.svg';
import axios from 'axios';
import qs from 'qs';
import { rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';
import Page1Accueil from "./Page1Accueil";
import Page2NomDonneesGenerales from './Page2NomDonneesGenerales.js';
import Page3Description from './Page3Description.js';
import Page4Echeancier from './Page4Echeancier.js';
import Page5MoyensMisEnOeuvre from './Page5MoyensMisEnOeuvre.js';
import Page6IncidencesFinancieres from './Page6IncidencesFinancieres.js';
import Page7Pilotage from './Page7Pilotage.js';
import Page1_PDF_Accueil from './Page1_PDF_Accueil.js';
import Page2_PDF_NomDonneesGenerales from './Page2_PDF_NomDonneesGenerales.js';
import Page3_PDF_Description from './Page3_PDF_Description.js';
import Page5_PDF_MoyensMisEnOeuvre from './Page5_PDF_MoyensMisEnOeuvre.js';
import Page6_PDF_IncidencesFinancieres from './Page6_PDF_IncidencesFinancieres.js';
import Page7_PDF_Pilotage from './Page7_PDF_Pilotage.js';
import Page4_PDF_Echeancier from './Page4_PDF_Echeancier.js';

const BilanProjets = (props) =>
{
    const blue = '#069BD3'
    const green = '#59B44A'
    const purple = '#6571FE'

    const [aff, setAff] = useState(false)
    const [affPDF, setAffPDF] = useState(false)
    const date = new Date()
    const day = date.getDate()<10?'0'+date.getDate():date.getDate();
    const month = (date.getMonth()+1)<10?'0'+(date.getMonth()+1):(date.getMonth()+1);
    const hour = date.getHours()<10?'0'+date.getHours():date.getHours();
    const minutes = date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes();
    const dateFR = 'Le  '+day+' / '+month+' / '+date.getFullYear()+' à '+hour+':'+minutes;
    const [countPages, setCountPages] = useState(7);
    const [logo, setLogo] = useState(null);
    const [projets, setProjets] = useState(null);
    const pageRefsGantt = useRef([]);
    const [images, setImages] = useState([]);
    const width = (window.innerWidth * 0.7);
    const height = (width * (210 / 297));
    const fontsize = (height*12/890);
    const sizelogo = (width/6);
    const [showButtonScrollUp, setShowButtonScrollUp] = useState(false);
    


    async function mount() {

        /* Récupération du logo de la commune */
        const params = {
            'getOrganismes': true,
            'id': props.user.id_organisme
        }
        await axios.post('https://app-slfinance.fr/api/organismes.php', qs.stringify(params))
        .then(async (res) => {
            await setLogo(res.data[0].logo);
        })
        .catch((err) => {
            console.log(err)
        })

        await setCountPages((props.projets.length * 6) + 1);
        await setAff(!aff);
    }


    useEffect(() => {
        if (props.user === null) {
            props.getUser();
        }
        else {
            mount();
        }
        
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setShowButtonScrollUp(true);
            } else {
                setShowButtonScrollUp(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);

    }, [props.user])


    // Fonction pour capturer les pages GANTT et les convertir en images
    const capturePages = async () => {
        const newImages = [];
        for (let i = 0; i < pageRefsGantt.current.length; i++) {
            if (pageRefsGantt.current[i]) {
                const image = await toPng(pageRefsGantt.current[i], { cacheBust: true });
                await newImages.push(image);
            }
        }
        await setImages(newImages);
        await setAffPDF(true);
    };


    return (
    <div style={{flex: 1, position: 'relative'}}>

        {showButtonScrollUp && affPDF === false && (
        <button
        style={{position: 'fixed', bottom: 20, right: 20, width: (width/10), height: (height/10), border: '1px solid black', borderRadius: 5}}
        onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
            <IconScrollUp width={(width/10)}/>
        </button>
        )}

        {affPDF === false?
        <div style={{display: 'flex'}}>

            <div style={{flex: 1}}></div>

            <div style={{flex: 1, marginTop: 20, textAlign: 'center'}}>
                <p style={{color: purple, fontSize: 20}}>Prévisualisation du PDF</p>
            </div>

            <div style={{flex: 1}}>
                <button
                className='shadow-md rounded-md'
                style={{backgroundColor: green, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}}
                onClick={async() => {
                    if (props.user) {
                        await capturePages();
                        await setAff(!aff);
                    }
                }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconPdfWhite width="18"/>
                        <p style={{margin: 5}}>Créer le PDF</p>
                    </div>
                </button>
            </div>
        </div>
        :null}

    

        {props.user && dateFR && countPages && props.projets.length > 0 && affPDF &&  (
        <PDFViewer width='100%' height={window.innerHeight}>
            <Document title={'BilanProjets'}>
                <Page1_PDF_Accueil user={props.user} date={dateFR} logo={logo} />
                {props.projets.map((projet, i) =>
                <React.Fragment key={i}>
                    <Page2_PDF_NomDonneesGenerales pagination={(i * 6)+2} user={props.user} date={dateFR} logo={logo} projet={projet} countPageTotal={countPages}/>
                    <Page3_PDF_Description pagination={(i * 6)+3} nom={"Description du projet"} user={props.user} date={dateFR} logo={logo} projet={projet} countPageTotal={countPages}/>
                    <Page4_PDF_Echeancier pagination={(i * 6)+4} nom={"Échéancier"} user={props.user} date={dateFR} logo={logo} projet={projet} countPageTotal={countPages} img={images[i]}/>
                    <Page5_PDF_MoyensMisEnOeuvre pagination={(i * 6)+5} nom={"Moyens mis en oeuvre"} user={props.user} date={dateFR} logo={logo} projet={projet} countPageTotal={countPages}/>
                    <Page6_PDF_IncidencesFinancieres pagination={(i * 6)+6} nom={"Incidences Financières"} user={props.user} date={dateFR} logo={logo} projet={projet} countPageTotal={countPages}/>
                    <Page7_PDF_Pilotage pagination={(i * 6)+7} nom={"Pilotage"} user={props.user} date={dateFR} logo={logo} projet={projet} countPageTotal={countPages}/>
                </React.Fragment>
                )}
            </Document>
        </PDFViewer>
        )}



        {props.user?
            affPDF === false && (
            <div style={{display: 'flex', marginTop: 50, marginBottom: 50}}>
                <div style={{flex: 1}}></div>
                <div>
                    <div className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height}}>
                        <Page1Accueil user={props.user} date={dateFR} logo={logo} width={width} height={height}/>
                    </div>
    
                    {props.projets.map((projet, i) =>
                    <div key={i}>
                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                            <Page2NomDonneesGenerales user={props.user} date={dateFR} logo={logo} width={width} height={height} projet={projet} pagination={(i * 6)+1+1} countPageTotal={countPages}/>
                        </div>

                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                            <Page3Description user={props.user} date={dateFR} logo={logo} width={width} height={height} projet={projet} pagination={(i * 6)+2+1} countPageTotal={countPages} nom={"Description du projet"}/>
                        </div>

                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

                                {/* Header */}
                                <div style={{ display: 'flex', height: '20%' }}>
                                    <div style={{ flex: 0.4, display: 'flex', marginLeft: 10 }}>
                                        {logo && (
                                            <img style={{ maxHeight: height / 8, borderRadius: 5 }} src={logo} alt="Logo" />
                                        )}
                                    </div>

                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: blue, fontSize: (fontsize + 10) }}>{rescueHtml(projet[3]?projet[3]:'')}</p>
                                    </div>

                                    <div style={{ flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25 }}>
                                        <p style={{ color: 'grey', fontSize: (fontsize + 4) }}>Échéancier</p>
                                    </div>
                                </div>

                                <div style={{height: '60%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}} ref={(el) => (pageRefsGantt.current[i] = el)}>
                                    <Page4Echeancier user={props.user} date={dateFR} logo={logo} width={width} height={height} projet={projet} nom={"Échéancier"}/>
                                </div>
                                
                                {/* Footer */}
                                <div style={{ display: 'flex', height: '20%', position: 'relative' }}>
                                    <div style={{ position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200 }}>
                                        <p style={{ fontSize: (fontsize - 2) }}>{dateFR}</p>
                                    </div>
                                    <div style={{ position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ fontSize: fontsize }}>page : {((i * 6)+3+1)} / {countPages}</p>
                                    </div>
                                    <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                                        <img style={{width: sizelogo}} src={Logo} />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                            <Page5MoyensMisEnOeuvre user={props.user} date={dateFR} logo={logo} width={width} height={height} projet={projet} pagination={(i * 6)+4+1} countPageTotal={countPages} nom={"Moyens mis en oeuvre"}/>
                        </div>

                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                            <Page6IncidencesFinancieres user={props.user} date={dateFR} logo={logo} width={width} height={height} projet={projet} pagination={(i * 6)+5+1} countPageTotal={countPages} nom={"Incidences Financières"}/>
                        </div>

                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                            <Page7Pilotage user={props.user} date={dateFR} logo={logo} width={width} height={height} projet={projet} pagination={(i * 6)+6+1} countPageTotal={countPages} nom={"Pilotage"}/>
                        </div>
                    </div>
                    )}
                </div>
                <div style={{flex: 1}}></div>
            </div>
            )
        :null}
            
    </div>
    );
}

export default BilanProjets;