import React, { useEffect, useState } from 'react';
import { addSpace } from '../../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function BilanCapaciteDesendettement(props)
{
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)

    useEffect(() => {

        var filename = "Encours dette / Epargne brute";

        axios.post('https://app-slfinance.fr/api/graph.php', qs.stringify({
        	'CapaciteDesendettement': true,
            'id_budget': props.user.id_budget,
            'annees': props.annees,
            'hypothese': 'R'
        }))
        .then(async (res) => {
            var state = {
                series: [{
                    name: 'Encours de dette / Epargne brute',
                    data: res.data,
                    color: '#27ae60',
                    type: 'line'
                }],
                options: {
                    title: {
                        text: filename
                    },
                    chart: {
                        toolbar: {
                            show: props.download?true:false,
                            export: {
                                svg: {
                                  filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                                },
                                png: {
                                  filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                                },
                                csv: {
                                  filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                                },
                            },
                        },
                        type: 'line'
                    },
                    stroke: {
                      curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4
                        },
                    },
                    legend: {
                        position: 'right',
                        offsetY: 40
                    },
                    xaxis: {
                        categories: props.annees
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return (Math.round(value*100)/100) + " année(s)";
                            }
                        },
                    },
                    fill: {
                        opacity: 1
                    }
                }
            }

            await setOptions(state.options)
            await setSeries(state.series)
        })
    }, [])


	return (
		<div style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={'100%'}
                height={props.height?props.height:300}
                type="line"
            />:null}
		</div>
	)
}

export default BilanCapaciteDesendettement;
