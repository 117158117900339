import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { rescueHtml } from '../components/Tools.js';


const Page1Accueil = (props) =>
{
    const blue = '#069BD3';
    const fontsize = (props.height*12/890);
    const sizelogo = props.width/4;

    return (
        <div style={{width: '100%', height: '100%', padding: 20}}>
            
            {/* Header */}
            <div style={{height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {props.logo && (
                    <img style={{objectFit: 'contain', maxHeight: '100%', maxWidth: '100%', borderRadius: 5}} src={props.logo} alt="Logo" />
                )}
                <div style={{flex: 1}}></div>
            </div>


            {/* Container */}
            <div style={{height: '60%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{textAlign: 'center', marginTop: -50}}>
                    <p style={{fontSize: (fontsize+22)}}>{rescueHtml(props.user.nom_organisme)}</p>
                    <p style={{fontSize: (fontsize+6)}}>{rescueHtml(props.user.nom_budget)}</p>
                    <p style={{fontSize: (fontsize+14), color: blue, marginTop: 20}}>Synthèse globale des projets</p>
                </div>
            </div>
            

            {/* Footer */}
            <div style={{height: '20%', position: 'relative'}}>
                <div style={{position: 'absolute', bottom: 0}}>
                    <p style={{fontSize: (fontsize-2)}}>{props.date}</p>
                </div>
                <img style={{position: 'absolute', bottom: 0, right: 0, objectFit: 'contain', width: sizelogo, borderRadius: 5}} src={Logo} alt="Logo" />
            </div>
            
        </div>
    );
}
  
  export default Page1Accueil;