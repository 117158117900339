import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';
import React, { useEffect, useState } from 'react';
import { lorem } from "../components/Tools";
import axios from 'axios';
import qs from 'qs';



const Page7_PDF_Pilotage = (props) =>
{
    const blue = '#069BD3'
    const greyChapter = '#DBDBDB'
    const smoothBorder = '#EDEDED'
    const green = '#59B44A'

    const [list_pilotage, setList_pilotage] = useState([]);

    async function mount()
    {
        await axios.post('https://app-slfinance.fr/api/pilotage_projet.php', qs.stringify({
            'get': true,
            'id_projet': props.projet[0]
        }))
        .then((res) => {
            var sortedData = res.data.sort((a, b) => {
                const dateA = new Date(a[2]); // Convertit la date en objet Date
                const dateB = new Date(b[2]); // Convertit la date en objet Date
                return dateB - dateA;         // Tri décroissant
            });
            
            // Prendre les 5 premiers éléments
            var tmp = sortedData.slice(0, 5);

            tmp = tmp.sort((a, b) => {
                const dateA = new Date(a[2]); // Convertit la date en objet Date
                const dateB = new Date(b[2]); // Convertit la date en objet Date
                return dateA - dateB;         // Tri croissant
            });
            
            setList_pilotage(tmp);
            
        })
    }

    useEffect(() => {
        mount();
    }, [props.user])


    function cutString(str) {
        if (!str) return '';
    
        let ret = '';
        let count = 5;
        let index = 0;
    
        for (let i = 0; i < str.length; i++) {
            if (count === 0) {
                return ret; // Arrêter une fois 7 lignes atteintes
            }
            
            ret += str[i]; // Ajouter le caractère actuel
            
            if (index === 29 || str[i] === '\n') { // Vérifie si on a 30 caractères ou un retour à la ligne
                index = 0; // Réinitialiser le compteur
                count--; // Réduire le nombre de lignes restantes
            } else {
                index++; // Incrémenter le compteur
            }
        }
    
        return ret;
    }
    


    return (
            <Page size="A4" style={{flexDirection: 'column', backgroundColor: 'white', padding: 20, fontSize: 14}} orientation="landscape">
                
                {/* Header */}
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 0.5, flexDirection: 'row'}}>
                        {props.logo && <Image style={{maxHeight: 75, borderRadius: 5}} src={props.logo} alt="Logo" />}
                    </View>
                    <View style={{flex: 1,justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{fontSize: 16, color: blue}}>{rescueHtml(props.projet[3]?props.projet[3]:'')}</Text>
                    </View>
                    <View style={{flex: 0.5, flexDirection: 'row', fontSize: 14}}>
                        <View style={{flex: 1}}></View>
                        <Text style={{color: 'grey'}}>{props.nom && (props.nom)}</Text>
                    </View>
                </View>

                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{fontSize: 12}}>Liste des 5 dernières lignes pilotage</Text>
                </View>


                {/* Container */}
                <View style={{flex: 1, marginTop: 5, marginBottom: 30}}>
                    <View style={{flex: 0.2, flexDirection: 'row', fontSize: 11, marginTop: 10}}>
                        <View style={{flex: 0.5, borderLeft: '1px solid white', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                            <Text>Date</Text>
                        </View>
                        <View style={{flex: 1, borderLeft: '1px solid white', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                            <Text>Instance réunie</Text>
                        </View>
                        <View style={{flex: 1, borderLeft: '1px solid white', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                            <Text>Relevé de décisions</Text>
                        </View>
                        <View style={{flex: 1, borderLeft: '1px solid white', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                            <Text>Action suivante</Text>
                        </View>
                        <View style={{flex: 0.5, borderLeft: '1px solid white', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                            <Text>Date limite</Text>
                        </View>
                        <View style={{flex: 1, borderLeft: '1px solid white', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                            <Text>Responsable</Text>
                        </View>
                    </View>
    
                    {list_pilotage.map((pilotage, i) =>
                    <View key={i} style={{marginTop: 3, border: '1px solid '+greyChapter, borderRadius: 5, height: 70}}>
                        <View
                            style={{
                                fontSize: 10,
                                flex: 1,
                                flexDirection: 'row'
                            }}
                        >
                            <View style={{flex: 0.5, justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: 70}}>
                                <Text style={{padding: 5}}>{pilotage[2]?pilotage[2].split('-')[2]+'/'+pilotage[2].split('-')[1]+'/'+pilotage[2].split('-')[0]:null}</Text>
                            </View>
        
                            <View style={{flex: 1, borderLeft: '1px solid '+greyChapter, justifyContent: 'center', flexDirection: 'row', height: 70}}>
                                <Text style={{padding: 5}}>{rescueHtml(pilotage[3]?pilotage[3]:'')}</Text>
                            </View>
        
                            <View style={{borderLeft: '1px solid '+greyChapter, flex: 1, justifyContent: 'center', flexDirection: 'row', height: 70}}>
                                <Text 
                                style={{
                                    backgroundColor: 'white',
                                    resize: 'none',
                                    padding: 5,
                                    fontSize: 9
                                }}>
                                    {rescueHtml(pilotage[4]?cutString(pilotage[4]):'')}
                                </Text>
                            </View>
        
                            <View style={{flex: 1, borderLeft: '1px solid '+greyChapter, justifyContent: 'center', flexDirection: 'row', height: 70}}>
                                <Text 
                                style={{
                                    backgroundColor: 'white',
                                    resize: 'none',
                                    padding: 5,
                                    fontSize: 9
                                }}>
                                    {rescueHtml(pilotage[5]?cutString(pilotage[5]):'')}
                                </Text>
                            </View>
        
                            <View style={{flex: 0.5, borderLeft: '1px solid '+greyChapter, justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: 70}}>
                                <Text style = {
                                    pilotage[9] === '1'?
                                        {color: green}
                                    :
                                        pilotage[9] === '0' && new Date(pilotage[6]) <= new Date() && (
                                        {color: 'red'}
                                        )
                                }>
                                    {pilotage[6]?pilotage[6].split('-')[2]+'/'+pilotage[6].split('-')[1]+'/'+pilotage[6].split('-')[0]:null}
                                </Text>
                            </View>
        
                            <View style={{flex: 1, borderLeft: '1px solid '+greyChapter, justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: 70}}>
                                <Text style={{padding: 5}}>{pilotage[7]?rescueHtml(pilotage[7]):''}</Text>
                            </View>
                        </View>    
                    </View>
                    )}
    
                </View>
    
    
                {/* Footer */}
                <View style={{position: 'absolute', bottom: 20, left: 20}}>
                    <Text style={{fontSize: 10}}>{props.date}</Text>
                </View>
    
                <View style={{position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-25%)'}}>
                    <Text style={{fontSize: 10}}>page : {props.pagination} / {props.countPageTotal}</Text>
                </View>
    
                <View style={{position: 'absolute', bottom: 20, right: 20}}>
                    <Image style={{width: 150, borderRadius: 5}} src={Logo} />
                </View>

            </Page>
    );
}
  
  export default Page7_PDF_Pilotage;