import React from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { addSpace, rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';


const Page3_PDF_Description = (props) =>
{
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';
    const greyclair = '#FAFAFA';

    return (
            <Page size="A4" style={{flexDirection: 'column', backgroundColor: 'white', padding: 20, fontSize: 10}} orientation="landscape">
                
                {/* Header - Logo */}
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 0.5, flexDirection: 'row'}}>
                        {props.logo && <Image style={{maxHeight: 75, borderRadius: 5}} src={props.logo} alt="Logo" />}
                    </View>
                    <View style={{flex: 1,justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{fontSize: 16, color: blue}}>{rescueHtml(props.projet[3]?props.projet[3]:'')}</Text>
                    </View>
                    <View style={{flex: 0.5, flexDirection: 'row', fontSize: 14}}>
                        <View style={{flex: 1}}></View>
                        <Text style={{color: 'grey'}}>{props.nom?props.nom:''}</Text>
                    </View>
                </View>

                {/* Container */}
                <View style={{flexDirection: 'row', justifyContent: 'right', alignItems: 'right', fontSize: 12}}>
                    <View style={{flex: 1}}></View>
                    <Text style={{marginRight: 10}}>Niveau de priorité : {props.projet[6]}</Text>
                </View>
    
                {/* Descriptif des enjeux */}
                <View style={{flexDirection: 'row', marginBottom: 4, marginTop: 3}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Descriptif des enjeux</Text>
                    </View>
                    <View style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text>{props.projet[4] ? rescueHtml(props.projet[4]) : ""}</Text>
                    </View>
                </View>
    
                {/* Politique publique de rattachement */}
                <View style={{flexDirection: 'row', marginBottom: 4}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Politique publique de rattachement</Text>
                    </View>
                    <View style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text>{props.projet[7] ? rescueHtml(props.projet[7]).replace('|', '') : ""}</Text>
                    </View>
                </View>
    
                {/* Référent élu */}
                <View style={{flexDirection: 'row', marginBottom: 4}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Référent élu</Text>
                    </View>
                    <View style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text>{props.projet[8] ? rescueHtml(props.projet[8]) : ""}</Text>
                    </View>
                </View>
    
                {/* Montant du projet */}
                <View style={{flexDirection: 'row', marginBottom: 4}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Montant du projet (en € TTC)</Text>
                    </View>
                    <View style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text>{props.projet[9] ? addSpace(parseFloat(props.projet[9])) + "€" : "0€"}</Text>
                    </View>
                </View>
    
                {/* Maître d'ouvrage */}
                <View style={{flexDirection: 'row', marginBottom: 4}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Maître d'ouvrage</Text>
                    </View>
                    <View style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text>{props.projet[12] ? rescueHtml(props.projet[12]) : ""}</Text>
                    </View>
                </View>
    
                {/* Partenaires à mobiliser */}
                <View style={{flexDirection: 'row', marginBottom: 4}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Partenaires à mobiliser</Text>
                    </View>
                    <View style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text>{props.projet[13] ? rescueHtml(props.projet[13]) : ""}</Text>
                    </View>
                </View>

                {/* Description de l'action */}
                <View style={{flexDirection: 'row', height: 70, marginBottom: 4}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Description de l'action</Text>
                    </View>
                    <View style={{flexDirection: 'row', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text style={{flex: 1, padding: 5, minHeight: 70, maxHeight: 70, fontSize: 9, overflow: 'hidden'}}>
                            {props.projet[10] ? rescueHtml(props.projet[10]) : ''}
                        </Text>
                    </View>
                </View>

                {/* Questions/ Remarques */}
                <View style={{flexDirection: 'row', height: 70, marginBottom: 4}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Questions / Remarques</Text>
                    </View>
                    <View style={{flexDirection: 'row', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text style={{flex: 1, padding: 5, minHeight: 70, maxHeight: 70, fontSize: 9, overflow: 'hidden'}}>
                            {props.projet[11] ? rescueHtml(props.projet[11]) : ''}
                        </Text>
                    </View>
                </View>

                {/* Service référent */}
                <View style={{flexDirection: 'row', marginTop: 10, marginBottom: 4}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Service référent</Text>
                    </View>
                    <View style={{flexDirection: 'row', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text style={{flex: 1, padding: 5}}>
                            {props.projet[14]?rescueHtml(props.projet[14]):''}
                        </Text>
                    </View>
                </View>
                {/****/}

                {/* Technicien référent */}
                <View style={{flexDirection: 'row', marginBottom: 4}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Technicien référent</Text>
                    </View>
                    <View style={{flex: 1, flexDirection: 'row', backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        
                        <View style={{flexDirection: 'row', flex: 0.6}}>
                            <View>
                                <Text style={{padding: 5}}>
                                    {props.projet[15]?rescueHtml(props.projet[15]):''}
                                </Text>
                            </View>
                        </View>

                        <View style={{flexDirection: 'row', flex: 1}}>
                            <View>
                                <Text style={{padding: 5}}>
                                    {props.projet[18]?rescueHtml(props.projet[18]):''}
                                </Text>
                            </View>
                        </View>

                        <View style={{flexDirection: 'row', flex: 0.4}}>
                            <View>
                                <Text style={{padding: 5}}>
                                    {props.projet[17]?rescueHtml(props.projet[17]):''}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                {/****/}

                {/* Fonction du technicien référent */}
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 0.1, padding: 5, backgroundColor: greyChapter, minWidth: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <Text>Fonction du technicien référent</Text>
                    </View>
                    <View style={{flexDirection: 'row', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <Text style={{flex: 1, padding: 5}}>
                            {props.projet[16]?rescueHtml(props.projet[16]):''}
                        </Text>
                    </View>
                </View>
                {/****/}
   
    
                {/* Footer */}
                <View style={{position: 'absolute', bottom: 20, left: 20}}>
                    <Text style={{fontSize: 10}}>{props.date}</Text>
                </View>
    
                <View style={{position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-25%)'}}>
                    <Text style={{fontSize: 10}}>page : {props.pagination} / {props.countPageTotal}</Text>
                </View>
    
                <View style={{position: 'absolute', bottom: 20, right: 20}}>
                    <Image style={{width: 150, borderRadius: 5}} src={Logo} />
                </View>
                
            </Page>
    );
}
  
  export default Page3_PDF_Description;