import React, { useEffect, useState } from 'react';
import { addSpace } from "../components/Tools";
import axios from 'axios';
import qs from 'qs';
import { rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';


const Page6IncidencesFinancieres = (props) =>
{
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';
    const fontsize = (props.height*12/890);
    const sizelogo = props.width/6;

    const [incidences_financieres, setIncidences_financieres] = useState([]);
    const [annees, setAnnees] = useState([])

    async function mount()
    {
        // Recuperation des annees de prospective
        let prosp_an_deb = ''
        let prosp_an_fin = ''
        await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
        .then((result) => {
            result.data.forEach(element => {
                if (element[2] === 'prosp_an_deb') {
                    prosp_an_deb = parseInt(element[3])
                }
                if (element[2] === 'prosp_an_fin') {
                    prosp_an_fin = parseInt(element[3])
                }
            });
        }).catch((err) => {console.log(err)})
        
        if (prosp_an_deb === '' || prosp_an_fin === '')
        {
            await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
            .then(async function (res) {
                if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                    if (prosp_an_deb === '') {
                        prosp_an_deb = parseInt(res.data.prosp_an_deb)
                    }
                    if (prosp_an_fin === '') {
                        prosp_an_fin = parseInt(res.data.prosp_an_fin)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        var liste_an = []
        var i = 0
        while (prosp_an_deb <= prosp_an_fin)
        {
            liste_an[i] = parseInt(prosp_an_deb)
            prosp_an_deb++;
            i++;
        }
        await setAnnees(liste_an)

        await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
            'get': true,
            'annees': liste_an,
            'id_projet': props.projet[0]
        }))
        .then(async(res) => {
            var total = [];
            await res.data.forEach((element, i) => {
                total[i] = 0;
                element.forEach((elem, j) => {
                    if (j > 1) {
                        if (!parseFloat(elem[1])) {
                            elem[1] = 0;
                        }
                        else {
                            elem[1] = parseFloat(elem[1]);
                        }
                        total[i] += (elem[1]);
                    }
                });
            });
            await res.data.forEach((line, k) => {
                res.data[k].push(['Total', total[k]])
            });
            setIncidences_financieres(res.data)
        })
    }

    useEffect(() => {
        mount();
    }, [props.user])

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{ display: 'flex', height: '20%' }}>
                <div style={{ flex: 0.4, display: 'flex', marginLeft: 10 }}>
                    {props.logo && (
                        <img style={{ maxHeight: props.height / 8, borderRadius: 5 }} src={props.logo} alt="Logo" />
                    )}
                </div>

                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: blue, fontSize: (fontsize + 10) }}>{rescueHtml(props.projet[3]?props.projet[3]:'')}</p>
                </div>

                <div style={{ flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25 }}>
                    <p style={{ color: 'grey', fontSize: (fontsize + 4) }}>{props.nom}</p>
                </div>
            </div>

            {/* Container */}
            <div style={{height: '60%', flex: 1, fontSize: fontsize}}>

                {/* Tableau des incidences financieres */}
                <div style={{display: 'flex', backgroundColor: blue, color: 'white', fontSize: (fontsize+3), borderTopLeftRadius: 3, borderTopRightRadius: 3}}>
                    <div style={{flex: 1, padding: 5}}>
                        <p></p>
                    </div>
                    {annees.map((annee, i) =>
                    <div key={i} style={{display: 'flex', justifyContent: 'center', flex: 0.5, borderLeft: '1px solid white'}}>
                        <p>{annee}</p>
                    </div>)}
                    <div style={{display: 'flex', justifyContent: 'center', flex: 0.5, borderLeft: '1px solid white', fontWeight: 'bold', backgroundColor: '#2980b9', borderTopRightRadius: 3}}>
                        <p>Total</p>
                    </div>
                </div>
                <div style={{flex: 1, backgroundColor: 'white'}}>
                    {incidences_financieres.length !== 0?
                    incidences_financieres.map((line, i) =>
                    <div key={i} style={i === 0?{display: 'flex'}:line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{display: 'flex'}:{display: 'flex'}}>
                        {line.map((val, j) =>
                            j === 0?
                            <div key={j}
                            style={
                                line[0] === "Dépenses de fonctionnement" ||
                                line[0] === "Recettes de fonctionnement" ||
                                line[0] === "Dépenses d_investissement" ||
                                line[0] === "Recettes d_investissement" ?
                                {flex: 1, padding: 5, minWidth: 50, backgroundColor: greyChapter}
                                :
                                {flex: 1, padding: 5, minWidth: 50, borderTop: '1px solid '+greyChapter}}
                            >
                                {val && typeof val === 'string' ?
                                <p style={
                                    line[0] === "Dépenses de fonctionnement" ||
                                    line[0] === "Recettes de fonctionnement" ||
                                    line[0] === "Dépenses d_investissement" ||
                                    line[0] === "Recettes d_investissement" ?
                                    {fontWeight: 'bold', fontSize: fontsize-1}
                                    :
                                    {textAlign: 'right', fontSize: fontsize-1}}
                                >
                                    {
                                    val.replace('_', "'")
                                    .replace('Dépenses d\'équipement', "Dépenses d\'équip.")
                                    .replace('Subventions de fonctionnement', "Subv. de fonct.")
                                    .replace('Autres recettes d\'investissement', "Autres recettes d\'invest.")
                                    }
                                </p>
                                
                                :null}
                            </div>
                            :
                            j > 1?
                            <div
                                key={j}
                                style={
                                    val[0] !== 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                        {flex: 0.5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white', backgroundColor: greyChapter, fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}
                                    :
                                        val[0] === 'Total'?
                                            line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement" ?
                                                {flex: 0.5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid '+greyChapter, borderTop: '1px solid white', backgroundColor: '#BABABA', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}
                                            :
                                                {flex: 0.5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid '+greyChapter, borderTop: '1px solid white', backgroundColor: greyChapter, fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}
                                        :
                                            {flex: 0.5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid '+greyChapter, borderTop: '1px solid '+greyChapter, backgroundColor: 'white'}
                                }
                            >
                                <p
                                style={{flex: 1, flexDirection: 'row', textAlign: 'right', alignItems: 'center', padding: 5}}
                                >
                                    {val[1] === null || val[1] === '' || val[1] === 0 || val[1] === '0'?'':addSpace(Math.round(val[1]*100)/100)}
                                </p>
                            </div>
                            :null
                        )}
                    </div>)
                    :null}
                </div>
                {/****/}
            </div>
            
            
            {/* Footer */}
            <div style={{ display: 'flex', height: '20%', position: 'relative' }}>
                <div style={{ position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200 }}>
                    <p style={{ fontSize: (fontsize - 2) }}>{props.date}</p>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: fontsize }}>page : {props.pagination} / {props.countPageTotal}</p>
                </div>
                <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                    <img style={{width: sizelogo}} src={Logo} />
                </div>
            </div>

        </div>
    );
}
  
  export default Page6IncidencesFinancieres;