import React from 'react';
import { rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';


const Page5MoyensMisEnOeuvre = (props) =>
{
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';
    const greyclair = '#FAFAFA';
    const height = (props.height*120/950);
    const fontsize = (props.height*12/890);
    const sizelogo = props.width/6;

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{ display: 'flex', height: '20%' }}>
                <div style={{ flex: 0.4, display: 'flex', marginLeft: 10 }}>
                    {props.logo && (
                        <img style={{ maxHeight: props.height / 8, borderRadius: 5 }} src={props.logo} alt="Logo" />
                    )}
                </div>

                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: blue, fontSize: (fontsize + 10) }}>{rescueHtml(props.projet[3]?props.projet[3]:'')}</p>
                </div>

                <div style={{ flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25 }}>
                    <p style={{ color: 'grey', fontSize: (fontsize + 4) }}>{props.nom}</p>
                </div>
            </div>

            {/* Container */}
            <div style={{height: '60%', flex: 1, fontSize: fontsize}}>

                {/* Ligne 14: Ressources humaines */}
                <div style={{display: 'flex', margin: 10, borderRadius: 5, height: height, maxHeight: height}}>
                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', width: 175, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <p>Ressources humaines</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <p style={{flex: 1, padding: 5, overflow: 'hidden'}}>
                            {props.projet[19]?rescueHtml(props.projet[19]):''}
                        </p>
                    </div>
                </div>
                {/****/}
                
                {/* Ligne 15: Etudes */}
                <div style={{display: 'flex', margin: 10, borderRadius: 5, height: height, maxHeight: height}}>
                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', width: 175, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <p>Études</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <p style={{flex: 1, padding: 5, overflow: 'hidden'}}>
                            {props.projet[20]?rescueHtml(props.projet[20]):''}
                        </p>
                    </div>
                </div>
                {/****/}
                
                {/* Ligne 16: Outils, matériels */}
                <div style={{display: 'flex', margin: 10, borderRadius: 5, height: height, maxHeight: height}}>
                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', width: 175, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <p>Outils, matériels</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <p style={{flex: 1, padding: 5, overflow: 'hidden'}}>
                            {props.projet[21]?rescueHtml(props.projet[21]):''}
                        </p>
                    </div>
                </div>
                {/****/}
                
                {/* Ligne 17: Investissements (moyens internes/externes) */}
                <div style={{display: 'flex', margin: 10, borderRadius: 5, height: height, maxHeight: height}}>
                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', width: 175, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <p>Investissements</p>
                        <p>(moyens internes/externes)</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <p style={{flex: 1, padding: 5, overflow: 'hidden'}}>
                            {props.projet[22]?rescueHtml(props.projet[22]):''}
                        </p>
                    </div>
                </div>
                {/****/}
                
                {/* Ligne 18: Incidences organisationnelle et administrative */}
                <div style={{display: 'flex', margin: 10, borderRadius: 5, height: height, maxHeight: height}}>
                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', width: 175, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <p>Incidences organisationnelle et administrative</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <p style={{flex: 1, padding: 5, overflow: 'hidden'}}>
                            {props.projet[23]?rescueHtml(props.projet[23]):''}
                        </p>
                    </div>
                </div>
                {/****/}
            </div>


            {/* Footer */}
            <div style={{ display: 'flex', height: '20%', position: 'relative' }}>
                <div style={{ position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200 }}>
                    <p style={{ fontSize: (fontsize - 2) }}>{props.date}</p>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: fontsize }}>page : {props.pagination} / {props.countPageTotal}</p>
                </div>
                <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                    <img style={{width: sizelogo}} src={Logo} />
                </div>
            </div>

        </div>
    );
}
  
  export default Page5MoyensMisEnOeuvre;