import React, { useEffect, useState } from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import Logo from '../Img/Logo_projets&finances.png';

const Page6_PDF_Gantt = (props) =>
{
    const blue = '#069BD3';
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        setIsLoaded(true);
    }, []);

    if (!isLoaded) {
        return null;
    }


    return (
        <Page 
            size="A4" 
            style={{flexDirection: 'column', backgroundColor: 'white', padding: 20}} 
            orientation="landscape"
        >
            {/* Header - Logo */}
            <View style={{flexDirection: 'row'}}>
                <View style={{flex: 0.5, flexDirection: 'row', height: 75}}>
                    {props.logo && <Image style={{maxHeight: 75, borderRadius: 5}} src={props.logo} alt="Logo" />}
                </View>
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                    <Text style={{color: blue, fontSize: 18}}>{props.nom?props.nom:''}</Text>
                </View>
                <View style={{flex: 0.5, flexDirection: 'row', fontSize: 12}}>
                    <Text style={{color: 'grey'}}>Synthèse globale des projets</Text>
                </View>
            </View>

            {/* Container */}
            <View style={{flex: 1, marginTop: 20, marginBottom: 40, flexDirection: 'row', height: 100, alignItems: 'center', justifyContent: 'center'}}>
                {props.img ? (
                    <Image src={props.img} style={{ width: '100%', height: 'auto' }} />
                ) : (
                    <Text style={{ textAlign: 'center', fontSize: 16 }}>Aucune fiche projet n'a de GANTT renseigné</Text>
                )}
            </View>
            
            {/* Footer */}
            <View style={{position: 'absolute', bottom: 20, left: 20}}>
                <Text style={{fontSize: 8}}>{props.date}</Text>
            </View>

            <View style={{position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-25%)'}}>
                <Text style={{fontSize: 8}}>page : {props.pagination} / {props.countPageTotal}</Text>
            </View>

            <View style={{position: 'absolute', bottom: 20, right: 20}}>
                <Image style={{width: 150, borderRadius: 5}} src={Logo} />
            </View>
            
        </Page>
    );
}

export default Page6_PDF_Gantt;