import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { addSpace, lorem, rescueHtml } from '../components/Tools.js';


const Page2DonneesGenerales = (props) =>
{
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';
    const height = (props.height*50/950);
    const fontsize = (props.height*12/890);
    const sizelogo = props.width/6;

    return (
        <div style={{width: '100%', height: '100%', padding: 20}}>
            
            {/* Header */}
            <div style={{display: 'flex', height: '20%'}}>
                <div style={{flex: 0.4, display: 'flex', marginLeft: 10}}>
                {props.logo && (
                    <img style={{maxHeight: props.height/8, borderRadius: 5}} src={props.logo} alt="Logo" />
                )}
                </div>

                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <p style={{color: blue, fontSize: (fontsize+10)}}>{props.nom}</p>
                    <p style={{color: blue, fontSize: (fontsize+6), marginLeft: 5, fontStyle: 'italic'}}>{props.index !== null ? '('+props.index+'/'+props.indexTotal+')' : ''}</p>
                </div>
                
                <div style={{flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25}}>
                    <p style={{color: 'grey', fontSize: (fontsize+4)}}>Synthèse globale des projets</p>
                </div>
            </div>


            {/* Container */}
            <div style={{flex: 1, height: '60%', fontSize: fontsize}}>
                
                {/* Entete */}
                <div style={{flex: 1, display: 'flex', marginRight: 10, justifyContent: 'center', alignItems: 'center', fontWeight: 'bold'}}>
                    <div style={{flex: 1}}>
                    </div>
                    <div style={{flex: 0.1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>Hypothèses</p>
                    </div>
                </div>
                <div style={{flex: 1, display: 'flex', marginRight: 10, marginLeft: 10, justifyContent: 'center', alignItems: 'center', fontWeight: 'bold'}}>
                    <div style={{flex: 0.1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>N°</p>
                    </div>
                    <div style={{width: (props.width/25), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>État</p>
                    </div>
                    <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>Intitulé</p>
                    </div>
                    <div style={{flex: 0.3, justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                        <p>Date de fin</p>
                        <p>prévisionnelle</p>
                    </div>
                    <div style={{flex: 0.8, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>Politique publique</p>
                    </div>
                    <div style={{flex: 0.2, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>Priorité</p>
                    </div>
                    <div style={{flex: 0.4, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>Montant</p>
                    </div>
                    <div style={{flex: 0.1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>H1</p>
                    </div>
                    <div style={{flex: 0.1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>H2</p>
                    </div>
                    <div style={{flex: 0.1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p>H3</p>
                    </div>
                </div>

                {props.projets.map((projet, i) => (
                    <div
                        key={i}
                        style={{flex: 1, display: 'flex', margin: (fontsize-2), border: '1px solid '+greyChapter, borderRadius: 5}}
                    >
                        {/* Numéro du projet */}
                        <div style={{flex: 0.1, display: 'flex', justifyContent: 'center'}}>
                            <p style={{display: 'flex', alignItems: 'center', height: height, overflow: 'hidden'}}>{projet[2]?projet[2]:''}</p>
                        </div>

                        {/* État / couleur */}
                        <div style={{width: (props.width/25), display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{border: '1px solid '+projet[27], backgroundColor: projet[27], borderRadius: '100%', width: "80%", height: "80%"}}></div>
                        </div>

                        {/* Intitulé */}
                        <div style={{flex: 1, display: 'flex'}}>
                            <p style={{display: 'flex', padding: 5, alignItems: 'center', height: height, overflow: 'hidden', fontWeight: 'bold'}}>{projet[3]?rescueHtml(projet[3]):''}</p>
                        </div>

                        {/* Date de fin previsionnelle GANTT */}
                        <div style={{flex: 0.3, display: 'flex', justifyContent: 'center'}}>
                            <p style={{display: 'flex', padding: 5, alignItems: 'center', height: height, overflow: 'hidden'}}>{projet['end']?(projet['end'].split('-')[2]+'/'+projet['end'].split('-')[1]+'/'+projet['end'].split('-')[0]):''}</p>
                        </div>

                        {/* Politique publique */}
                        <div style={{flex: 0.8, display: 'flex'}}>
                            <p style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5, height: height, overflow: 'hidden'}}>{projet[7] ? projet[7].replace(/\|/g, ' ') : ''}</p>
                        </div>

                        {/* Priorité */}
                        <div style={{flex: 0.2, display: 'flex'}}>
                            <p style={{flex: 1, display: 'flex', padding: 5, justifyContent: 'center', alignItems: 'center', height: height, overflow: 'hidden'}}>{projet[6] ? projet[6] : ''}</p>
                        </div>

                        {/* Montant */}
                        <div style={{flex: 0.4, display: 'flex', justifyContent: 'right'}}>
                            <p style={{display: 'flex', paddingRight: 20, alignItems: 'center', height: height, overflow: 'hidden'}}>{projet[9] ? addSpace(projet[9], true)+' €' : ''}</p>
                        </div>

                        {/* hypothèse 1 */}
                        <div style={{flex: 0.1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                           <p>{<input type="checkbox" onChange={() => {}} checked={projet[24] === "0"?false:true} style={{ transform: "scale(0.9)" }} />}</p>
                        </div>

                        {/* hypothèse 2 */}
                        <div style={{flex: 0.1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <p>{<input type="checkbox" onChange={() => {}} checked={projet[25] === "0"?false:true} style={{ transform: "scale(0.9)" }} />}</p>
                        </div>

                        {/* hypothèse 3 */}
                        <div style={{flex: 0.1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <p>{<input type="checkbox" onChange={() => {}} checked={projet[26] === "0"?false:true} style={{ transform: "scale(0.9)" }} />}</p>
                        </div>

                    </div>
                ))}
            </div>
            
                        
            {/* Footer */}
            <div style={{display: 'flex', height: '20%', position: 'relative'}}>
                <div style={{position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200}}>
                    <p style={{fontSize: (fontsize-2)}}>{props.date}</p>
                </div>
                <div style={{position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <p style={{fontSize: fontsize}}>page : {props.pagination} / {props.countPageTotal}</p>
                </div>
                <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                    <img style={{width: sizelogo}} src={Logo} />
                </div>
            </div>
            
        </div>
    );
}
  
  export default Page2DonneesGenerales;