import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { rescueHtml } from '../components/Tools.js';


const Page1Accueil = (props) =>
{
    const blue = '#069BD3'

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 300}} src={Logo} />
                </div>

                <div style={{flex: 1}}></div>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginTop: 25, height: '80%'}}>
                <div style={{height: '25%'}}></div>
                <div style={{textAlign: 'center'}}>
                <p style={{fontSize: 30}}>{rescueHtml(props.user.nom_organisme)}</p>
                <p style={{fontSize: 20}}>{props.user.nom_budget}</p>
                    <p style={{fontSize: 26, color: blue, marginTop: 40}}>Tableau de bord mensuel {props.year}</p>
                    <p style={{fontSize: 16, color: blue, marginTop: 10}}>Suivi de la consommation budgétaire</p>
                </div>

                <div style={{display: 'flex', marginTop: 50}}>
                    <div style={{flex: 1}}></div>
                    <div style={{flex: 1}}></div>
                </div>

                <div style={{display: 'flex', marginTop: 150}}>
                    <div style={{flex: 1}}></div>
                    <div style={{flex: 2, textAlign: 'right', display: 'flex', justifyContent: 'right'}}>
                        <p style={{fontSize: 16, marginTop: 10, color: blue}}>Outil développé par l'équipe de</p>
                        {/* <img style={{marginLeft: 50, width: 300}} src={Logo} /> */}
                    </div>
                    <div style={{flex: 0.2}}></div>
                </div>
            </div>


            
            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
            </div>
        </div>
    );
}
  
  export default Page1Accueil;