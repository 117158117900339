import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import {NotificationManager} from 'react-notifications';

const UploadLogoOrganisme = (props) =>
{
    const green = '#59B44A'
    const purple = '#6571FE'
    
    const [logo, setLogo] = useState(null);
    const [organisme, setOrganisme] = useState(null);
    const [aff, setAff] = useState(false);

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Stocke l'image sous forme de base64 pour prévisualisation
            const reader = new FileReader();
            reader.onload = (e) => {
                setLogo(e.target.result);
            };
            reader.readAsDataURL(file);
            await setAff(!aff);
        }
    };

    async function mount()
    {    
        const params = {
            'getOrganismes': true,
            'id': props.user.id_organisme
        }
        await axios.post('https://app-slfinance.fr/api/organismes.php', qs.stringify(params))
        .then(async (res) => {
            await setLogo(res.data[0].logo);
            await setOrganisme(res.data[0]);
        })
        .catch((err) => {
            console.log(err)
        })
    }


    useEffect(() => {
        mount();
    }, [])


    async function validation()
    {
        if (logo !== null && organisme !== null)
        {
            const params = {
                'updateOrganisme': true,
                'id': organisme.id,
                'logo': logo
            }
            await axios.post('https://app-slfinance.fr/api/organismes.php', qs.stringify(params))
            .then(async (res) => {
                if (res.data['status'] === 1) {
                    NotificationManager.success(res.data['status_message'], '', 2000);
                }
                else {
                    NotificationManager.warning(res.data['status_message'], '', 5000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
    

	return (
        <div className='shadow-xl' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
                <button
                    className='shadow-md rounded-md'
                    style={{
                        backgroundColor: purple,
                        color: 'white',
                        fontSize: 17,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        padding: 10,
                        marginBottom: 20,
                    }}
                    onClick={() => document.getElementById('upload-logo-input').click()}
                >
                    <p>Importer son logo</p>
                </button>

                {/* Champ d'upload caché */}
                <input
                    id="upload-logo-input"
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                />
                
                <div style={{margin: 25}}>
                    <img style={logo?{width: 300, borderRadius: 5}:{width: 300, minHeight: 200, borderRadius: 5}} src={logo} />
                </div>

                <button
                style={{marginBottom: 5, display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}
                onClick={() => {
                    validation();
                }}
                >
                    <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                        <Check width="12" />
                        <p style={{marginLeft: 5}}>Valider</p>
                    </div>
                </button>
            </div>
        </div>
	);
}

export default UploadLogoOrganisme;
