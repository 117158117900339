import React, { useEffect, useState } from 'react';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import AddEmpruntManuel from "./components/AddEmpruntManuel";
import Dialog from "./components/Dialog";
import {NotificationManager} from 'react-notifications';
import { ReactComponent as Add } from './Img/icon-add.svg';
import { ReactComponent as Bin } from './Img/icon-bin.svg';
import axios from 'axios';
import qs from 'qs';
import { addSpace, getyears } from "./components/Tools";


const CalculEmpruntsManuel = (props) =>
{
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);
    const [suppressModalVisible, setSuppressModalVisible] = useState(false);
    const [listEmprunts, setListEmprunts] = useState(false);
    const d = new Date();

    const [id, setID] = useState(0);
    const [montant, setMontant] = useState(0);
    const [txi, setTxi] = useState(0);
    const [txa, setTxa] = useState(0);
    const [annee, setAnnee] = useState(d.getFullYear());
    const [annees, setAnnees] = useState(null);
    const [duree, setDuree] = useState(1);
    const [dureeDiff, setDureeDiff] = useState(0);

    const blue = '#069BD3'
    const green = '#59B44A'
    const red = '#E33636'
    const purple = '#6571FE'
    
    useEffect(() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            getyears(props.user.id_budget, 'P')
            .then((annees) => {
                annees.shift()
                setAnnees(annees)
            })

            // Récupération des emprunts pour les afficher
            const params = {
                'getEmprunts': true,
                'id_budget': props.user.id_budget,
                'hypothese': 'h'+props.user.hypothese,
            }
            
            axios.post("https://app-slfinance.fr/api/emprunts.php", qs.stringify(params))
            .then(async (res) => {
                await setListEmprunts(res.data)
            })
            .catch((err) => {console.log(err)})
        }
        
    }, [props.user])


    async function suppressAll()
    {  
        await listEmprunts.forEach(async emprunt => {
            const params = {
                'suppress': true,
                'id_budget': props.user.id_budget,
                'hypothese': 'h'+props.user.hypothese,
                'annees': annees,
                'id': emprunt[0]
            }
            await axios.post("https://app-slfinance.fr/api/emprunts.php", qs.stringify(params))
            .then(async (e) => {
                console.log(e.data)
                NotificationManager.success('Emprunt supprimé', '', 300);
            })
            .catch((err) => {console.log(err)})
        });
        await setTimeout(() => window.location = window.location.href, 500);
    }


    function callbackDialog(ret) {
        if (ret === true) {
            suppressAll();
        }
    }


	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                <Navbar user={props.user} />

                <Dialog
                setModalVisible={setSuppressModalVisible}
                modalVisible={suppressModalVisible}
                callbackDialog={callbackDialog}
                message={"Êtes vous sûr de vouloir supprimer les emprunts de cette hypothèse ?"}
                />

                {props.user?
                <div style={{display: 'flex'}}>

                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Calcul manuel des emprunts d'équilibre</p>
                    </div>

                    <div style={{flex: 1, textAlign: 'center'}}>
                        <p style={{flex: 1, margin: 20}}>{props.user.nom_budget}</p>
                    </div>

                    <div style={{flex: 1}}></div>

                </div>
                :null}

                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>

                        <div style={{margin: 15, display: 'flex', justifyContent: 'center'}}>
                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: red, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                onClick={() => {
                                    setSuppressModalVisible(true)
                                }}
                            >
                                <Bin width="12"/>
                                <p style={{marginLeft: 5}}>Tout supprimer</p>
                            </button>
                        </div>

                        <div style={{margin: 15, display: 'flex', justifyContent: 'center'}}>
                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: green, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                onClick={() => {
                                    setCreateModalVisible(!createModalVisible)
                                }}
                            >
                                <Add width="12"/>
                                <p style={{marginLeft: 5}}>Nouvel emprunt</p>
                            </button>
                        </div>
                    </div>
                    <div style={{flex: 1}}></div>
                </div>

                <div style={{display: 'flex'}}>

                    {/* volet gauche */}
                    <div style={{flex: 0.2}}>
                    </div>

                    {/* volet central */}
                    <div className='shadow-md rounded-md' style={{flex: 1}}>
                        <div style={{display: 'flex', textAlign: 'center', backgroundColor: blue, color: 'white'}}>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p style={{margin: 2}}>Montant</p>
                            </div>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                <p style={{margin: 2}}>Année de souscription</p>
                            </div>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                <p style={{margin: 2}}>Durée</p>
                            </div>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                <p style={{margin: 2}}>Durée du différé d'amort.</p>
                            </div>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                <p style={{margin: 2}}>Taux d'intérêts</p>
                            </div>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px solid white'}}>
                                <p style={{margin: 2}}>Taux d'amortissement</p>
                            </div>
                        </div>

                        {listEmprunts && listEmprunts.length !== 0?
                        <div>
                            {listEmprunts.map((emprunt, i) =>
                            <div key={i} style={{display: 'flex', textAlign: 'center', borderTop: '1px solid '+blue, marginBottom: 5}}>
                                <button
                                style={{flex: 1, display: 'flex', textAlign: 'center', marginTop: 5}}
                                onClick={() => {
                                    setUpdateModalVisible(!updateModalVisible)
                                    setID(emprunt[0])
                                    setMontant(emprunt[5])
                                    setAnnee(emprunt[2])
                                    setDuree(emprunt[3])
                                    setDureeDiff(emprunt[4])
                                    setTxi(emprunt[6])
                                    setTxa(emprunt[7])
                                }}
                                >
                                    <div style={{flex: 1}}>
                                        <p>{addSpace(emprunt[5])} €</p>
                                    </div>
                                    <div style={{flex: 1}}>
                                        <p>{emprunt[2]}</p>
                                    </div>
                                    <div style={{flex: 1}}>
                                        <p>{parseInt(emprunt[3]) === 1?emprunt[3]+' an':emprunt[3]+' ans'}</p>
                                    </div>
                                    <div style={{flex: 1}}>
                                        <p>{parseInt(emprunt[4]) <= 1?emprunt[4]+' an':emprunt[4]+' ans'}</p>
                                    </div>
                                    <div style={{flex: 1}}>
                                        <p>{emprunt[6]}%</p>
                                    </div>
                                    <div style={{flex: 1}}>
                                        <p>{emprunt[7]}%</p>
                                    </div>

                                </button>
                            </div>
                            )}
                        </div>
                        :
                        <div style={{display: 'flex', textAlign: 'center', borderTop: '1px solid '+purple, marginTop: 5, marginBottom: 5}}>

                        </div>}
                    </div>


                    {/* volet droit */}
                    <div style={{flex: 0.2, display: 'flex', justifyContent: 'center'}}>

                    </div>

                </div>

                {createModalVisible && props.user?
                <AddEmpruntManuel
                modalVisible={createModalVisible}
                setModalVisible={setCreateModalVisible}
                create={true}
                hypothese={'h'+props.user.hypothese}
                user={props.user}
                montant={0}
                annee={d.getFullYear()}
                duree={1}
                dureeDiff={0}
                txi={0}
                txa={0}
                />
                :null}

                {updateModalVisible && props.user?
                <AddEmpruntManuel
                modalVisible={updateModalVisible}
                setModalVisible={setUpdateModalVisible}
                create={false}
                hypothese={'h'+props.user.hypothese}
                user={props.user}
                id={id}
                montant={montant}
                annee={annee}
                duree={duree}
                dureeDiff={dureeDiff}
                txi={txi}
                txa={txa}
                />
                :null}

            </Access>
        </div>
	);
}

export default CalculEmpruntsManuel;
