import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { addSpace, rescueHtml } from "../components/Tools";

const Page30BilanFP = (props) =>
{
    const blue = '#069BD3'
    const greyChapter = '#DBDBDB'
    const smoothGrey = '#f4f4f4';

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={Logo} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>Bilan financier des projets - Hypothèse n°{props.hypothese}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginTop: 25, height: '80%', textAlign: 'center'}}>
                <div style={{margin: 25}}>
                    <div style={{display: 'flex', backgroundColor: blue, color: 'white', borderTopRightRadius: 5, borderTopLeftRadius: 5}}>
                        <div style={{display: 'flex', width: 300}}>
                            <p style={{flex: 1, fontSize: 12, paddingRight: 5, textAlign: 'center'}}>Intitulés</p>
                        </div>

                        {props.yearsProsp !== null?
                        props.yearsProsp.map((year, j) =>
                        j > 0?
                        <div key={j} style={{borderLeft: '1px solid white', flex: 1, textAlign: 'center'}}>
                            <p style={{fontSize: 12}}>{year}</p>
                        </div>
                        :null):null}
                        <div style={{borderLeft: '1px solid white', flex: 1, textAlign: 'center', backgroundColor: '#2980b9', borderTopRightRadius: 5}}>
                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Total</p>
                        </div>
                    </div>


                    {props.objet !== null?
                    props.objet.map((line, i) =>
                    <div key={i}
                    style={
                        line[0] === 'Dépenses de fonctionnement' || line[0] === 'Recettes de fonctionnement' || line[0] === 'Dépenses d_investissement' || line[0] === 'Recettes d_investissement'?
                        {display: 'flex', flex: 1, borderTop: '1px solid '+greyChapter, backgroundColor: greyChapter}
                        :
                        {display: 'flex', flex: 1, borderTop: '1px solid '+greyChapter}
                    }>

                        <div style={{width: 300, marginTop: 2}}>
                            <p style={line[0] === 'Dépenses de fonctionnement' || line[0] === 'Recettes de fonctionnement' || line[0] === 'Dépenses d_investissement' || line[0] === 'Recettes d_investissement'?{flex: 1, fontSize: 12, paddingLeft: 5}:{flex: 1, fontSize: 11, paddingRight: 5, textAlign: 'right'}}>{line[0].replace('_', '\'')}</p>
                        </div>
                        
                        {line.map((an, k) =>
                        k>1?
                        <div key={k} style={
                            an[0] === 'Total' && (line[0] === 'Dépenses de fonctionnement' || line[0] === 'Recettes de fonctionnement' || line[0] === 'Dépenses d_investissement' || line[0] === 'Recettes d_investissement')?
                            {borderLeft: '1px solid white', fontWeight: 'bold', borderTop: '1px solid white', flex: 1, paddingRight: 2, paddingTop: 2, backgroundColor: '#BABABA'}
                            :
                            an[0] !== 'Total' && (line[0] === 'Dépenses de fonctionnement' || line[0] === 'Recettes de fonctionnement' || line[0] === 'Dépenses d_investissement' || line[0] === 'Recettes d_investissement')?
                            {borderLeft: '1px solid white', flex: 1, paddingRight: 2, paddingTop: 2, backgroundColor: greyChapter}
                            :
                            an[0] === 'Total'?
                            {borderLeft: '1px solid white', fontWeight: 'bold', borderTop: '1px solid white', flex: 1, paddingRight: 2, paddingTop: 2, backgroundColor: greyChapter}
                            :
                            {borderLeft: '1px solid white', flex: 1, paddingRight: 2, paddingTop: 2}
                        }>
                            <p style={{fontSize: 11, flex: 1, textAlign: 'right'}}>{an[1] !== '0' && an[1] !== 0?addSpace(Math.round(an[1]*100)/100, true):'.'}</p>
                        </div>
                        :null)}

                    </div>
                    ):null}
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
}
  
  export default Page30BilanFP;