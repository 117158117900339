import React, { useEffect, useState, useRef } from 'react';
import { Page, Image, Document, PDFViewer } from '@react-pdf/renderer';
import Navbar from "../components/Navbar";
import { getyears } from "../components/Tools";
import axios from 'axios';
import qs from 'qs';
import { toPng } from 'html-to-image';
import { ReactComponent as IconPdfWhite } from '../Img/icon-pdf-white.svg';
import { ReactComponent as IconScrollUp } from '../Img/scroll-up.svg';
import BounceLoader from "react-spinners/BounceLoader";
import Page1Accueil from "./Page1Accueil";
import Page2Sommaire from "./Page2Sommaire";
import Page3Fct from "./Page3Fct";
import Page4RestitutionFct from "./Page4RestitutionFct";
import Page5Graph from './Page5Graph';
import Page6Graph from './Page6Graph';
import Page7Graph from './Page7Graph';
import Page8Graph from './Page8Graph';
import Page9Graph from './Page9Graph';
import Page10Inv from "./Page10Inv";
import Page11RestitutionInv from "./Page11RestitutionInv";
import Page12Graph from './Page12Graph';
import Page13Graph from './Page13Graph';
import Page14Fiscalite from './Page14Fiscalite';
import Page15FiscaliteRetro from './Page15FiscaliteRetro';
import Page16Graph from './Page16Graph';
import Page17Graph from './Page17Graph';
import Page18Graph from './Page18Graph';
import Page19Graph from './Page19Graph';
import Page20Graph from './Page20Graph';
import Page21Graph from './Page21Graph';
import Page22Dette from './Page22Dette';
import Page23Graph from './Page23Graph';
import Page24Graph from './Page24Graph';
import Page25Graph from './Page25Graph';
import Page26Graph from './Page26Graph';
import Page27FichesProjets from './Page27FichesProjets';
import Page28FicheProjet from './Page28FicheProjet';
import Page29RecapFP from './Page29RecapFP';
import Page30BilanFP from './Page30BilanFP';
import Page31ProjectionFinanciere from './Page31ProjectionFinanciere';
import Page32ComFP from './Page32ComFP';
import Page33RestitutionProsp from './Page33RestitutionProsp';
import Page34FiscaliteProsp from './Page34FiscaliteProsp';
import Page35Graph from './Page35Graph';
import Page36Graph from './Page36Graph';
import Page37Graph from './Page37Graph';
import Page38Graph from './Page38Graph';
import Page39Graph from './Page39Graph';
import Page40Graph from './Page40Graph';
import Page41Graph from './Page41Graph';


/*  La variable projet contient toutes les infos du projet et est structurée ainsi:
    projet[0] = id
    projet[1] = numero_projet
    projet[2] = nom
    projet[3] = enjeu
    projet[4] = description
    projet[5] = montant
    projet[6] = h1
    projet[7] = h2
    projet[8] = h3
    projet[9] = Dépenses de fonctionnement
    projet[10] = Recettes de fonctionnement
    projet[11] = Dépenses d'investissement
    projet[12] = Recettes d'investissement
*/
const BilanPDF = (props) =>
{
    const green = '#59B44A'
    const purple = '#6571FE'

    const [PDFREADY, setPDFREADY] = useState(false)
    const [aff, setAff] = useState(false)
    const [affRetro, setAffRetro] = useState(true)
    const [affProsp, setAffProsp] = useState(true)
    const [affH1, setAffH1] = useState(true)
    const [affH2, setAffH2] = useState(true)
    const [affH3, setAffH3] = useState(true)
    const [affFP, setAffFP] = useState(true)
    const [NBFP, setNBFP] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [affPDF, setAffPDF] = useState(false)
    const [listeProjets, setListeProjets] = useState(null)
    const [yearsRetro, setYearsRetro] = useState(null)
    const [yearsProsp, setYearsProsp] = useState(null)
    const date = new Date()
    const day = date.getDate()<10?'0'+date.getDate():date.getDate();
    const month = (date.getMonth()+1)<10?'0'+(date.getMonth()+1):(date.getMonth()+1);
    const hour = date.getHours()<10?'0'+date.getHours():date.getHours();
    const minutes = date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes();
    const dateFR = 'Le  '+day+' / '+month+' / '+date.getFullYear()+' à '+hour+':'+minutes;
    const [incid_fin_h1, setIncid_fin_h1] = useState(null);
    const [incid_fin_h2, setIncid_fin_h2] = useState(null);
    const [incid_fin_h3, setIncid_fin_h3] = useState(null);
    const [hypothese1, setHypothese1] = useState({'num': 1, 'nom': '', 'commentaire': ''});
    const [hypothese2, setHypothese2] = useState({'num': 2, 'nom': '', 'commentaire': ''});
    const [hypothese3, setHypothese3] = useState({'num': 3, 'nom': '', 'commentaire': ''});
    const [restitution_fct_h1, setRestitution_fct_h1] = useState(null);
    const [restitution_evol_fct_h1, setRestitution_evol_fct_h1] = useState(null);
    const [restitution_fct_h2, setRestitution_fct_h2] = useState(null);
    const [restitution_evol_fct_h2, setRestitution_evol_fct_h2] = useState(null);
    const [restitution_fct_h3, setRestitution_fct_h3] = useState(null);
    const [restitution_evol_fct_h3, setRestitution_evol_fct_h3] = useState(null);
    const [restitution_inv_h1, setRestitution_inv_h1] = useState(null);
    const [restitution_inv_h2, setRestitution_inv_h2] = useState(null);
    const [restitution_inv_h3, setRestitution_inv_h3] = useState(null);
    const [fiscalite_retro, setFiscalite_retro] = useState(null);
    const [fiscalite_prosp_h1, setFiscalite_prosp_h1] = useState(null);
    const [fiscalite_prosp_h2, setFiscalite_prosp_h2] = useState(null);
    const [fiscalite_prosp_h3, setFiscalite_prosp_h3] = useState(null);
    const [restitutionRetroFct, setRestitutionRetroFct] = useState(null);
    const [restitutionEvolRetroFct, setRestitutionEvolRetroFct] = useState(null);
    const [restitutionRetroInv, setRestitutionRetroInv] = useState(null);
    const [logoOrganisme, setLogoOrganisme] = useState(null);
    const [showButtonScrollUp, setShowButtonScrollUp] = useState(false);
    const width = (window.innerWidth * 0.7);
    const height = (width * (210 / 297));
    
    
    const [page1, setPage1] = useState(null)
    const [page2, setPage2] = useState(null)
    const [page3, setPage3] = useState(null)
    const [page4, setPage4] = useState(null)
    const [page4b, setPage4b] = useState(null)
    const [page5, setPage5] = useState(null)
    const [page6, setPage6] = useState(null)
    const [page7, setPage7] = useState(null)
    const [page8, setPage8] = useState(null)
    const [page9, setPage9] = useState(null)
    const [page10, setPage10] = useState(null)
    const [page11, setPage11] = useState(null)
    const [page12, setPage12] = useState(null)
    const [page13, setPage13] = useState(null)
    const [page14, setPage14] = useState(null)
    const [page15, setPage15] = useState(null)
    const [page16, setPage16] = useState(null)
    const [page17, setPage17] = useState(null)
    const [page18, setPage18] = useState(null)
    const [page19, setPage19] = useState(null)
    const [page20, setPage20] = useState(null)
    const [page21, setPage21] = useState(null)
    const [page22, setPage22] = useState(null)
    const [page23, setPage23] = useState(null)
    const [page24, setPage24] = useState(null)
    const [page25, setPage25] = useState(null)
    const [page26, setPage26] = useState(null)
    const [page27, setPage27] = useState(null)
    const [page28FP, setPage28FP] = useState(null)
    const [page29, setPage29] = useState(null)
    const [page30, setPage30] = useState(null)
    const [page31, setPage31] = useState(null)
    const [page32, setPage32] = useState(null)
    const [page33, setPage33] = useState(null)
    const [page34, setPage34] = useState(null)
    const [page35, setPage35] = useState(null)
    const [page35b, setPage35b] = useState(null)
    const [page36, setPage36] = useState(null)
    const [page37, setPage37] = useState(null)
    const [page38, setPage38] = useState(null)
    const [page39, setPage39] = useState(null)
    const [page39b, setPage39b] = useState(null)
    const [page40, setPage40] = useState(null)
    const [page41, setPage41] = useState(null)
    const [page42, setPage42] = useState(null)
    const [page43, setPage43] = useState(null)
    const [page43b, setPage43b] = useState(null)
    const [page44, setPage44] = useState(null)
    const [page45, setPage45] = useState(null)
    const [page46, setPage46] = useState(null)
    const [page47, setPage47] = useState(null)
    const [page48, setPage48] = useState(null)
    const [page49, setPage49] = useState(null)
    const [page50, setPage50] = useState(null)
    const [page51, setPage51] = useState(null)
    const [page52, setPage52] = useState(null)
    const [page53, setPage53] = useState(null)

    const elementRef1 = useRef(null);
    const elementRef2 = useRef(null);
    const elementRef3 = useRef(null);
    const elementRef4 = useRef(null);
    const elementRef4b = useRef(null);
    const elementRef5 = useRef(null);
    const elementRef6 = useRef(null);
    const elementRef7 = useRef(null);
    const elementRef8 = useRef(null);
    const elementRef9 = useRef(null);
    const elementRef10 = useRef(null);
    const elementRef11 = useRef(null);
    const elementRef12 = useRef(null);
    const elementRef13 = useRef(null);
    const elementRef14 = useRef(null);
    const elementRef15 = useRef(null);
    const elementRef16 = useRef(null);
    const elementRef17 = useRef(null);
    const elementRef18 = useRef(null);
    const elementRef19 = useRef(null);
    const elementRef20 = useRef(null);
    const elementRef21 = useRef(null);
    const elementRef22 = useRef(null);
    const elementRef23 = useRef(null);
    const elementRef24 = useRef(null);
    const elementRef25 = useRef(null);
    const elementRef26 = useRef(null);
    const elementRef27 = useRef(null);
    const elementRef28 = useRef([]);
    const elementRef29 = useRef(null);
    const elementRef30 = useRef(null);
    const elementRef31 = useRef(null);
    const elementRef32 = useRef(null);
    const elementRef33 = useRef(null);
    const elementRef34 = useRef(null);
    const elementRef35 = useRef(null);
    const elementRef35b = useRef(null);
    const elementRef36 = useRef(null);
    const elementRef37 = useRef(null);
    const elementRef38 = useRef(null);
    const elementRef39 = useRef(null);
    const elementRef39b = useRef(null);
    const elementRef40 = useRef(null);
    const elementRef41 = useRef(null);
    const elementRef42 = useRef(null);
    const elementRef43 = useRef(null);
    const elementRef43b = useRef(null);
    const elementRef44 = useRef(null);
    const elementRef45 = useRef(null);
    const elementRef46 = useRef(null);
    const elementRef47 = useRef(null);
    const elementRef48 = useRef(null);
    const elementRef49 = useRef(null);
    const elementRef50 = useRef(null);
    const elementRef51 = useRef(null);
    const elementRef52 = useRef(null);
    const elementRef53 = useRef(null);


    async function mount()
    {
        const params = {
            'getOrganismes': true,
            'id': props.user.id_organisme
        }
        await axios.post('https://app-slfinance.fr/api/organismes.php', qs.stringify(params))
        .then(async (res) => {
            await setLogoOrganisme(res.data[0].logo);
        })
        .catch((err) => {
            console.log(err)
        })

        /* Récupération des fiches projets */
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify({
            'getFichesProjets_totaux': true,
            'id_budget': props.user.id_budget,
        }))
        .then(async (result) => {
            await setListeProjets(result.data)
            await setNBFP(result.data.length)
            elementRef28.current = elementRef28.current.slice(0, result.data.length);
        })


        var ComEpci = "Com";
        if (props.user.id_organisme_type === "2") {
            ComEpci = "Epci";
        }
        if (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49') {
            ComEpci = props.user.nomen;
        }

        /* Récupération des annees retro */
        await getyears(props.user.id_budget, 'R')
        .then(async(annees_retro) => {
            await setYearsRetro(annees_retro)

            /* Récupération du tableau restitution fonctionnement retro */
            await axios.post('https://app-slfinance.fr/api/restitution.php', qs.stringify({
                'getRestitution': true,
                'grille': ComEpci+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'Retro':'') + 'Fct',
                'annees': annees_retro,
                'id_budget': props.user.id_budget,
                'id_organisme': props.user.id_organisme,
                'hypothese': 'R'
            }))
            .then(async(ret) => {
                await setRestitutionRetroFct(ret.data);

                /* Calcul des évolutions de ce tableau */
                var tmp = [];
                ret.data.forEach((element, i) => {
                    element.forEach((elem, j) => {
                        if (j === 0) {
                            tmp[i] = [];
                            tmp[i].push(elem);
                        }
                        if (j > 1) {
                            tmp[i].push([ret.data[i][j][0], ret.data[i][j-1][1]!==0?((ret.data[i][j][1] - ret.data[i][j-1][1]) / ret.data[i][j-1][1] * 100):0]);
                        }
                    });
                });
                await setRestitutionEvolRetroFct(tmp);
            })
            
            /* Récupération du tableau restitution investissement retro */
            await axios.post('https://app-slfinance.fr/api/restitution.php', qs.stringify({
                'getRestitution': true,
                'grille': (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?props.user.nomen:'') + 'RetroInv',
                'annees': annees_retro,
                'id_budget': props.user.id_budget,
                'id_organisme': props.user.id_organisme,
                'hypothese': 'R'
            }))
            .then(async(ret) => {
                await setRestitutionRetroInv(ret.data);
            })

            /* Récupération du tableau fiscalité retro */
            if (props.user.budget_annexe == '0') {
                await axios.post('https://app-slfinance.fr/api/datas_rei.php', qs.stringify({
                    'getGrilleSaisieRetroFisc': true,
                    'id_budget': props.user.id_budget,
                    'hypothese': 'R',
                    'an_deb': annees_retro[0],
                    'an_fin': annees_retro[annees_retro.length-1]
                }))
                .then(async (ret) => {
                    await setFiscalite_retro(ret.data)
                })
            }
        })
        

        /* Récupération des annees prosp */
        getyears(props.user.id_budget, 'P')
        .then(async(annees_prosp) =>
        {
            while(annees_prosp.length >= 9) {
                await annees_prosp.pop();
            }
            await setYearsProsp(annees_prosp)

            /* Récupération des Incidences financières H1 H2 H3 */
            await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
                'getSumIncidencesFinancieres': true,
                'annees': annees_prosp,
                'id_budget': props.user.id_budget,
                'hypothese': 'h1'
            }))
            .then(async(res) => {
                var total = [];
                await res.data.forEach((element, i) => {
                    total[i] = 0;
                    element.forEach((elem, j) => {
                        if (j > 0) {
                            elem[1] = parseFloat(elem[1]);
                            total[i] += (elem[1]);
                        }
                    });
                });
                await res.data.forEach((line, k) => {
                    res.data[k].push(['Total', total[k]])
                });
                
                setIncid_fin_h1(res.data)
            })

            await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
                'getSumIncidencesFinancieres': true,
                'annees': annees_prosp,
                'id_budget': props.user.id_budget,
                'hypothese': 'h2'
            }))
            .then(async(res) => {
                var total = [];
                await res.data.forEach((element, i) => {
                    total[i] = 0;
                    element.forEach((elem, j) => {
                        if (j > 0) {
                            elem[1] = parseFloat(elem[1]);
                            total[i] += (elem[1]);
                        }
                    });
                });
                await res.data.forEach((line, k) => {
                    res.data[k].push(['Total', total[k]])
                });
                setIncid_fin_h2(res.data)
            })

            await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
                'getSumIncidencesFinancieres': true,
                'annees': annees_prosp,
                'id_budget': props.user.id_budget,
                'hypothese': 'h3'
            }))
            .then(async(res) => {
                var total = [];
                await res.data.forEach((element, i) => {
                    total[i] = 0;
                    element.forEach((elem, j) => {
                        if (j > 0) {
                            elem[1] = parseFloat(elem[1]);
                            total[i] += (elem[1]);
                        }
                    });
                });
                await res.data.forEach((line, k) => {
                    res.data[k].push(['Total', total[k]])
                });
                setIncid_fin_h3(res.data)
            })

            /* Récupération du tableau restitution fonctionnement prosp H1 H2 H3 */
            await axios.post('https://app-slfinance.fr/api/datas_restitution.php', qs.stringify({
                'getDataRestitution': true,
                'grille': ComEpci+'Fct',
                'annees': annees_prosp,
                'id_budget': props.user.id_budget,
                'hypothese': 'h1'
            }))
            .then(async(ret) => {
                setRestitution_fct_h1(ret.data)

                /* Calcul évolution restitution Fct H1 */
                var tmp = [];
                ret.data.forEach((element, i) => {
                    element.forEach((elem, j) => {
                        if (j === 0) {
                            tmp[i] = [];
                            tmp[i].push(elem);
                        }
                        if (j > 1) {
                            tmp[i].push([ret.data[i][j][0], ret.data[i][j-1][1]!==0?((ret.data[i][j][1] - ret.data[i][j-1][1]) / ret.data[i][j-1][1] * 100):0]);
                        }
                    });
                });
                await setRestitution_evol_fct_h1(tmp);
            })

            await axios.post('https://app-slfinance.fr/api/datas_restitution.php', qs.stringify({
                'getDataRestitution': true,
                'grille': ComEpci+'Fct',
                'annees': annees_prosp,
                'id_budget': props.user.id_budget,
                'hypothese': 'h2'
            }))
            .then(async(ret) => {
                setRestitution_fct_h2(ret.data)

                /* Calcul évolution restitution Fct H2 */
                var tmp = [];
                ret.data.forEach((element, i) => {
                    element.forEach((elem, j) => {
                        if (j === 0) {
                            tmp[i] = [];
                            tmp[i].push(elem);
                        }
                        if (j > 1) {
                            tmp[i].push([ret.data[i][j][0], ret.data[i][j-1][1]!==0?((ret.data[i][j][1] - ret.data[i][j-1][1]) / ret.data[i][j-1][1] * 100):0]);
                        }
                    });
                });
                await setRestitution_evol_fct_h2(tmp);
            })

            await axios.post('https://app-slfinance.fr/api/datas_restitution.php', qs.stringify({
                'getDataRestitution': true,
                'grille': ComEpci+'Fct',
                'annees': annees_prosp,
                'id_budget': props.user.id_budget,
                'hypothese': 'h3'
            }))
            .then(async(ret) => {
                setRestitution_fct_h3(ret.data)

                /* Calcul évolution restitution Fct H3 */
                var tmp = [];
                ret.data.forEach((element, i) => {
                    element.forEach((elem, j) => {
                        if (j === 0) {
                            tmp[i] = [];
                            tmp[i].push(elem);
                        }
                        if (j > 1) {
                            tmp[i].push([ret.data[i][j][0], ret.data[i][j-1][1]!==0?((ret.data[i][j][1] - ret.data[i][j-1][1]) / ret.data[i][j-1][1] * 100):0]);
                        }
                    });
                });
                await setRestitution_evol_fct_h3(tmp);
            })


            /* Récupération du tableau restitution investissement prosp H1 H2 H3 */
            await axios.post('https://app-slfinance.fr/api/datas_restitution.php', qs.stringify({
                'getDataRestitution': true,
                'grille': (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?props.user.nomen:'') + 'ProspInv',
                'annees': annees_prosp,
                'id_budget': props.user.id_budget,
                'hypothese': 'h1'
            }))
            .then(async(ret) => {
                setRestitution_inv_h1(ret.data)
            })

            await axios.post('https://app-slfinance.fr/api/datas_restitution.php', qs.stringify({
                'getDataRestitution': true,
                'grille': (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?props.user.nomen:'') + 'ProspInv',
                'annees': annees_prosp,
                'id_budget': props.user.id_budget,
                'hypothese': 'h2'
            }))
            .then(async(ret) => {
                setRestitution_inv_h2(ret.data)
            })

            await axios.post('https://app-slfinance.fr/api/datas_restitution.php', qs.stringify({
                'getDataRestitution': true,
                'grille': (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?props.user.nomen:'') + 'ProspInv',
                'annees': annees_prosp,
                'id_budget': props.user.id_budget,
                'hypothese': 'h3'
            }))
            .then(async(ret) => {
                setRestitution_inv_h3(ret.data)
            })


            /* Récupération du tableau fiscalité prosp H1 H2 H3 */
            if (props.user.budget_annexe == 0) {
                await axios.post('https://app-slfinance.fr/api/datas_rei.php', qs.stringify({
                    'getGrilleSaisieProspFisc': true,
                    'id_budget': props.user.id_budget,
                    'hypothese': 'h1',
                    'an_deb': annees_prosp[0],
                    'an_fin': annees_prosp[annees_prosp.length-1]
                }))
                .then(async (ret) => {
                    await setFiscalite_prosp_h1(ret.data)
                })


                await axios.post('https://app-slfinance.fr/api/datas_rei.php', qs.stringify({
                    'getGrilleSaisieProspFisc': true,
                    'id_budget': props.user.id_budget,
                    'hypothese': 'h2',
                    'an_deb': annees_prosp[0],
                    'an_fin': annees_prosp[annees_prosp.length-1]
                }))
                .then(async (ret) => {
                    await setFiscalite_prosp_h2(ret.data)
                })


                await axios.post('https://app-slfinance.fr/api/datas_rei.php', qs.stringify({
                    'getGrilleSaisieProspFisc': true,
                    'id_budget': props.user.id_budget,
                    'hypothese': 'h3',
                    'an_deb': annees_prosp[0],
                    'an_fin': annees_prosp[annees_prosp.length-1]
                }))
                .then(async (ret) => {
                    await setFiscalite_prosp_h3(ret.data)
                })
            }
        })

        /* Récupération des noms et commentaires d'hypotheses */
        await axios.post('https://app-slfinance.fr/api/hypothese.php', qs.stringify({
            'get': true,
            'id_budget': props.user.id_budget
        }))
        .then((result) => {
            if (result.data.length !== 0) {
                result.data.forEach(element => {
                    if (element[0] === 'h1') {
                        setHypothese1({'num': 1, 'nom': element[1], 'commentaire': element[2]});
                    }
                    if (element[0] === 'h2') {
                        setHypothese2({'num': 2, 'nom': element[1], 'commentaire': element[2]});
                    }
                    if (element[0] === 'h3') {
                        setHypothese3({'num': 3, 'nom': element[1], 'commentaire': element[2]});
                    }
                });
            }
        })
        await setPDFREADY(true);
        await setAff(!aff);
    }


    useEffect(() => {
        if (props.user === null) {
            props.getUser();
        }
        else {
            mount();
        }
        
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setShowButtonScrollUp(true);
            } else {
                setShowButtonScrollUp(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);

    }, [props.user])


    const htmlToImageConvert = async() => {

        await toPng(elementRef1.current, { cacheBust: false })
        .then((dataUrl) => {
            setPage1(dataUrl)
        })

        await toPng(elementRef2.current, { cacheBust: false })
        .then((dataUrl) => {
            setPage2(dataUrl)
        })

        if (affRetro) {
            await toPng(elementRef3.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage3(dataUrl)
            })
            await toPng(elementRef4.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage4(dataUrl)
            })
            await toPng(elementRef4b.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage4b(dataUrl)
            })
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef5.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage5(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef6.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage6(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef7.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage7(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef8.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage8(dataUrl)
                })
            }
            await toPng(elementRef9.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage9(dataUrl)
            })
            await toPng(elementRef10.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage10(dataUrl)
            })
            await toPng(elementRef11.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage11(dataUrl)
            })
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef12.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage12(dataUrl)
                })
            }
            await toPng(elementRef13.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage13(dataUrl)
            })
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef14.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage14(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef15.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage15(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef16.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage16(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef17.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage17(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef18.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage18(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef19.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage19(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef20.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage20(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef21.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage21(dataUrl)
                })
            }
            await toPng(elementRef22.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage22(dataUrl)
            })
            await toPng(elementRef23.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage23(dataUrl)
            })
            await toPng(elementRef24.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage24(dataUrl)
            })
            await toPng(elementRef25.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage25(dataUrl)
            })
            await toPng(elementRef26.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage26(dataUrl)
            })
        }

        if (affFP) {
            await toPng(elementRef27.current, { cacheBust: false })
            .then(async(dataUrl) => {
                await setPage27(dataUrl)
            })
    
            var indexfp = 0;
            var tmp = [];
            while (elementRef28.current[indexfp])
            {
                await toPng(elementRef28.current[indexfp], { cacheBust: false })
                .then(async(dataUrl) => {
                    await tmp.push(dataUrl);
                })
                indexfp++;
            }
            await setPage28FP(tmp);
    
            await toPng(elementRef29.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage29(dataUrl)
            })
            if (affH1) {
                await toPng(elementRef30.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage30(dataUrl)
                })
            }
            if (affH2) {
                await toPng(elementRef31.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage31(dataUrl)
                })
            }
            if (affH3) {
                await toPng(elementRef32.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage32(dataUrl)
                })
            }
        }

        if (affProsp) {
            await toPng(elementRef33.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage33(dataUrl)
            })
            if (affH1) {
                await toPng(elementRef34.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage34(dataUrl)
                })
                await toPng(elementRef35.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage35(dataUrl)
                })
                await toPng(elementRef35b.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage35b(dataUrl)
                })
                await toPng(elementRef36.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage36(dataUrl)
                })
                if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                    await toPng(elementRef37.current, { cacheBust: false })
                    .then((dataUrl) => {
                        setPage37(dataUrl)
                    })
                }
            }
            if (affH2) {
                await toPng(elementRef38.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage38(dataUrl)
                })
                await toPng(elementRef39.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage39(dataUrl)
                })
                await toPng(elementRef39b.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage39b(dataUrl)
                })
                await toPng(elementRef40.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage40(dataUrl)
                })
                if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                    await toPng(elementRef41.current, { cacheBust: false })
                    .then((dataUrl) => {
                        setPage41(dataUrl)
                    })
                }
            }
            if (affH3) {
                await toPng(elementRef42.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage42(dataUrl)
                })
                await toPng(elementRef43.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage43(dataUrl)
                })
                await toPng(elementRef43b.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage43b(dataUrl)
                })
                await toPng(elementRef44.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage44(dataUrl)
                })
                if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                    await toPng(elementRef45.current, { cacheBust: false })
                    .then((dataUrl) => {
                        setPage45(dataUrl)
                    })
                }
            }
            await toPng(elementRef46.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage46(dataUrl)
            })
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef47.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage47(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef48.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage48(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef49.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage49(dataUrl)
                })
            }
            if (props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49') {
                await toPng(elementRef50.current, { cacheBust: false })
                .then((dataUrl) => {
                    setPage50(dataUrl)
                })
            }
            await toPng(elementRef51.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage51(dataUrl)
            })
            await toPng(elementRef52.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage52(dataUrl)
            })
            await toPng(elementRef53.current, { cacheBust: false })
            .then((dataUrl) => {
                setPage53(dataUrl)
            })
        }

        await setClicked(false);
        await setAff(!aff);
    };


    return (
    <div style={{flex: 1, position: 'relative'}}>
        {affPDF === false?
        <Navbar />
        :null}
        
        {showButtonScrollUp && (
        <button
        style={{position: 'fixed', bottom: 20, right: 20, width: (width/10), height: (height/10), border: '1px solid black', borderRadius: 5}}
        onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
            <IconScrollUp width={(width/10)}/>
        </button>
        )}

        {affPDF === false?
        <div style={{display: 'flex'}}>

            <div style={{flex: 1}}></div>

            <div style={{flex: 1, marginTop: 20, textAlign: 'center'}}>
                <p style={{color: purple, fontSize: 20}}>Prévisualisation du PDF</p>
            </div>

            <div style={{flex: 1}}></div>
        </div>
        :null}

        {affPDF === false?
        <div style={{flex: 1, display: 'flex'}}>

            <div style={{flex: 1}}></div>
            
            <button
            className='shadow-md rounded-md'
            style={
                affRetro?
                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, marginLeft: 20, paddingLeft: 5, paddingRight: 5}
                :
                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, marginLeft: 20, paddingLeft: 5, paddingRight: 5}
            }
            onClick={async() => {
                await setAffRetro(!affRetro);
                await setAff(!aff);
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <p style={{margin: 5}}>Rétrospective</p>
                </div>
            </button>
            
            <button
            className='shadow-md rounded-md'
            style={
                affFP?
                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                :
                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
            }
            onClick={async() => {
                await setAffFP(!affFP);
                await setAff(!aff);
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <p style={{margin: 5}}>Fiches projets</p>
                </div>
            </button>
            
            <button
            className='shadow-md rounded-md'
            style={
                affProsp?
                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                :
                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
            }
            onClick={async() => {
                await setAffProsp(!affProsp);
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <p style={{margin: 5}}>Prospective</p>
                </div>
            </button>

            <button
            className='shadow-md rounded-md'
            style={
                affH1?
                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                :
                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
            }
            onClick={async() => {
                if (affH1) {
                    await setAffH1(false)
                }
                else {
                    await setAffH1(true)
                }
                await setAff(!aff);
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <p style={{margin: 5}}>Hypothèse 1</p>
                </div>
            </button>

            <button
            className='shadow-md rounded-md'
            style={
                affH2?
                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                :
                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
            }
            onClick={async() => {
                if (affH2) {
                    await setAffH2(false)
                }
                else {
                    await setAffH2(true)
                }
                await setAff(!aff);
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <p style={{margin: 5}}>Hypothèse 2</p>
                </div>
            </button>

            <button
            className='shadow-md rounded-md'
            style={
                affH3?
                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                :
                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
            }
            onClick={async() => {
                if (affH3) {
                    await setAffH3(false)
                }
                else {
                    await setAffH3(true)
                }
                await setAff(!aff);
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <p style={{margin: 5}}>Hypothèse 3</p>
                </div>
            </button>

            <div style={{flex: 1}}></div>
            
        </div>
        :null}
        

        {props.user !== null && yearsRetro !== null && yearsProsp !== null && incid_fin_h1 !== null && incid_fin_h2 !== null && incid_fin_h3 !== null?
            <div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>

            {affPDF?
            <PDFViewer width='100%' height={window.innerHeight}>
                <Document title={'Bilan'}>
                    <Page size="A4" orientation="landscape">
                        <Image src={page1} style={{width: '100%', height: '100%'}}/>
                    </Page>

                    <Page size="A4" orientation="landscape">
                        <Image src={page2} style={{width: '100%', height: '100%'}}/>
                    </Page>

                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page3} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page4} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page4b} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page5} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page6} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page7} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page8} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page9} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page10} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page11} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page12} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page13} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page14} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page15} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page16} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page17} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page18} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page19} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page20} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <Page size="A4" orientation="landscape">
                            <Image src={page21} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page22} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page24} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page23} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page25} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affRetro?
                        <Page size="A4" orientation="landscape">
                            <Image src={page26} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}

                    {affFP?
                        <Page size="A4" orientation="landscape">
                            <Image src={page27} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                        
                    {affFP && page28FP !== null?
                        page28FP.map((url, j) =>
                        <Page key={j} size="A4" orientation="landscape">
                            <Image src={url} style={{width: '100%', height: '100%'}}/>
                        </Page>)
                    :null}
                        
                    {affFP?
                        <Page size="A4" orientation="landscape">
                            <Image src={page29} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affFP && affH1?
                        <Page size="A4" orientation="landscape">
                            <Image src={page30} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affFP && affH2?
                        <Page size="A4" orientation="landscape">
                            <Image src={page31} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    {affFP && affH3?
                        <Page size="A4" orientation="landscape">
                            <Image src={page32} style={{width: '100%', height: '100%'}}/>
                        </Page>
                    :null}
                    
                    {affProsp?
                    <Page size="A4" orientation="landscape">
                        <Image src={page33} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH1?
                    <Page size="A4" orientation="landscape">
                        <Image src={page34} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH1?
                    <Page size="A4" orientation="landscape">
                        <Image src={page35} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH1?
                    <Page size="A4" orientation="landscape">
                        <Image src={page35b} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH1?
                    <Page size="A4" orientation="landscape">
                        <Image src={page36} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH1 && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                    <Page size="A4" orientation="landscape">
                        <Image src={page37} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH2?
                    <Page size="A4" orientation="landscape">
                        <Image src={page38} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH2?
                    <Page size="A4" orientation="landscape">
                        <Image src={page39} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH2?
                    <Page size="A4" orientation="landscape">
                        <Image src={page39b} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH2?
                    <Page size="A4" orientation="landscape">
                        <Image src={page40} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH2 && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                    <Page size="A4" orientation="landscape">
                        <Image src={page41} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH3?
                    <Page size="A4" orientation="landscape">
                        <Image src={page42} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH3?
                    <Page size="A4" orientation="landscape">
                        <Image src={page43} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH3?
                    <Page size="A4" orientation="landscape">
                        <Image src={page43b} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH3?
                    <Page size="A4" orientation="landscape">
                        <Image src={page44} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && affH3 && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                    <Page size="A4" orientation="landscape">
                        <Image src={page45} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp?
                    <Page size="A4" orientation="landscape">
                        <Image src={page46} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                    <Page size="A4" orientation="landscape">
                        <Image src={page47} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                    <Page size="A4" orientation="landscape">
                        <Image src={page48} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                    <Page size="A4" orientation="landscape">
                        <Image src={page49} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                    <Page size="A4" orientation="landscape">
                        <Image src={page50} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp?
                    <Page size="A4" orientation="landscape">
                        <Image src={page51} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp?
                    <Page size="A4" orientation="landscape">
                        <Image src={page52} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    {affProsp?
                    <Page size="A4" orientation="landscape">
                        <Image src={page53} style={{width: '100%', height: '100%'}}/>
                    </Page>
                    :null}
                    
                </Document>
            </PDFViewer>
            :
            /* PARTIE PRÉVISUALISATION */
            <div style={{display: 'flex'}}>
                <div style={{flex: 0.15}}></div>
                <div style={{flex: 1}}>

                    <div style={{flex: 1, margin: 25, display: 'flex'}}>
                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef1}>
                            <Page1Accueil user={props.user} yearsRetro={yearsRetro} date={dateFR} logoOrganisme={logoOrganisme} />
                        </div>
                    </div>
                                        
                    <div style={{flex: 1, margin: 25, display: 'flex'}}>
                        <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef2}>
                            <Page2Sommaire user={props.user} titre={'Sommaire'} date={dateFR} index={2} affRetro={affRetro} affFP={affFP} affProsp={affProsp} affH1={affH1} affH2={affH2} affH3={affH3} NBFP={NBFP} />
                        </div>
                    </div>

                    {affRetro?
                    <div>
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef3}>
                                <Page3Fct user={props.user} date={dateFR} index={3} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef4}>
                                <Page4RestitutionFct user={props.user} annees={yearsRetro} date={dateFR} restitution={restitutionRetroFct} titre={'Équilibre ' + (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement')} index={4} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef4b}>
                                <Page4RestitutionFct user={props.user} annees={yearsRetro} date={dateFR} restitution={restitutionEvolRetroFct} titre={'Évolution ' + (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement')} index={5} evolution={true} />
                            </div>
                        </div>


                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef5}>
                                <Page5Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Répartition des recettes et dépenses ' + (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'de fonctionnement')} index={6} />
                            </div>
                        </div>
                        :null}

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef6}>
                                <Page6Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Dynamique générale ' + (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement')} index={7} />
                            </div>
                        </div>
                        :null}

                        
                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef7}>
                                <Page7Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Structure des recettes de fonctionnement'} index={8} />
                            </div>
                        </div>
                        :null}

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef8}>
                                <Page8Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Structure des dépenses ' + (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'de fonctionnement')} index={9} />
                            </div>
                        </div>
                        :null}

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef9}>
                                <Page9Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Taux d\'épargne'} index={10} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef10}>
                                <Page10Inv user={props.user} date={dateFR} index={11} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef11}>
                                <Page11RestitutionInv user={props.user} annees={yearsRetro} date={dateFR} restitution={restitutionRetroInv} titre={'Financement de l\'investissement'} index={12} />
                            </div>
                        </div>

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef12}>
                                <Page12Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Dynamique d\'investissement'} index={13} />
                            </div>
                        </div>
                        :null}

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef13}>
                                <Page13Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Résultat de clôture et restes à réaliser'} index={14} />
                            </div>
                        </div>

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef14}>
                                <Page14Fiscalite user={props.user} date={dateFR} index={15} />
                            </div>
                        </div>
                        :null}

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef15}>
                                <Page15FiscaliteRetro user={props.user} annees={yearsRetro} date={dateFR} restitution={fiscalite_retro} titre={'Recettes de fiscalité directe'} index={16} />
                            </div>
                        </div>
                        :null}

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef16}>
                                <Page16Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Niveau des bases d\'imposition'} index={17} />
                            </div>
                        </div>
                        :null}

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef17}>
                                <Page17Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Évolution des bases d\'imposition'} index={18} />
                            </div>
                        </div>
                        :null}

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef18}>
                                <Page18Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Bases d\'imposition par habitant'} index={19} />
                            </div>
                        </div>
                        :null}


                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef19}>
                                <Page19Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Niveaux des taux d\'imposition'} index={20} />
                            </div>
                        </div>
                        :null}

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef20}>
                                <Page20Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Évolution des taux d\'imposition'} index={21} />
                            </div>
                        </div>
                        :null}

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef21}>
                                <Page21Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Panier des ressources fiscales'} index={22} />
                            </div>
                        </div>
                        :null}

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef22}>
                                <Page22Dette user={props.user} annees={yearsRetro} date={dateFR} index={23} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef24}>
                                <Page24Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Encours de la dette au 31/12'} index={25} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef23}>
                                <Page23Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Extinction de la dette'} index={24} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef25}>
                                <Page25Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Indicateurs d\'endettement'} index={26} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef26}>
                                <Page26Graph user={props.user} annees={yearsRetro} date={dateFR} titre={'Indicateurs d\'endettement'} index={27} />
                            </div>
                        </div>
                    </div>
                    :null}

                    {affFP?
                    <div>
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef27}>
                                <Page27FichesProjets user={props.user} date={dateFR} index={!affRetro?3:28} />
                            </div>
                        </div>
        
                        {listeProjets !== null?
                        listeProjets.map((projet, i) =>
                        <div key={i} style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={el => elementRef28.current[i] = el}>
                                <Page28FicheProjet user={props.user} date={dateFR} projet={projet} index={((!affRetro?4:29) + i)} yearsProsp={yearsProsp} />
                            </div>
                        </div>
                        ):null}
        
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef29}>
                                <Page29RecapFP user={props.user} yearsProsp={yearsProsp} date={dateFR} listeProjets={listeProjets} titre={'Récapitulatif financier des projets'} index={((!affRetro?4:29) + NBFP)} />
                            </div>
                        </div>

                        {affH1?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef30}>
                                <Page30BilanFP user={props.user} yearsProsp={yearsProsp} date={dateFR} hypothese={1} objet={incid_fin_h1} index={((!affRetro?5:30) + NBFP)} />
                            </div>
                        </div>
                        :null}

                        {affH2?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef31}>
                                <Page30BilanFP user={props.user} yearsProsp={yearsProsp} date={dateFR} hypothese={2} objet={incid_fin_h2} index={((!affRetro?6:31) + NBFP - (!affH1?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH3?
                            <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef32}>
                                <Page30BilanFP user={props.user} yearsProsp={yearsProsp} date={dateFR} hypothese={3} objet={incid_fin_h3} index={((!affRetro?7:32) + NBFP  - (!affH1?1:0) - (!affH2?1:0))} />
                            </div>
                        </div>
                        :null}
                        </div>
                    :null}

                    {affProsp?
                    <div>
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef33}>
                                <Page31ProjectionFinanciere user={props.user} date={dateFR} index={(3 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?1:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>

{/* H1 */}
                        {affH1?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef34}>
                                <Page32ComFP user={props.user} date={dateFR} hypothese={hypothese1} index={(4 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?1:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH1?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef35}>
                                <Page33RestitutionProsp user={props.user} annees={yearsProsp} date={dateFR} restitution={restitution_fct_h1} titre={'Équilibres '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement') +' projetés'} hypothese={hypothese1} index={(5 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?1:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH1?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef35b}>
                                <Page33RestitutionProsp user={props.user} annees={yearsProsp} date={dateFR} restitution={restitution_evol_fct_h1} titre={'Évolutions '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement') +' projetées'} hypothese={hypothese1} index={(6 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?1:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} evolution={true} />
                            </div>
                        </div>
                        :null}

                        {affH1?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef36}>
                                <Page33RestitutionProsp user={props.user} annees={yearsProsp} date={dateFR} restitution={restitution_inv_h1} titre={'Financement des investissements projetés'} hypothese={hypothese1} index={(7 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?1:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH1 && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef37}>
                                <Page34FiscaliteProsp user={props.user} annees={yearsProsp} date={dateFR} objet={fiscalite_prosp_h1} titre={'Ressources fiscales projetées'} hypothese={hypothese1} index={(8 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?1:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

{/* H2 */}
                        {affH2?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef38}>
                                <Page32ComFP user={props.user} date={dateFR} hypothese={hypothese2} index={(9 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH2?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef39}>
                                <Page33RestitutionProsp user={props.user} annees={yearsProsp} date={dateFR} restitution={restitution_fct_h2} titre={'Équilibres '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement') +' projetés'} hypothese={hypothese2} index={(10 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH2?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef39b}>
                                <Page33RestitutionProsp user={props.user} annees={yearsProsp} date={dateFR} restitution={restitution_evol_fct_h2} titre={'Évolutions '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement') +' projetées'} hypothese={hypothese2} index={(11 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} evolution={true} />
                            </div>
                        </div>
                        :null}

                        {affH2?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef40}>
                                <Page33RestitutionProsp user={props.user} annees={yearsProsp} date={dateFR} restitution={restitution_inv_h2} titre={'Financement des investissements projetés'} hypothese={hypothese2} index={(12 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH2 && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef41}>
                                <Page34FiscaliteProsp user={props.user} annees={yearsProsp} date={dateFR} objet={fiscalite_prosp_h2} titre={'Ressources fiscales projetées'} hypothese={hypothese2} index={(13 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?1:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

{/* H3 */}
                        {affH3?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef42}>
                                <Page32ComFP user={props.user} date={dateFR} hypothese={hypothese3} index={(14 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH3?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef43}>
                                <Page33RestitutionProsp user={props.user} annees={yearsProsp} date={dateFR} restitution={restitution_fct_h3} titre={'Équilibres '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement') +' projetés'} hypothese={hypothese3} index={(15 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH3?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef43b}>
                                <Page33RestitutionProsp user={props.user} annees={yearsProsp} date={dateFR} restitution={restitution_evol_fct_h3} titre={'Évolutions '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement') +' projetées'} hypothese={hypothese3} index={(16 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?1:0))} evolution={true} />
                            </div>
                        </div>
                        :null}

                        {affH3?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef44}>
                                <Page33RestitutionProsp user={props.user} annees={yearsProsp} date={dateFR} restitution={restitution_inv_h3} titre={'Financement des investissements projetés'} hypothese={hypothese3} index={(17 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        {affH3 && props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef45}>
                                <Page34FiscaliteProsp user={props.user} annees={yearsProsp} date={dateFR} objet={fiscalite_prosp_h3} titre={'Ressources fiscales projetées'} hypothese={hypothese3} index={(18 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?1:0))} />
                            </div>
                        </div>
                        :null}

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef46}>
                                <Page31ProjectionFinanciere user={props.user} date={dateFR} index={(19 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?6:0) - (!affFP && !affH3?5:0))} />
                            </div>
                        </div>

                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef47}>
                                <Page35Graph aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} annees={yearsProsp} date={dateFR} titre={'Dynamique générale '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'du fonctionnement')} index={(20 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?6:0) - (!affFP && !affH3?5:0))} />
                            </div>
                        </div>
                        :null}


                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef48}>
                                <Page36Graph aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} annees={yearsProsp} date={dateFR} titre={'Évolution des recettes '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'de fonctionnement')} index={(21 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?6:0) - (!affFP && !affH3?5:0))} />
                            </div>
                        </div>
                        :null}


                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef49}>
                                <Page37Graph aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} annees={yearsProsp} date={dateFR} titre={'Évolution des dépenses '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'de fonctionnement')} index={(22 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?6:0) - (!affFP && !affH3?5:0))} />
                            </div>
                        </div>
                        :null}


                        {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef50}>
                                <Page38Graph aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} annees={yearsProsp} date={dateFR} titre={'Indicateurs complémentaires de la section '+ (props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'exploitation':'fonctionnement')} index={(23 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?6:0) - (!affFP && !affH3?5:0))} />
                            </div>
                        </div>
                        :null}

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef51}>
                                <Page39Graph aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} annees={yearsProsp} date={dateFR} titre={'Taux d\'épargne'} index={(24 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?6:0) - (!affFP && !affH3?5:0))} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef52}>
                                <Page40Graph aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} annees={yearsProsp} date={dateFR} titre={'Endettement projeté'} index={(25 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?6:0) - (!affFP && !affH3?5:0))} />
                            </div>
                        </div>

                        <div style={{flex: 1, margin: 25, display: 'flex'}}>
                            <div className='shadow-md p-2 rounded-md' style={{flex: 1, backgroundColor: 'white', height: 800}} ref={elementRef53}>
                                <Page41Graph aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} annees={yearsProsp} date={dateFR} titre={'Financement de l\'investissement'} index={(26 + (affRetro?25:0) + (affFP?(5+NBFP):0) - (affFP && !affH1?6:0) - (!affFP && !affH1?5:0) - (affFP && !affH2?6:0) - (!affFP && !affH2?5:0) - (affFP && !affH3?6:0) - (!affFP && !affH3?5:0))} />
                            </div>
                        </div>
                    </div>
                    :null}
                </div>

                <div style={{flex: 0.15}}>
                    {!clicked && PDFREADY && restitutionRetroFct !== null && restitutionEvolRetroFct !== null && restitutionRetroInv !== null?
                    <button
                    className='shadow-md rounded-md'
                    style={{backgroundColor: green, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}}
                    onClick={async() => {
                        if (props.user) {
                            await setClicked(true);
                            await htmlToImageConvert();
                            await setAffPDF(true);
                        }
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconPdfWhite width="18"/>
                            <p style={{margin: 5}}>Créer le PDF</p>
                        </div>
                    </button>
                    :
                    <div style={{flex: 1, alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                        <BounceLoader color={purple} />
                    </div>
                    }
                </div>
                
            </div>
            }
        </div>
        :
        <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 100}}>
            <BounceLoader color={purple} />
        </div>}
    </div>
    );
}

export default BilanPDF;
