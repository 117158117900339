import React from 'react';
import { addSpace, rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';


const Page2NomDonneesGenerales = (props) =>
{
    const blue = '#069BD3';
    const greenpastel = '#b8e994';
    const orangepastel = '#ffbe76';
    const redpastel = '#ff7979';
    const circle = (props.height*70/950);
    const fontsize = (props.height*12/890);
    const sizelogo = props.width/6;

    return (
        <div style={{width: '100%', height: '100%', padding: 20}}>
            
            {/* Header Photos du projet */}
            <div style={{height: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{flex: 1}}></div>
                {props.logo && (
                    <img style={{objectFit: 'contain', maxHeight: '100%', maxWidth: '100%', borderRadius: 5}} src={props.logo} alt="Logo" />
                )}
                <div style={{flex: 1}}></div>
            </div>

            {/* Container 1 Numero et nom du projet  */}
            <div style={{height: '50%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div
                        style={props.projet[27] === greenpastel?
                        {margin: 3, border: '2px solid '+greenpastel, borderRadius: 100, width: circle, height: circle, backgroundColor: greenpastel}
                        :
                        {margin: 3, border: '2px solid '+greenpastel, borderRadius: 100, width: circle, height: circle, backgroundColor: 'white'}}>
                        </div>
                        <div
                        style={props.projet[27] === orangepastel?
                        {margin: 3, border: '2px solid '+orangepastel, borderRadius: 100, width: circle, height: circle, backgroundColor: orangepastel}
                        :
                        {margin: 3, border: '2px solid '+orangepastel, borderRadius: 100, width: circle, height: circle, backgroundColor: 'white'}}>
                        </div>
                        <div
                        style={props.projet[27] === redpastel?
                        {margin: 3, border: '2px solid '+redpastel, borderRadius: 100, width: circle, height: circle, backgroundColor: redpastel}
                        :
                        {margin: 3, border: '2px solid '+redpastel, borderRadius: 100, width: circle, height: circle, backgroundColor: 'white'}}>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 5}}>
                        <p style={{fontSize: (fontsize+8)}}>Projet n° {props.projet[2]}</p>
                    </div>
                    <p style={{marginTop: 15, color: blue, fontSize: (fontsize+12)}}>{props.projet[3]}</p>
                </div>
            </div>

            
            {/* Footer */}
            <div style={{height: '20%', position: 'relative'}}>
                <div style={{position: 'absolute', bottom: 0}}>
                    <p style={{fontSize: (fontsize-2)}}>{props.date}</p>
                </div>
                <div style={{position: 'absolute', bottom: 0, left: '47%', display: 'flex'}}>
                    <p style={{fontSize: fontsize}}>page : {props.pagination} / {props.countPageTotal}</p>
                </div>
                <img style={{position: 'absolute', bottom: 0, right: 0, objectFit: 'contain', width: sizelogo, borderRadius: 5}} src={Logo} alt="Logo" />
            </div>

        </div>
    );
}
  
  export default Page2NomDonneesGenerales;