import './Navbar.css';
import React, {useState, useEffect, useRef} from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { ReactComponent as IconUser } from '../Img/icon-user.svg';
import { ReactComponent as IconNotif } from '../Img/icon-notif.svg';
import { ReactComponent as IconRetro } from '../Img/icon-back-time.svg';
import { ReactComponent as IconProsp } from '../Img/icon-increase.svg';
import { ReactComponent as IconLine } from '../Img/icon-line.svg';
import { ReactComponent as IconProjects } from '../Img/icon-projects.svg';
import { ReactComponent as IconCalendar } from '../Img/icon-calendar.svg';
import { ReactComponent as IconCamembert } from '../Img/icon-camembert.svg';
import { ReactComponent as IconComments } from '../Img/icon-comments.svg';
import { ReactComponent as IconPdf } from '../Img/icon-pdf.svg';
import { checkUser, rescueHtml } from "./Tools";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import ModalComments from './ModalBlocNotes';


const Navbar = () =>
{
    let history = useHistory();
    const [aff, setAff] = useState(false);
    const [user, setUser] = useState({});
    const [profil, setProfil] = useState(false);
    const [notif, setNotif] = useState(false);
    const [retro, setRetro] = useState(false);
    const [fp, setFp] = useState(false);
    const [prosp, setProsp] = useState(false);
    const [tabBord, setTabBord] = useState(false);
    const [modalComments, setModalComments] = useState(false);
    const pathname = window.location.pathname;
    let retro_prosp = window.location.pathname.split('/')[2];

    const purple = '#6571FE';
    const greyChapter = '#DBDBDB';
    
        
    function getUser()
    {
        const token = localStorage.getItem("session_token")
        checkUser(token)
        .then(async (res) => {
            var tmp = res
            tmp.email = rescueHtml(res.email)
            tmp.nom = rescueHtml(res.nom)
            tmp.prenom = rescueHtml(res.prenom)
            if (res.nom_organisme) {
                tmp.nom_organisme = rescueHtml(res.nom_organisme)
            }

            await setUser(tmp)
            await setAff(!aff)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    

    useEffect(() => {
        getUser()
    }, [])
    

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setProfil(false);
                    setNotif(false);
                    setRetro(false);
                    setFp(false);
                    setProsp(false);
                    setTabBord(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }


    function updateHypotheseUser(hyp)
    {
        var params = {
            'updateUser': true,
            'hypothese': hyp,
            'id': user.id
        }
        axios.post('https://app-slfinance.fr/api/connexion.php', qs.stringify(params))
        .then((e) => {
            if (e.data['status'] === 1) {
                // NotificationManager.success(e.data['status_message'], '', 700);
                setTimeout(() => window.location = window.location.href, 100);
            }
            else {
                // NotificationManager.warning(e.data['status_message'], '', 3000);
            }
        })
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);


	return (
        <div>
            <div className='shadow-md' style={{flex: 1, backgroundColor: 'white', minHeight: 72}}>

                {/* -------- Premier bandeau horizontal -------- */}
                <div style={{display: 'flex', flex: 1}}>
                    <a style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', border: '0px'}} href="/">
                        <img style={{ height: 50 }} src={Logo} alt="Logo" />
                    </a>
                    
                    <div style={{flex: 1}}></div>

                    {retro_prosp && retro_prosp === 'prospective'?
                    <div>
                        {user.hypothese === '1'?
                        <button
                        className='shadow-md rounded-md'
                        style={{padding: 5, textAlign: 'center', borderRadius: 5, backgroundColor: greyChapter, margin: 15, border: '2px solid '+purple, color: purple}}
                        onClick={() => {}}>
                            <p>Hypothèse 1</p>
                        </button>
                        :
                        <button
                        className='shadow-md rounded-md'
                        style={{padding: 5, textAlign: 'center', borderRadius: 5, backgroundColor: greyChapter, margin: 15}}
                        onClick={() => {
                            updateHypotheseUser(1);
                        }}>
                            <p>Hypothèse 1</p>
                        </button>
                        }
                
                        {user.hypothese === '2'?
                        <button
                        className='shadow-md rounded-md'
                        style={{padding: 5, textAlign: 'center', borderRadius: 5, backgroundColor: greyChapter, margin: 15, border: '2px solid '+purple, color: purple}}
                        onClick={() => {}}>
                            <p>Hypothèse 2</p>
                        </button>
                        :
                        <button
                        className='shadow-md rounded-md'
                        style={{padding: 5, textAlign: 'center', borderRadius: 5, backgroundColor: greyChapter, margin: 15}}
                        onClick={() => {
                            updateHypotheseUser(2);
                        }}>
                            <p>Hypothèse 2</p>
                        </button>
                        }
                
                        {user.hypothese === '3'?
                        <button
                        className='shadow-md rounded-md'
                        style={{padding: 5, textAlign: 'center', borderRadius: 5, backgroundColor: greyChapter, margin: 15, border: '2px solid '+purple, color: purple}}
                        onClick={() => {}}>
                            <p>Hypothèse 3</p>
                        </button>
                        :
                        <button
                        className='shadow-md rounded-md'
                        style={{padding: 5, textAlign: 'center', borderRadius: 5, backgroundColor: greyChapter, margin: 15}}
                        onClick={() => {
                            updateHypotheseUser(3);
                        }}>
                            <p>Hypothèse 3</p>
                        </button>
                        }
                    </div>
                    :null}

                    <div style={{flex: 1, textAlign: 'right'}}>
                        {user.nom_organisme?
                        <div style={{margin: 25}}>
                            <a href="/organismepage">
                                <p style={{color: purple, fontWeight: 'bold', fontSize: 18}}>{user.nom_organisme.toUpperCase()}</p>
                                <p style={{color: 'grey', fontSize: 14}}>{user.nom_budget}  {user.email==='victor.castera@strategies-locales.fr'?' - '+user.id_budget:null}</p>
                            </a>
                        </div>
                        :null}
                    </div>

                    {/* Bouton Notifications */}
                    {user.email ?
                    <button
                    style={{marginRight: 20}}
                    onClick={() => {
                        setNotif(!notif)
                    }}>
                        <IconNotif width="25" />
                    </button>
                    :null}

                    {/* Bouton Profil ou Connexion */}
                    {user.email ?
                    <button
                    style={{marginRight: 20}}
                    onClick={() => {
                        setProfil(!profil)
                    }}>
                        <IconUser width="28" />
                    </button>
                    :null}

                </div>
                
                {/* Modal Profil */}
                {profil?
                <div ref={wrapperRef} className='shadow-xl' style={{position: 'absolute', right: 10, backgroundColor: 'white', borderRadius: 5, padding: 25, zIndex: 1, border: '2px solid #E7E7E7', textAlign: 'center'}}>
                    
                    {(user.nom || user.prenom) ?
                    <p style={{color: purple, fontWeight: 'bold'}}>{rescueHtml(user.nom)} {rescueHtml(user.prenom)}</p>
                    :null}

                    <div style={{marginTop: 20}}>
                        <a href="/moncompte">
                            <p style={{color: 'black'}}>Mon Profil</p>
                        </a>
                    </div>
                    <div style={{marginTop: 20}}>
                        <button onClick={() => {history.push('/logout')}}>
                            <p>Déconnexion</p>
                        </button>
                    </div>
                </div>
                :null}
                
                {/* Modal Notifications */}
                {notif?
                <div ref={wrapperRef} className='shadow-xl' style={{position: 'absolute', right: 10, backgroundColor: 'white', borderRadius: 5, padding: 25, zIndex: 1, border: '2px solid #E7E7E7', textAlign: 'center'}}>
                    <p>Pas de notifications</p>
                </div>
                :null}
            </div>
            
            
            
            {/* -------- Deuxième bandeau horizontal -------- */}
            {user.email?
            <div className='shadow-md' style={{flex: 1, backgroundColor: 'white', marginTop: 0.5, display: 'flex', minHeight: 65}}>

                {/* Bouton Retrospective */}
                <button
                style={{marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => {
                    setRetro(!retro);
                }}
                >
                    <IconRetro width="20px" />
                    <p style={{flex: 1, marginLeft: 10}}>Retrospective</p>
                </button>

                {/* Bouton Fiches projets */}
                <button
                style={{marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => {
                    setFp(!fp);
                    // window.location = window.location.origin + '/saisie/prospective/fichesprojets';
                }}
                >
                    <IconProjects width="20px" />
                    <p style={{flex: 1, marginLeft: 10}}>Pilotage projets</p>
                </button>

                {/* Bouton Prospective */}
                <button
                style={{marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => {
                    setProsp(!prosp);
                }}
                >
                    <IconProsp width="20px" />
                    <p style={{flex: 1, marginLeft: 10}}>Prospective</p>
                </button>

                {/* Bouton Tableau de bord */}
                <button
                style={{marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => {
                    setTabBord(!tabBord);
                }}
                >
                    <IconCalendar width="20px" />
                    <p style={{flex: 1, marginLeft: 10}}>TdB mensuel</p>
                </button>

                {/* Bouton Liste des Graphiques */}
                <button
                style={{marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => {
                    window.location = window.location.origin + '/graphiques'
                }}
                >
                    <IconCamembert width="20px" />
                    <p style={{flex: 1, marginLeft: 10}}>Graphiques</p>
                </button>


                {/* Bouton Édition du rapport */}
                <button
                style={{marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => {
                    window.location = window.location.origin + '/rapport/financier'
                }}
                >
                    <IconPdf width="20px" />
                    <p style={{flex: 1, marginLeft: 10}}>Rapport financier</p>
                </button>

                <div style={{flex: 1}}></div>


                {/* Bouton des bloc-notes */}
                {!modalComments &&
                pathname !== '/graphiques' &&
                pathname !== '/adminpage' &&
                pathname !== '/rapport/financier' &&
                pathname !== '/saisie/prospective/commentaire' &&
                !pathname.includes('restitution') &&
                !pathname.includes('projet') &&
                pathname !== '/verification/retrospective' && 
                (
                <button
                style={{marginRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                onClick={() => {
                    if (!modalComments &&
                        pathname !== '/graphiques' &&
                        pathname !== '/adminpage' &&
                        pathname !== '/rapport/financier' &&
                        pathname !== '/saisie/prospective/commentaire' &&
                        !pathname.includes('restitution') &&
                        !pathname.includes('projet') &&
                        pathname !== '/verification/retrospective'
                    ) {
                        setModalComments(true);
                    }
                }}
                >
                    <IconComments width="20px" />
                    <p style={{flex: 1, marginLeft: 10, fontSize: 12}}>Bloc-notes</p>
                </button>
                )}

            </div>
            :null}

            
            {/* Modal Commentaires */}
            {modalComments && (
                <ModalComments user={user} modalVisible={modalComments} setModalVisible={setModalComments} />
            )}



            {/* Modal Retrospective */}
            {retro?
            <div
            ref={wrapperRef}
            className='shadow-xl'
            style={{position: 'absolute', left: 5, backgroundColor: 'white', borderRadius: 5, padding: 25, zIndex: 1, border: '2px solid #E7E7E7', display: 'flex'}}
            >
                <div>
                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1}}>
                            <p style={{fontWeight: 'bold'}}>Saisie :</p>
                        </div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginTop: 20, marginLeft: 'auto'}}>
                            <p>Fonctionnement</p>
                        </div>
                    </div>

                    <div style={{marginTop: 20, display: 'flex', textAlign: 'center'}}>
                        <a href="/saisie/retrospective/fct/recette">
                            <p style={{color: purple}}>Recettes</p>
                        </a>
                        <p style={{marginLeft: 5}}> \ </p>
                        <a style={{marginLeft: 5}} href="/saisie/retrospective/fct/depense">
                            <p style={{color: purple}}>Dépenses</p>
                        </a>
                    </div>

                    <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>

                    <div style={{marginTop: 0, textAlign: 'center'}}>
                        <p>Investissement</p>
                    </div>

                    <div style={{marginTop: 20, display: 'flex', textAlign: 'center'}}>
                        <a href="/saisie/retrospective/inv/recette">
                            <p style={{color: purple}}>Recettes</p>
                        </a>
                        <p style={{marginLeft: 5}}> \ </p>
                        <a style={{marginLeft: 5}} href="/saisie/retrospective/inv/depense">
                            <p style={{color: purple}}>Dépenses</p>
                        </a>
                    </div>

                    <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>

                    {user.budget_annexe == 0?
                    <div style={{marginTop: 0, textAlign: 'center'}}>
                        <a href="/saisie/retrospective/fiscalite">
                            <p style={{color: purple}}>Fiscalité</p>
                        </a>
                    </div>
                    :null}

                    {user.budget_annexe == 0?
                    <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>
                    :null}

                    <div style={{marginTop: 0, textAlign: 'center'}}>
                        <a href="/saisie/dette">
                            <p style={{color: purple}}>Dette</p>
                        </a>
                    </div>

                    <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>

                    <div style={{marginTop: 0, textAlign: 'center'}}>
                        <a href="/verification/retrospective">
                            <p style={{color: purple}}>Vérification</p>
                        </a>
                    </div>
                </div>

                <div style={{paddingLeft: 25, marginLeft: 25, borderLeft: '1px solid black'}}>
                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1}}>
                            <p style={{fontWeight: 'bold'}}>Restitution :</p>
                        </div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginTop: 20, marginLeft: 'auto'}}>
                            <a href="/restitution/retrospective/fct">
                                <p style={{color: purple}}>Fonctionnement</p>
                            </a>
                        </div>
                    </div>

                    <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginLeft: 'auto'}}>
                            <a href="/restitution/retrospective/inv">
                                <p style={{color: purple}}>Investissement</p>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            :null}

            {/* Modal Fiches projets */}
            {fp?
            <div
            ref={wrapperRef}
            className='shadow-xl'
            style={{position: 'absolute', left: 170, backgroundColor: 'white', borderRadius: 5, padding: 25, zIndex: 1, border: '2px solid #E7E7E7', display: 'flex'}}
            >
                <div>
                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1}}>
                            <p style={{fontWeight: 'bold'}}>Saisie :</p>
                        </div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginTop: 20, marginLeft: 'auto'}}>
                            <a href="/saisie/prospective/fichesprojets">
                                <p style={{color: purple}}>Pilotage des projets</p>
                            </a>
                        </div>
                    </div>
                </div>

                <div style={{paddingLeft: 25, marginLeft: 25, borderLeft: '1px solid black'}}>
                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1}}>
                            <p style={{fontWeight: 'bold'}}>Rapports :</p>
                        </div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginTop: 20, marginLeft: 'auto'}}>
                            <a href="/fichesprojets/listing">
                                <p style={{color: purple}}>Fiches projets</p>
                            </a>
                        </div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginTop: 20, marginLeft: 'auto'}}>
                            <a href="/rapport/synthese/projets">
                                <p style={{color: purple}}>Synthèse globale</p>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            :null}

            {/* Modal Prospective */}
            {prosp?
            <div ref={wrapperRef} className='shadow-xl' style={{position: 'absolute', left: 315, backgroundColor: 'white', borderRadius: 5, padding: 25, zIndex: 1, border: '2px solid #E7E7E7', display: 'flex'}}>
                <div>
                    <div style={{textAlign: 'center'}}>
                        <p style={{fontWeight: 'bold'}}>Saisie :</p>
                    </div>

                    <div style={{marginTop: 20, textAlign: 'center'}}>
                        <p>Fonctionnement</p>
                    </div>

                    <div style={{marginTop: 20, display: 'flex', textAlign: 'center'}}>
                        <a href="/saisie/prospective/fct/recette">
                            <p style={{color: purple}}>Recettes</p>
                        </a>
                        <p style={{marginLeft: 5}}> \ </p>
                        <a style={{marginLeft: 5}} href="/saisie/prospective/fct/depense">
                            <p style={{color: purple}}>Dépenses</p>
                        </a>
                    </div>


                    <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>

                    {user.budget_annexe == 0?
                    <div style={{marginTop: 0, textAlign: 'center'}}>
                        <a href="/saisie/prospective/fiscalite">
                            <p style={{color: purple}}>Fiscalité</p>
                        </a>
                    </div>
                    :null}

                    {user.budget_annexe == 0?
                    <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>
                    :null}

                    <div style={{marginTop: 0, textAlign: 'center'}}>
                        <a href="/saisie/prospective/commentaire">
                            <p style={{color: purple}}>Commentaires</p>
                        </a>
                    </div>
                </div>

                <div style={{paddingLeft: 25, marginLeft: 25, borderLeft: '1px solid black'}}>
                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1}}>
                            <p style={{fontWeight: 'bold'}}>Restitution :</p>
                        </div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginTop: 20, marginLeft: 'auto'}}>
                            <a href="/restitution/prospective/fct">
                                <p style={{color: purple}}>Fonctionnement</p>
                            </a>
                        </div>
                    </div>

                    <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginLeft: 'auto'}}>
                            <a href="/restitution/prospective/inv">
                                <p style={{color: purple}}>Investissement</p>
                            </a>
                        </div>
                    </div>

                </div>

                <div style={{paddingLeft: 25, marginLeft: 25, borderLeft: '1px solid black'}}>
                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1}}>
                            <p style={{fontWeight: 'bold'}}>Outils de saisie rapide :</p>
                        </div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginTop: 20, marginLeft: 'auto'}}>
                            <a href="/emprunts/prospective/manuel">
                                <p style={{color: purple}}>Saisie des emprunts</p>
                                <p style={{color: purple}}> futurs</p>
                            </a>
                        </div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginTop: 20, marginLeft: 'auto'}}>
                            <a href="/emprunts/auto">
                                <p style={{color: purple}}>Calcul automatique</p>
                                <p style={{color: purple}}>des emprunts d'équilibre</p>
                            </a>
                        </div>
                    </div>

                    {/* <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>

                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <div style={{flex: 1, marginLeft: 'auto'}}>
                            <a href="/restitution/prospective/inv/h1">
                                <p style={{color: purple}}>Investissement</p>
                            </a>
                        </div>
                    </div> */}

                </div>
            </div>
            :null}


            {/* Modal Tableau de bord */}
            {tabBord?
            <div ref={wrapperRef} className='shadow-xl' style={{position: 'absolute', left: 470, backgroundColor: 'white', borderRadius: 5, padding: 25, zIndex: 1, border: '2px solid #E7E7E7', display: 'flex'}}>
                <div>
                    <div style={{textAlign: 'center'}}>
                        <p style={{fontWeight: 'bold'}}>Tableau de bord :</p>
                    </div>

                    <div style={{marginTop: 20, display: 'flex', textAlign: 'center'}}>
                        <a style={{flex: 1, marginLeft: 5}} href="/tableaudebord">
                            <p style={{color: purple}}>Grille de saisie</p>
                        </a>
                    </div>


                    <div style={{marginLeft: 5, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <IconLine width="50px" />
                        <div style={{flex: 1}}></div>
                    </div>

                    <div style={{marginTop: 0, textAlign: 'center'}}>
                        <a style={{flex: 1}} href="/graphtableaudebord">
                            <p style={{color: purple}}>Édition du tableau</p>
                            <p style={{color: purple}}> de bord mensuel</p>
                        </a>
                    </div>
                </div>
            </div>
            :null}
        </div>
	);
}

export default Navbar;
