import React, { useEffect, useState, useRef } from 'react';
import { toPng } from 'html-to-image';
import { Page, Text, View, Document, PDFViewer } from '@react-pdf/renderer';
import { ReactComponent as IconPdfWhite } from '../Img/icon-pdf-white.svg';
import { ReactComponent as IconScrollUp } from '../Img/scroll-up.svg';
import axios from 'axios';
import qs from 'qs';
import Navbar from "../components/Navbar.js";
import Access from "../components/Access.js";
import { rescueHtml } from "../components/Tools.js";
import Logo from '../Img/Logo_projets&finances.png';
import BounceLoader from "react-spinners/BounceLoader";
import Page1Accueil from './Page1Accueil.js';
import Page2DonneesGenerales from './Page2DonneesGenerales.js';
import Page3Reponsables from './Page3Reponsables.js';
import Page4Pilotage from './Page4Pilotage.js';
import Page5BilanFinancier from './Page5BilanFinancier.js';
import Page6Gantt from './Page6Gantt.js';
import Page7Graphs from './Page7Graphs.js';
import Page1_PDF_Accueil from './Page1_PDF_Accueil.js';
import Page2_PDF_DonneesGenerales from './Page2_PDF_DonneesGenerales.js';
import Page3_PDF_Responsables from './Page3_PDF_Responsables.js';
import Page4_PDF_Pilotage from './Page4_PDF_Pilotage.js';
import Page5_PDF_BilanFinancier from './Page5_PDF_BilanFinancier.js';
import Page6_PDF_Gantt from './Page6_PDF_Gantt.js';
import Page7_PDF_Graphs from './Page7_PDF_Graphs.js';

const SyntheseProjets = (props) =>
{
    const blue = '#069BD3';
    const green = '#59B44A';
    const purple = '#6571FE';

    const [aff, setAff] = useState(false);
    const [affPDF, setAffPDF] = useState(false);
    const [affBounceLoader, setAffBounceLoader] = useState(false);
    const date = new Date();
    const day = date.getDate()<10?'0'+date.getDate():date.getDate();
    const month = (date.getMonth()+1)<10?'0'+(date.getMonth()+1):(date.getMonth()+1);
    const hour = date.getHours()<10?'0'+date.getHours():date.getHours();
    const minutes = date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes();
    const dateFR = 'Le  '+day+' / '+month+' / '+date.getFullYear()+' à '+hour+':'+minutes;
    const [logo, setLogo] = useState(null);
    const [projets, setProjets] = useState(null);
    const [liste_projets, setListe_projets] = useState(null);
    const pageRefsGantt = useRef([]);
    const pageRefsGraphs = useRef([]);
    const [imagesGantt, setImagesGantt] = useState([]);
    const [imagesGraph_h1, setImagesGraph_h1] = useState(null);
    const [imagesGraph_h2, setImagesGraph_h2] = useState(null);
    const [imagesGraph_h3, setImagesGraph_h3] = useState(null);
    const width = (window.innerWidth * 0.7) || 0;
    const height = (width * (210 / 297)) || 0;
    const [annees, setAnnees] = useState([]);
    const [affH1, setAffH1] = useState(true);
    const [affH2, setAffH2] = useState(true);
    const [affH3, setAffH3] = useState(true);
    const [countPages, setCountPages] = useState(0);
    const [showButtonScrollUp, setShowButtonScrollUp] = useState(false);
    const fontsize = (Math.round(height * 12 / 890 * 100)/100) || 12;
    const sizelogo = (Math.round(width / 6*100)/100) || 200;


    async function mount() {
        const params = {
            'getOrganismes': true,
            'id': props.user.id_organisme
        }
        await axios.post('https://app-slfinance.fr/api/organismes.php', qs.stringify(params))
        .then(async (res) => {
            await setLogo(res.data[0].logo);
        })
        .catch((err) => {
            console.log(err)
        })

        var tasks = [];
        // récupération de la derniere tache du GANTT
        await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
            'getTasks': true,
            'id_budget': props.user.id_budget
        }))
        .then((ret) => {
            tasks = ret.data.sort((a, b) => {
                return new Date(a.end) - new Date(b.end);
            });
        })

        var pilotages = [];
        // récupération de la liste des pilotages de ce budget (tous projets compris)
        await axios.post('https://app-slfinance.fr/api/pilotage_projet.php', qs.stringify({
            'get_by_id_budget': true,
            'id_budget': props.user.id_budget
        }))
        .then((ret) => {
            pilotages = ret.data.sort((a, b) => {
                return new Date(a.end) - new Date(b.end);
            });
        })
        
        // récupération des projets
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify({'getFichesProjets': true, 'id_budget': props.user.id_budget}))
        .then(async (result) => {
            var objet = {};
            var objetGantt = {}; // Nouvel array pour les projets avec tâches Gantt renseignées
            var tmp = [];
            var tmpGantt = []; // Temp array pour les projets avec tâches Gantt renseignées
            var j = 0;
            var jGantt = 0; // Index pour les projets avec tâches Gantt renseignées
            for(let i = 0; i < result.data.length; i++)
            {
                let hasGanttTask = false; // Flag pour vérifier si le projet a une tâche Gantt renseignée

                // Assignation de la derniere tache gantt au projet
                for(let k = 0; k < tasks.length; k++) {
                    if (tasks[k]['id_projet'] == result.data[i][0]) {
                        result.data[i]['end'] = tasks[k]['end'];
                        hasGanttTask = true; // Le projet a une tâche Gantt renseignée
                    }
                }

                // Assignation au projet de la derniere décision du pilotage
                for(let l = 0; l < pilotages.length; l++) {
                    if (pilotages[l][0] == result.data[i][0]) {
                        result.data[i]['pilotage_date'] = pilotages[l][1];
                        result.data[i]['pilotage_decision'] = pilotages[l][2];
                        result.data[i]['pilotage_date_limite'] = pilotages[l][3];
                        result.data[i]['pilotage_action_suivante'] = pilotages[l][4];
                    }
                }

                // i % 9 = Multiple de 9 pour afficher max 9 projets sur une page
                if (i % 9 === 0 && tmp.length > 0) {
                    objet[j] = tmp;
                    tmp = [];
                    j++;
                }
                await tmp.push(result.data[i]);

                // compte le nombre de page sans prendre en compte les pages qui appartiennent aux hyopthèses h1, h2, h3
                await setCountPages( 1 + ((j+1) * 3) + 1 + 6 );

                // Ajouter le projet à objetGantt si il a une tâche Gantt renseignée
                if (hasGanttTask) {
                    if (tmpGantt.length > 0 && tmpGantt.length % 9 === 0) {
                        objetGantt[jGantt] = tmpGantt;
                        tmpGantt = [];
                        jGantt++;
                    }
                    await tmpGantt.push(result.data[i]);
                }
            }
            if (tmp.length > 0) {
                objet[j] = tmp;
            }
            if (tmpGantt.length > 0) {
                objetGantt[jGantt] = tmpGantt;
            }
            await setProjets(objet);
            await setListe_projets(objetGantt);
        })
        .catch((err) => {
            console.log(err)
        })
        
        /* Recuperation des annees de prospective */
        let prosp_an_deb = '';
        let prosp_an_fin = '';

        try {
            const result = await axios.get(`https://app-slfinance.fr/api/config_budget.php?id_budget=${props.user.id_budget}`);
            result.data.forEach(element => {
                if (element[2] === 'prosp_an_deb') prosp_an_deb = parseInt(element[3]);
                if (element[2] === 'prosp_an_fin') prosp_an_fin = parseInt(element[3]);
            });
        } catch (err) {
            console.log(err);
        }

        if (!prosp_an_deb || !prosp_an_fin) {
            try {
                const res = await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales');
                if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                    if (!prosp_an_deb) prosp_an_deb = parseInt(res.data.prosp_an_deb);
                    if (!prosp_an_fin) prosp_an_fin = parseInt(res.data.prosp_an_fin);
                }
            } catch (err) {
                console.log(err);
            }
        }

        const liste_an = [];
        for (let i = 0; prosp_an_deb <= prosp_an_fin; prosp_an_deb++, i++) {
            liste_an[i] = prosp_an_deb;
        }
        await setAnnees(liste_an);
        await setAff(!aff);
    }


    useEffect(() => {
        if (props.user === null) {
            props.getUser();
        }
        else {
            mount();
        }

        const handleScroll = () => {
            if (window.scrollY > 500) {
                setShowButtonScrollUp(true);
            } else {
                setShowButtonScrollUp(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);

    }, [props.user])
    
    
    // Fonction pour capturer les pages GANTT et les convertir en images
    const capturePages = async () =>
    {
        const newImages = [];
        for (let i = 0; i < pageRefsGantt.current.length; i++) {
            if (pageRefsGantt.current[i]) {
                const image = await toPng(pageRefsGantt.current[i], { cacheBust: true });
                newImages.push(image);
            }
        }
        setImagesGantt(newImages);

        if (affH1 && pageRefsGraphs.h1) {
            const imageH1 = await toPng(pageRefsGraphs.h1, { cacheBust: true });
            setImagesGraph_h1(imageH1);
        }
        if (affH2 && pageRefsGraphs.h2) {
            const imageH2 = await toPng(pageRefsGraphs.h2, { cacheBust: true });
            setImagesGraph_h2(imageH2);
        }
        if (affH3 && pageRefsGraphs.h3) {
            const imageH3 = await toPng(pageRefsGraphs.h3, { cacheBust: true });
            setImagesGraph_h3(imageH3);
        }
    };


    return (
    <div style={{position: 'relative'}}>
        <Access needConnection={true} type_user={[0]}>
            {affPDF === false && (
                <Navbar user={props.user} />
            )}

            {showButtonScrollUp && affPDF === false && (
            <button
            style={{position: 'fixed', bottom: 20, right: 20, width: (width/10), height: (height/10), border: '1px solid black', borderRadius: 5}}
            onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                <IconScrollUp width={(width/10)}/>
            </button>
            )}
            
            {affPDF === false && (
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <div style={{flex: 1, display: 'flex'}}>
                            <p>Pilotage projets</p>
                            <p style={{marginLeft: 5}}>\</p>
                            <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Édition de la synthèse</p>
                        </div>
                        <div style={{flex: 1}}>
                            {props.user?
                            <p style={{flex: 1, textAlign: 'center'}}>{props.user.nom_budget}</p>
                            :null}
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>
                </div>
            )}

            <div style={{flex: 1}}>
                {affPDF === false?
                <div style={{display: 'flex'}}>

                    <div style={{flex: 1}}></div>

                    <div style={{flex: 1, marginTop: 20, textAlign: 'center'}}>
                        <p style={{color: purple, fontSize: 20}}>Prévisualisation du PDF</p>

                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                            <button
                            className='shadow-md rounded-md'
                            style={
                                affH1?
                                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                                :
                                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                            }
                            onClick={async() => {
                                if (affH1) {
                                    await setAffH1(false);
                                    await setCountPages(countPages - 2);
                                }
                                else {
                                    await setAffH1(true);
                                    await setCountPages(countPages + 2);
                                }
                                await setAff(!aff);
                            }}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: 5}}>Hypothèse 1</p>
                                </div>
                            </button>

                            <button
                            className='shadow-md rounded-md'
                            style={
                                affH2?
                                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                                :
                                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                            }
                            onClick={async() => {
                                if (affH2) {
                                    await setAffH2(false);
                                    await setCountPages(countPages - 2);
                                }
                                else {
                                    await setAffH2(true);
                                    await setCountPages(countPages + 2);
                                }
                                await setAff(!aff);
                            }}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: 5}}>Hypothèse 2</p>
                                </div>
                            </button>

                            <button
                            className='shadow-md rounded-md'
                            style={
                                affH3?
                                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                                :
                                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}
                            }
                            onClick={async() => {
                                if (affH3) {
                                    await setAffH3(false);
                                    await setCountPages(countPages - 2);
                                }
                                else {
                                    await setAffH3(true);
                                    await setCountPages(countPages + 2);
                                }
                                await setAff(!aff);
                            }}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: 5}}>Hypothèse 3</p>
                                </div>
                            </button>
                        
                        </div>

                    </div>

                    <div style={{flex: 1, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <div style={{flex: 0.5}}>
                            <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: green, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, paddingLeft: 5, paddingRight: 5}}
                            onClick={async () => {
                                if (affBounceLoader === false) {
                                    setAffBounceLoader(true);
                                    await capturePages();
                                    setAffPDF(true);
                                    setAff(!aff);
                                }
                            }}>
                                {affBounceLoader === false?
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <IconPdfWhite width="18"/>
                                    <p style={{margin: 5}}>Créer le PDF</p>
                                </div>
                                :
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <BounceLoader size={20} color='white' />
                                    <p style={{margin: 5}}>Création en cours...</p>
                                </div>
                                }
                            </button>
                        </div>

                    </div>

                </div>
                :null}
    

                {affPDF === true && props.user && dateFR !== '' && (
                <PDFViewer width='100%' height={window.innerHeight?window.innerHeight:200}>
                    <Document title={'SyntheseProjets'}>

                        <Page1_PDF_Accueil
                            user={props.user}
                            date={dateFR}
                            logo={logo}
                        />

                        {Object.values(projets).map((liste, i) => (
                        <React.Fragment key={i}>
                            <Page2_PDF_DonneesGenerales
                                nom={"Données générales"}
                                index={Object.values(projets).length > 1? i+1 : null}
                                indexTotal={Object.values(projets).length}
                                pagination={(i + 2)}
                                countPageTotal={countPages}
                                projets={liste}
                                user={props.user}
                                date={dateFR}
                                logo={logo}
                                width={width}
                                height={height}
                            />
                        </React.Fragment>
                        ))}
                        
                        {Object.values(projets).map((liste, i) => (
                        <React.Fragment key={i}>
                            <Page3_PDF_Responsables
                                nom={"Responsables du pilotage des projets"}
                                index={Object.values(projets).length > 1? i+1 : null}
                                indexTotal={Object.values(projets).length}
                                pagination={(i + 2 + (Object.values(projets).length * 1))}
                                countPageTotal={countPages}
                                projets={liste}
                                user={props.user}
                                date={dateFR}
                                logo={logo}
                                width={width}
                                height={height}
                            />
                        </React.Fragment>
                        ))}
                        
                        {Object.values(projets).map((liste, i) => (
                        <React.Fragment key={i}>
                            <Page4_PDF_Pilotage
                                nom={"Pilotage des projets"}
                                index={Object.values(projets).length > 1? i+1 : null}
                                indexTotal={Object.values(projets).length}
                                pagination={(i + 2 + (Object.values(projets).length * 2))}
                                countPageTotal={countPages}
                                projets={liste}
                                user={props.user}
                                date={dateFR}
                                logo={logo}
                                width={width}
                                height={height}
                            />
                        </React.Fragment>
                        ))}

                        {affH1 && (
                        <Page5_PDF_BilanFinancier
                            nom={"Bilan financier - Hypothèse 1"}
                            hypothese={'h1'}
                            pagination={((Object.values(projets).length * 3) + 2)}
                            countPageTotal={countPages}
                            annees={annees}
                            user={props.user}
                            date={dateFR}
                            logo={logo}
                            width={width}
                            height={height}
                        />
                        )}

                        {affH2 && (
                            <Page5_PDF_BilanFinancier
                            nom={"Bilan financier - Hypothèse 2"}
                            hypothese={'h2'}
                            pagination={((Object.values(projets).length * 3) + 2 + (affH1?1:0))}
                            countPageTotal={countPages}
                            annees={annees}
                            user={props.user}
                            date={dateFR}
                            logo={logo}
                            width={width}
                            height={height}
                        />
                        )}

                        {affH3 && (
                            <Page5_PDF_BilanFinancier
                            nom={"Bilan financier - Hypothèse 3"}
                            hypothese={'h3'}
                            pagination={((Object.values(projets).length * 3) + 2 + (affH1?1:0) + (affH2?1:0))}
                            countPageTotal={countPages}
                            annees={annees}
                            user={props.user}
                            date={dateFR}
                            logo={logo}
                            width={width}
                            height={height}
                        />
                        )}

                        {Object.values(liste_projets).map((liste, i) => (
                        <React.Fragment key={i}>
                            <Page6_PDF_Gantt
                                nom={"Calendrier de réalisation"}
                                pagination={((Object.values(projets).length * 3) + 2 + (affH1?1:0) + (affH2?1:0) + (affH3?1:0))}
                                countPageTotal={countPages}
                                user={props.user}
                                date={dateFR}
                                logo={logo}
                                width={width}
                                height={height}
                                img={imagesGantt[i]}
                            />
                        </React.Fragment>
                        ))}

                        {affH1 && (
                        <Page7_PDF_Graphs
                            nom={"Répartition des dépenses d'investissement"}
                            hypothese={'h1'}
                            pagination={((Object.values(projets).length * 3) + 3 + (affH1?1:0) + (affH2?1:0) + (affH3?1:0))}
                            countPageTotal={countPages}
                            annees={annees}
                            user={props.user}
                            date={dateFR}
                            logo={logo}
                            width={width}
                            height={height}
                            img={imagesGraph_h1}
                        />
                        )}

                        {affH2 && (
                        <Page7_PDF_Graphs
                            nom={"Répartition des dépenses d'investissement"}
                            hypothese={'h2'}
                            pagination={((Object.values(projets).length * 3) + 3 + (affH1?2:0) + (affH2?1:0) + (affH3?1:0))}
                            countPageTotal={countPages}
                            annees={annees}
                            user={props.user}
                            date={dateFR}
                            logo={logo}
                            width={width}
                            height={height}
                            img={imagesGraph_h2}
                        />
                        )}

                        {affH3 && (
                        <Page7_PDF_Graphs
                            nom={"Répartition des dépenses d'investissement"}
                            hypothese={'h3'}
                            pagination={((Object.values(projets).length * 3) + 3 + (affH1?2:0) + (affH2?2:0) + (affH3?1:0))}
                            countPageTotal={countPages}
                            annees={annees}
                            user={props.user}
                            date={dateFR}
                            logo={logo}
                            width={width}
                            height={height}
                            img={imagesGraph_h3}
                        />
                        )}

                    </Document>
                </PDFViewer>
                )}

    

                {props.user && projets && affPDF === false && (
                    <div style={{display: 'flex', marginTop: 50, marginBottom: 50}}>
                        <div style={{flex: 1}}></div>
                        <div>
                            <div className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height}}>
                                <Page1Accueil
                                    user={props.user}
                                    date={dateFR}
                                    logo={logo}
                                    width={width}
                                    height={height}
                                />
                            </div>
            
                            {Object.values(projets).map((liste, i) => (
                            <div key={i} className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                                <Page2DonneesGenerales
                                    nom={"Données générales"}
                                    index={Object.values(projets).length > 1? i+1 : null}
                                    indexTotal={Object.values(projets).length} pagination={(i + 2)}
                                    countPageTotal={countPages}
                                    projets={liste}
                                    user={props.user}
                                    date={dateFR}
                                    logo={logo}
                                    width={width}
                                    height={height}
                                />
                            </div>
                            ))}
            
                            {Object.values(projets).map((liste, i) => (
                            <div key={i} className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                                <Page3Reponsables
                                    nom={"Responsables du pilotage des projets"}
                                    index={Object.values(projets).length > 1? i+1 : null}
                                    indexTotal={Object.values(projets).length}
                                    pagination={(i + Object.values(projets).length + 2)}
                                    countPageTotal={countPages}
                                    projets={liste}
                                    user={props.user}
                                    date={dateFR}
                                    logo={logo}
                                    width={width}
                                    height={height}
                                />
                            </div>
                            ))}
            
                            {Object.values(projets).map((liste, i) => (
                            <div key={i} className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                                <Page4Pilotage
                                    nom={"Pilotage des projets"}
                                    index={Object.values(projets).length > 1? i+1 : null}
                                    indexTotal={Object.values(projets).length}
                                    pagination={(i + (Object.values(projets).length * 2) + 2)}
                                    countPageTotal={countPages}
                                    projets={liste}
                                    user={props.user}
                                    date={dateFR}
                                    logo={logo}
                                    width={width}
                                    height={height}
                                />
                            </div>
                            ))}

                            {affH1 && (
                            <div className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                                <Page5BilanFinancier
                                    nom={"Bilan financier - Hypothèse 1"}
                                    hypothese={'h1'}
                                    pagination={( (Object.values(projets).length * 3) + 2)}
                                    countPageTotal={countPages}
                                    annees={annees}
                                    user={props.user}
                                    date={dateFR}
                                    logo={logo}
                                    width={width}
                                    height={height}
                                />
                            </div>
                            )}

                            {affH2 && (
                            <div className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                                <Page5BilanFinancier
                                    nom={"Bilan financier - Hypothèse 2"}
                                    hypothese={'h2'}
                                    pagination={( (Object.values(projets).length * 3) + 2 + (affH1?1:0))}
                                    countPageTotal={countPages}
                                    annees={annees}
                                    user={props.user}
                                    date={dateFR}
                                    logo={logo}
                                    width={width}
                                    height={height}
                                />
                            </div>
                            )}

                            {affH3 && (
                            <div className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                                <Page5BilanFinancier
                                    nom={"Bilan financier - Hypothèse 3"}
                                    hypothese={'h3'}
                                    pagination={( (Object.values(projets).length * 3) + 2 + (affH1?1:0) + (affH2?1:0))}
                                    countPageTotal={countPages}
                                    annees={annees}
                                    user={props.user}
                                    date={dateFR}
                                    logo={logo}
                                    width={width}
                                    height={height}
                                />
                            </div>
                            )}


                            {Object.values(liste_projets).map((liste, i) => (
                            <div key={i} className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>

                                <div style={{ width: '100%', height: '100%', padding: 20 }}>

                                    {/* Header */}
                                    <div style={{ display: 'flex', height: '20%' }}>
                                        <div style={{ flex: 0.4, display: 'flex', marginLeft: 10 }}>
                                            {logo && (
                                                <img style={{ maxHeight: height / 8, borderRadius: 5 }} src={logo} alt="Logo" />
                                            )}
                                        </div>

                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <p style={{ color: blue, fontSize: (fontsize + 10) }}>Calendrier de réalisation</p>
                                        </div>

                                        <div style={{ flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25 }}>
                                            <p style={{ color: 'grey', fontSize: (fontsize + 4) }}>Synthèse globale des projets</p>
                                        </div>
                                    </div>

                                    <div style={{ height: '60%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} ref={(el) => (pageRefsGantt.current[i] = el)}>
                                        <Page6Gantt
                                            nom={"Calendrier de réalisation"}
                                            pagination={((Object.values(projets).length * 3) + 2 + (affH1?1:0) + (affH2?1:0) + (affH3?1:0))}
                                            countPageTotal={countPages}
                                            projets={liste}
                                            annees={annees}
                                            user={props.user}
                                            date={dateFR}
                                            logo={logo}
                                            width={width}
                                            height={height}
                                        />
                                    </div>
                            
                                    {/* Footer */}
                                    <div style={{ display: 'flex', height: '20%', position: 'relative' }}>
                                        <div style={{ position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200 }}>
                                            <p style={{ fontSize: (fontsize - 2) }}>{dateFR}</p>
                                        </div>
                                        <div style={{ position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <p style={{ fontSize: fontsize }}>page : {((Object.values(projets).length * 3) + 2 + (affH1?1:0) + (affH2?1:0) + (affH3?1:0))} / {countPages}</p>
                                        </div>
                                        <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                                            <img style={{width: sizelogo}} src={Logo} />
                                        </div>
                                    </div>
                    
                                </div>
                            </div>
                            ))}

                            {affH1 && (
                            <div className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                                
                                <div style={{ width: '100%', height: '100%', padding: 20 }}>

                                    {/* Header */}
                                    <div style={{ display: 'flex', height: '20%' }}>
                                        <div style={{ flex: 0.4, display: 'flex', marginLeft: 10 }}>
                                            {logo && (
                                                <img style={{ maxHeight: height / 8, borderRadius: 5 }} src={logo} alt="Logo" />
                                            )}
                                        </div>

                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{textAlign: 'center'}}>
                                                <p style={{ color: blue, fontSize: (fontsize + 10) }}>Répartition des dépenses d'investissement</p>
                                                <p style={{ color: 'grey', fontSize: (fontsize + 5) }}>Hypothèse 1</p>
                                            </div>
                                        </div>

                                        <div style={{ flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25 }}>
                                            <p style={{ color: 'grey', fontSize: (fontsize + 4) }}>Synthèse globale des projets</p>
                                        </div>
                                    </div>

                                    {/* Container */}
                                    <div style={{ height: '60%', flex: 1 }} ref={(el) => (pageRefsGraphs.h1 = el)}>
                                        <Page7Graphs
                                            hypothese={'h1'}
                                            annees={annees}
                                            user={props.user}
                                            height={height}
                                        />
                                    </div>
                                    
                                    {/* Footer */}
                                    <div style={{ display: 'flex', height: '20%', position: 'relative' }}>
                                        <div style={{ position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200 }}>
                                            <p style={{ fontSize: (fontsize - 2) }}>{dateFR}</p>
                                        </div>
                                        <div style={{ position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <p style={{ fontSize: fontsize }}>page : {((Object.values(projets).length * 3) + 3 + (affH1?1:0) + (affH2?1:0) + (affH3?1:0))} / {countPages}</p>
                                        </div>
                                        <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                                            <img style={{width: sizelogo}} src={Logo} />
                                        </div>
                                    </div>
                        
                                </div>

                            </div>
                            )}

                            {affH2 && (
                            <div className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                                
                                <div style={{ width: '100%', height: '100%', padding: 20 }}>

                                    {/* Header */}
                                    <div style={{ display: 'flex', height: '20%' }}>
                                        <div style={{ flex: 0.4, display: 'flex', marginLeft: 10 }}>
                                            {logo && (
                                                <img style={{ maxHeight: height / 8, borderRadius: 5 }} src={logo} alt="Logo" />
                                            )}
                                        </div>

                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{textAlign: 'center'}}>
                                                <p style={{ color: blue, fontSize: (fontsize + 10) }}>Répartition des dépenses d'investissement</p>
                                                <p style={{ color: 'grey', fontSize: (fontsize + 5) }}>Hypothèse 2</p>
                                            </div>
                                        </div>

                                        <div style={{ flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25 }}>
                                            <p style={{ color: 'grey', fontSize: (fontsize + 4) }}>Synthèse globale des projets</p>
                                        </div>
                                    </div>

                                    {/* Container */}
                                    <div style={{ height: '60%', flex: 1 }} ref={(el) => (pageRefsGraphs.h2 = el)}>
                                        <Page7Graphs
                                            nom={"Répartition des dépenses d'investissement"}
                                            hypothese={'h2'}
                                            pagination={((Object.values(projets).length * 3) + 3 + (affH1?2:0) + (affH2?1:0) + (affH3?1:0))}
                                            countPageTotal={countPages}
                                            annees={annees}
                                            user={props.user}
                                            date={dateFR}
                                            logo={logo}
                                            width={width}
                                            height={height}
                                        />
                                    </div>
                                    
                                    {/* Footer */}
                                    <div style={{ display: 'flex', height: '20%', position: 'relative' }}>
                                        <div style={{ position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200 }}>
                                            <p style={{ fontSize: (fontsize - 2) }}>{dateFR}</p>
                                        </div>
                                        <div style={{ position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <p style={{ fontSize: fontsize }}>page : {((Object.values(projets).length * 3) + 3 + (affH1?2:0) + (affH2?1:0) + (affH3?1:0))} / {countPages}</p>
                                        </div>
                                        <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                                            <img style={{width: sizelogo}} src={Logo} />
                                        </div>
                                    </div>
                        
                                </div>
                            </div>
                            )}

                            {affH3 && (
                            <div className='shadow-md p-2 rounded-md' style={{backgroundColor: 'white', width: width, height: height, marginTop: 50}}>
                                
                                <div style={{ width: '100%', height: '100%', padding: 20 }}>

                                    {/* Header */}
                                    <div style={{ display: 'flex', height: '20%' }}>
                                        <div style={{ flex: 0.4, display: 'flex', marginLeft: 10 }}>
                                            {logo && (
                                                <img style={{ maxHeight: height / 8, borderRadius: 5 }} src={logo} alt="Logo" />
                                            )}
                                        </div>

                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{textAlign: 'center'}}>
                                                <p style={{ color: blue, fontSize: (fontsize + 10) }}>Répartition des dépenses d'investissement</p>
                                                <p style={{ color: 'grey', fontSize: (fontsize + 5) }}>Hypothèse 3</p>
                                            </div>
                                        </div>

                                        <div style={{ flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25 }}>
                                            <p style={{ color: 'grey', fontSize: (fontsize + 4) }}>Synthèse globale des projets</p>
                                        </div>
                                    </div>

                                    {/* Container */}
                                    <div style={{ height: '60%', flex: 1 }} ref={(el) => (pageRefsGraphs.h3 = el)}>
                                        <Page7Graphs
                                            nom={"Répartition des dépenses d'investissement"}
                                            hypothese={'h3'}
                                            pagination={((Object.values(projets).length * 3) + 3 + (affH1?2:0) + (affH2?2:0) + (affH3?1:0))}
                                            countPageTotal={countPages}
                                            annees={annees}
                                            user={props.user}
                                            date={dateFR}
                                            logo={logo}
                                            width={width}
                                            height={height}
                                        />
                                    </div>
                                    
                                    {/* Footer */}
                                    <div style={{ display: 'flex', height: '20%', position: 'relative' }}>
                                        <div style={{ position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200 }}>
                                            <p style={{ fontSize: (fontsize - 2) }}>{dateFR}</p>
                                        </div>
                                        <div style={{ position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <p style={{ fontSize: fontsize }}>page : {((Object.values(projets).length * 3) + 3 + (affH1?2:0) + (affH2?2:0) + (affH3?1:0))} / {countPages}</p>
                                        </div>
                                        <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                                            <img style={{width: sizelogo}} src={Logo} />
                                        </div>
                                    </div>
                        
                                </div>
                            </div>
                            )}

                        </div>
                        <div style={{flex: 1}}></div>
                    </div>
                )}
                    
            </div>
        </Access>
    </div>
    );
}

export default SyntheseProjets;