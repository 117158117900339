import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { rescueHtml } from '../components/Tools';


const Page32ComFP = (props) =>
{
    const blue = '#069BD3'

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={Logo} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>Hypothèse n°{props.hypothese.num} {props.hypothese?rescueHtml(props.hypothese.nom):''}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginTop: 25, height: '80%', textAlign: 'left'}}>
                <div className='shadow-md p-2 rounded-md' style={{marginLeft: 25, marginRight: 25}}>
                    <p style={{fontSize: 14}}>{props.hypothese?rescueHtml(props.hypothese.commentaire):''}</p>
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
}
  
  export default Page32ComFP;