import React, { useEffect, useState } from 'react';
import { ReactComponent as IconSingleNeutral } from './Img/icon-single-neutral.svg';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import { rescueHtml, escapeHtml } from './components/Tools';
import axios from 'axios';
import qs from 'qs';


const Moncompte = (props) =>
{
    const [aff, setAff] = useState(false)
    const [user, setUser] = useState({})
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    
    const green = '#59B44A'
    
    useEffect(() => {
        getUser()
        setAff(!aff)
    }, [])

    function getUser()
    {
        const currentUser = localStorage.getItem("session_token")

        if (currentUser)
        {
            const url = 'https://app-slfinance.fr/api/connexion.php';
			const params = {
				'session_token': currentUser
			};
			axios.post(url, qs.stringify(params))
            .then((res) => {
				const tmp = {
                    'id' : res.data.id,
					'email' : rescueHtml(res.data.email),
					'nom' : rescueHtml(res.data.nom.toUpperCase()),
					'prenom' : rescueHtml(res.data.prenom),
                    'id_type_users': res.data.id_type_users
				}
                setUser(tmp)
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }


    function updateUser()
    {
        var params = {}
        // Update Nom
        if (nom && nom !== '') {
            params = {
                'updateUser': true,
                'nom': escapeHtml(nom),
                'id': user.id
            }
            axios.post('https://app-slfinance.fr/api/users.php', qs.stringify(params))
            .then((e) => {
                if (e.data['status'] === 1) {
                    NotificationManager.success(e.data['status_message'], '', 700);
                    setTimeout(() => window.location = window.location.href, 900);
                }
                else {
                    NotificationManager.success(e.data['status_message'], '', 3000);
                }
            })
        }

        // Update Prenom
        if (prenom && prenom !== '') {
            params = {
                'updateUser': true,
                'prenom': escapeHtml(prenom),
                'id': user.id
            }
            axios.post('https://app-slfinance.fr/api/users.php', qs.stringify(params))
            .then((e) => {
                if (e.data['status'] === 1) {
                    NotificationManager.success(e.data['status_message'], '', 700);
                    setTimeout(() => window.location = window.location.href, 900);
                }
                else {
                    NotificationManager.warning(e.data['status_message'], '', 3000);
                }
            })
        }
    }

	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                <NotificationContainer />
                <Navbar user={props.user} />
                <div style={{flex: 1, display: 'flex', padding: 25}}>

                    <div style={{flex: 1}}></div>

                    {/* -------------- Gestion Nom - Prenom -------------- */}
                    <div style={{display: 'flex', margin: 25}}>
                        <div style={{flex: 1}}></div>
                        <div className='shadow-md' style={{borderRadius: 5, padding: 25}}>

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IconSingleNeutral width="60px" />
                            </div>
                            
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}></div>
                                <div>
                                    <div style={{marginTop: 10, padding: 5, height: 30}}>
                                        <p>Nom</p>
                                    </div>
                                    <div style={{marginTop: 10, padding: 5, height: 30}}>
                                        <p>Prénom</p>
                                    </div>
                                </div>

                                <div style={{marginLeft: 20}}>
                                    <div style={{marginTop: 10, height: 30}}>
                                        <input
                                        style={{borderRadius: 5, padding: 5, width: 200}}
                                        onChange={async (e) => {
                                            setNom(e.target.value)
                                        }}
                                        placeholder={user.nom}
                                        />
                                    </div>
                                    <div style={{marginTop: 10, height: 30}}>
                                        <input
                                        style={{borderRadius: 5, padding: 5, width: 200}}
                                        onChange={async (e) => {
                                            setPrenom(e.target.value)
                                        }}
                                        placeholder={user.prenom}
                                        />
                                    </div>
                                </div>
                                <div style={{flex: 1}}></div>
                            </div>

                            <div style={{marginTop: 25, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <button
                                className='shadow-md p-2 rounded-md'
                                style={{backgroundColor: green, color: 'white', fontSize: 14}}
                                onClick={() => {
                                    updateUser();
                                }}>
                                    Valider
                                </button>
                            </div>
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>

                    <div style={{flex: 1}}></div>

                </div>
            </Access>
        </div>
	);
}

export default Moncompte;
