import React, { useEffect, useState } from 'react';
import { rescueHtml } from '../components/Tools.js';
import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import axios from 'axios';
import qs from 'qs';

const Page6Gantt = (props) => {
    const greyChapter = '#DBDBDB';

    const [data, setData] = useState([]);
    const [aff, setAff] = useState(false);
    const [sizecolone, setSizecolone] = useState(0);

    const fontsize = (Math.round(props.height * 12 / 890 * 100)/100) || 12;

    async function mount() {
        let tmp = [];
        let key = 0;
        let minStartDate = null;
        let maxEndDate = null;

        for (const element of props.projets) {
            try {
                const result = await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
                    'getStartEnd': true,
                    'id_projet': element[0]
                }));

                if (result.data.start && result.data.end && new Date(result.data.end) >= new Date()) {
                    const startDate = new Date(result.data.start);
                    const endDate = result.data.end ? new Date(result.data.end) : new Date();

                    if (!minStartDate || startDate < minStartDate) {
                        minStartDate = startDate;
                    }
                    if (!maxEndDate || endDate > maxEndDate) {
                        maxEndDate = endDate;
                    }

                    tmp.push({
                        id: key,
                        numero_projet: element[2],
                        start: startDate,
                        end: endDate,
                        name: !result.data.start || !result.data.end ? rescueHtml(element[3]) + ' (GANTT non renseigné)' : rescueHtml(element[3]),
                        type: 'task',
                        styles: {
                            backgroundColor: element[27] && element[27] !== '#ffffff' ? element[27] : '#bdc3c7',
                            backgroundSelectedColor: element[27] && element[27] !== '#ffffff' ? element[27] : '#bdc3c7',
                            progressColor: element[27] && element[27] !== '#ffffff' ? element[27] : '#bdc3c7',
                            progressSelectedColor: element[27] && element[27] !== '#ffffff' ? element[27] : '#bdc3c7'
                        },
                        h1: element[24],
                        h2: element[25],
                        h3: element[26],
                    });
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
            key++;
        }

        if (minStartDate && maxEndDate) {
            const durationInDays = Math.ceil((maxEndDate - minStartDate) / (1000 * 60 * 60 * 24));
            let newSizecolone = props.width / 7;
            const stepReduction = 20; // Réduction par étape
            const minSizecolone = 50; // Taille minimale des colonnes
            const thresholds = [365, 730, 1095, 1460, 1825, 2190, 2555]; // Seuils initiaux (7 ans au maximum)

            // Ajouter dynamiquement des seuils pour des durées très grandes
            let currentThreshold = 2555;
            while (currentThreshold < durationInDays) {
                currentThreshold += 365; // Ajouter des seuils par incréments d'un an
                thresholds.push(currentThreshold);
            }

            // Réduire la taille des colonnes en fonction des seuils
            thresholds.forEach(threshold => {
                if (newSizecolone > minSizecolone && durationInDays >= threshold) {
                    newSizecolone -= stepReduction;
                }
            });

            // S'assurer que la taille des colonnes ne descend pas en dessous de la taille minimale
            newSizecolone = Math.max(newSizecolone, minSizecolone);

            setSizecolone(newSizecolone);
        }

        setData(tmp);
        setAff(!aff);
    }

    useEffect(() => {
        if (props.user.id_budget) {
            mount();
        }
    }, [props.user]);

    return (
        data && data.length !== 0 && props.user ?
            <Gantt
                todayColor={'#c8eae9'}
                handleWidth={5}
                rowHeight={35}
                tasks={data}
                locale={'fr'}
                preStepsCount={0}
                columnWidth={sizecolone}
                TaskListHeader={
                    () => (
                        <div style={{ display: 'flex', fontSize: (fontsize + 2), backgroundColor: '#f5f5f5', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: 50, borderLeft: '1px solid ' + greyChapter, borderTop: '1px solid ' + greyChapter }}>
                            <p style={{ flex: 0.1, paddingLeft: 5, paddingRight: 5 }}>N°</p>
                            <p style={{ flex: 1.5 }}>Projets</p>
                            <p style={{ flex: 0.15 }}>H1</p>
                            <p style={{ flex: 0.15 }}>H2</p>
                            <p style={{ flex: 0.15 }}>H3</p>
                        </div>
                    )
                }
                TaskListTable={
                    (e) => (
                        e.tasks.map((task) =>
                            <div key={task.id} style={{ display: 'flex', fontSize: (fontsize + 2), backgroundColor: '#f5f5f5', alignItems: 'center', justifyContent: 'center', textAlign: 'center', minHeight: 35, borderLeft: '1px solid ' + greyChapter, borderTop: '1px solid ' + greyChapter }}>
                                <p style={{ flex: 0.1, paddingLeft: 5, paddingRight: 5 }}>{task.numero_projet}</p>
                                <p style={{ flex: 1.5, width: (sizecolone + 160), textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{task.name}</p>
                                <p style={{ flex: 0.15 }}>
                                    <input
                                        type="checkbox"
                                        checked={task.h1 === "0" ? false : true}
                                        onChange={() => {}}
                                        style={{ transform: "scale(0.9)" }}
                                    />
                                </p>
                                <p style={{ flex: 0.15 }}>
                                    <input
                                        type="checkbox"
                                        checked={task.h2 === "0" ? false : true}
                                        onChange={() => {}}
                                        style={{ transform: "scale(0.9)" }}
                                    />
                                </p>
                                <p style={{ flex: 0.15 }}>
                                    <input
                                        type="checkbox"
                                        checked={task.h3 === "0" ? false : true}
                                        onChange={() => {}}
                                        style={{ transform: "scale(0.9)" }}
                                    />
                                </p>
                            </div>
                        )
                    )
                }
                viewMode={'Year'}
            />
        :
            <div style={{ marginTop: 50, textAlign: 'center' }}>
                <p>Aucune fiche projet n'a de GANTT renseigné.</p>
            </div>
        
    );
}

export default Page6Gantt;