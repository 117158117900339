import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { rescueHtml, lorem } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';


const Page7Pilotage = (props) =>
{
    const blue = '#069BD3';
    const green = '#59B44A';
    const greyChapter = '#DBDBDB';
    const height = (props.height*100/950);
    const fontsize = (props.height*12/890);
    const sizelogo = props.width/6;

    const [list_pilotage, setList_pilotage] = useState([]);

    async function mount()
    {
        await axios.post('https://app-slfinance.fr/api/pilotage_projet.php', qs.stringify({
            'get': true,
            'id_projet': props.projet[0]
        }))
        .then((res) => {
            var sortedData = res.data.sort((a, b) => {
                const dateA = new Date(a[2]); // Convertit la date en objet Date
                const dateB = new Date(b[2]); // Convertit la date en objet Date
                return dateB - dateA;         // Tri décroissant
            });
            
            // Prendre les 5 premiers éléments
            var tmp = sortedData.slice(0, 5);

            tmp = tmp.sort((a, b) => {
                const dateA = new Date(a[2]); // Convertit la date en objet Date
                const dateB = new Date(b[2]); // Convertit la date en objet Date
                return dateA - dateB;         // Tri croissant
            });
            
            setList_pilotage(tmp);
            
        })
    }

    useEffect(() => {
        mount();
    }, [props.user])

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{ display: 'flex', height: '20%' }}>
                <div style={{ flex: 0.4, display: 'flex', marginLeft: 10 }}>
                    {props.logo && (
                        <img style={{ maxHeight: props.height / 8, borderRadius: 5 }} src={props.logo} alt="Logo" />
                    )}
                </div>

                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: blue, fontSize: (fontsize + 10) }}>{rescueHtml(props.projet[3]?props.projet[3]:'')}</p>
                </div>

                <div style={{ flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25 }}>
                    <p style={{ color: 'grey', fontSize: (fontsize + 4) }}>{props.nom}</p>
                </div>
            </div>

            {/* Container */}
            <div style={{textAlign: 'center', fontSize: fontsize}}>
                <p>Liste des 5 dernières lignes pilotage</p>
            </div>
            <div style={{height: '60%', flex: 1, marginTop: 20, fontSize: fontsize}}>
                <div style={{flex: 1, display: 'flex', borderTopLeftRadius: 2}}>

                    <div style={{flex: 0.5, width: (props.width / 9), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{}}>Date</p>
                    </div>
                    <div style={{flex: 1, width: (props.width / 7), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{}}>Instance réunie</p>
                    </div>
                    <div style={{flex: 1.5, width: (props.width / 5), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{}}>Relevé de décisions</p>
                    </div>
                    <div style={{flex: 1.5, width: (props.width / 5), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{}}>Action suivante</p>
                    </div>
                    <div style={{flex: 0.5, width: (props.width / 9), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{}}>Date limite</p>
                    </div>
                    <div style={{flex: 1, width: (props.width / 7), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{}}>Responsable</p>
                    </div>

                </div>

                {list_pilotage.map((pilotage, i) =>
                <div key={i} style={{flex: 1, display: 'flex', margin: 5, border: '1px solid '+greyChapter, borderRadius: 5}}>

                    <div style={{flex: 0.5, width: (props.width / 9), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{}}>{pilotage[2]?pilotage[2].split('-')[2]+'/'+pilotage[2].split('-')[1]+'/'+pilotage[2].split('-')[0]:null}</p>
                    </div>

                    <div style={{borderLeft: '1px solid '+greyChapter, flex: 1, width: (props.width / 7), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{height: height, overflow: 'hidden'}}>{rescueHtml(pilotage[3])}</p>
                    </div>

                    <div style={{borderLeft: '1px solid '+greyChapter, flex: 1.5, width: (props.width / 5), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{height: height, overflow: 'hidden'}}>{rescueHtml(pilotage[4])}</p>
                    </div>

                    <div style={{borderLeft: '1px solid '+greyChapter, flex: 1.5, width: (props.width / 5), justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <p style={{height: height, overflow: 'hidden'}}>{rescueHtml(pilotage[5])}</p>
                    </div>

                    <div style={{
                        borderLeft: '1px solid '+greyChapter,
                        flex: 0.5,
                        width: (props.width / 9),
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <p style={
                            // On regarde si la date est passée pour mettre un encadré en rouge ou vert ou aucun encadré
                            pilotage[9] === '1'?
                            {color: green}
                            :
                            pilotage[9] === '0' && new Date(pilotage[6]) <= new Date()?
                                {color: 'red'}
                                :
                                {}
                        }>{pilotage[6]?pilotage[6].split('-')[2]+'/'+pilotage[6].split('-')[1]+'/'+pilotage[6].split('-')[0]:null}</p>
                    </div>

                    <div style={{
                        borderLeft: '1px solid '+greyChapter,
                        flex: 1,
                        width: (props.width / 7),
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <p style={{height: height, overflow: 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center',}}>{rescueHtml(pilotage[7])}</p>
                    </div>

                </div>    
                )}

            </div>
            
            
            {/* Footer */}
            <div style={{ display: 'flex', height: '20%', position: 'relative' }}>
                <div style={{ position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200 }}>
                    <p style={{ fontSize: (fontsize - 2) }}>{props.date}</p>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: fontsize }}>page : {props.pagination} / {props.countPageTotal}</p>
                </div>
                <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                    <img style={{width: sizelogo}} src={Logo} />
                </div>
            </div>

        </div>
    );
}
  
  export default Page7Pilotage;