import React, { useEffect, useState } from 'react';
import { addSpace } from '../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function HistogramMontantPpr(props)
{
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({});

    async function mount()
    {
        /* Initialisation des options avec les années */
        var option_tmp = {
            chart: {
                toolbar: {
                    show: false,
                },
                type: 'bar',
                height: props.height/2,
                stacked: true,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            title: {
                text: 'Dépenses d\'invest. par politique pub. par année',
                style: {
                    fontSize: (props.fontsize + 4)
                }
            },
            xaxis: {
				categories: props.annees,
                labels: {
                    formatter: function (value) {
                        return addSpace(Math.round(value*100)/100, true) + " €";
                    },
                    style: {
                        fontSize: (props.fontsize)
                    }
                },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return addSpace(val, true) + " €"
                },
                style: {
                    fontSize: (props.fontsize)
                }
              },
            },
            dataLabels: {
                formatter: (val) => {
                    return addSpace(val, true) + " €";
                },
                style: {
                    fontSize: (props.fontsize)
                }
            },
            fill: {
              opacity: 1
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left',
              fontSize: (props.fontsize),
              markers: {
                  width: props.fontsize,
                  height: props.fontsize
              },
            }
        }
        setOptions(option_tmp);
        
        // récupérer les données du graph bar des dépenses d'investissement par priorité
        const params = {
            'montants_par_ppr_par_annee': true,
            'id_budget': props.user.id_budget,
            'hypothese': props.hypothese,
            'annees': props.annees
        };
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
        .then((res) => {
            setSeries(res.data);
        })
        .catch(err => console.log(err));
    }

    useEffect(() => {
        mount();
    }, [props.user, props.hypothese, props.annees])

    return (
        <div style={{margin: 5}}>
            {series.length > 0 && (
            <ApexCharts
                series={series}
                options={options}
                height={props.height/2}
                type="bar"
            />
            )}
        </div>
    )
}

export default HistogramMontantPpr;
