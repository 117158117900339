import React, { useState, useEffect, useRef } from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { toPng } from 'html-to-image';  // Importer html-to-image
import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import axios from 'axios';
import qs from 'qs';
import Logo from '../Img/Logo_projets&finances.png';
import { rescueHtml } from "../components/Tools";

const Page4_PDF_Echeancier = (props) => {
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';
    const green = '#59B44A';

    return (
        <Page size="A4" style={{ flexDirection: 'column', backgroundColor: 'white', padding: 20, fontSize: 14 }} orientation="landscape">
            
            {/* Header */}
            <View style={{flexDirection: 'row'}}>
                <View style={{flex: 0.5, flexDirection: 'row'}}>
                    {props.logo && <Image style={{maxHeight: 75, borderRadius: 5}} src={props.logo} alt="Logo" />}
                </View>
                <View style={{flex: 1,justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{fontSize: 16, color: blue}}>{rescueHtml(props.projet[3]?props.projet[3]:'')}</Text>
                </View>
                <View style={{flex: 0.5, flexDirection: 'row', fontSize: 14}}>
                    <View style={{flex: 1}}></View>
                    <Text style={{color: 'grey'}}>{props.nom?props.nom:''}</Text>
                </View>
            </View>

            {/* Image du Gantt */}
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                {props.img ? (
                    <Image src={props.img} style={{ width: '100%', height: 'auto' }} />
                ) : (
                    <Text style={{ color: 'red', textAlign: 'center' }}>Diagramme manquant</Text>
                )}
            </View>

            {/* Footer */}
            <View style={{ position: 'absolute', bottom: 20, left: 20 }}>
                <Text style={{ fontSize: 10 }}>{props.date}</Text>
            </View>
            <View style={{ position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-25%)' }}>
                <Text style={{ fontSize: 10 }}>page : {props.pagination} / {props.countPageTotal}</Text>
            </View>
            <View style={{ position: 'absolute', bottom: 20, right: 20 }}>
                <Image style={{ width: 150, borderRadius: 5 }} src={Logo} />
            </View>
        </Page>
    );
};

export default Page4_PDF_Echeancier;
