import React, { useEffect, useState } from 'react';
import "gantt-task-react/dist/index.css";
import HistogramMontantPpr from '../Graph/HistogramMontantPpr.js';
import HistogramMontantPriorite from '../Graph/HistogramMontantPriorite.js';
import axios from 'axios';
import qs from 'qs';

const Page7Graphs = (props) => {
    const fontsize = (props.height * 12 / 890);
    const [datas, setDatas] = useState([]);

    async function mount()
    {
        // récupérer les données du graph bar des dépenses d'investissement par priorité
        const params = {
            'montants_par_ppr_par_annee': true,
            'id_budget': props.user.id_budget,
            'hypothese': props.hypothese,
            'annees': props.annees
        };
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
        .then((res) => {
            setDatas(res.data);
        })
        .catch(err => console.log(err));
    }

    useEffect(() => {
        mount();
    }, [props]);

    return (
        <div style={{flex: 1, height: '60%'}}>
            {datas.length > 0 ?
            <div style={{flex: 1, display: 'flex'}}>
                <div style={{flex: 1.5}}>
                    <HistogramMontantPpr user={props.user} hypothese={props.hypothese} height={props.height} annees={props.annees} fontsize={fontsize} />
                </div>
                <div style={{flex: 1}}>
                    <HistogramMontantPriorite user={props.user} hypothese={props.hypothese} height={props.height} fontsize={fontsize} />
                </div>
            </div>
            :
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: props.height/2}}>
                <p style={{fontSize: fontsize + 4}}>Aucune politique publique trouvée</p>
            </div>
            }
        </div>
    );
}

export default Page7Graphs;