import React from 'react';
import { addSpace, rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';


const Page3Description = (props) =>
{
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';
    const greyclair = '#FAFAFA';
    const height = (props.height*20/950);
    const fontsize = (props.height*12/890);
    const sizelogo = props.width/6;

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            
            {/* Header */}
            <div style={{ display: 'flex', height: '20%' }}>
                <div style={{ flex: 0.4, display: 'flex', marginLeft: 10 }}>
                    {props.logo && (
                        <img style={{ maxHeight: props.height / 8, borderRadius: 5 }} src={props.logo} alt="Logo" />
                    )}
                </div>

                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: blue, fontSize: (fontsize + 10) }}>{rescueHtml(props.projet[3]?props.projet[3]:'')}</p>
                </div>

                <div style={{ flex: 0.4, display: 'flex', justifyContent: 'right', marginRight: 25 }}>
                    <p style={{ color: 'grey', fontSize: (fontsize + 4) }}>{props.nom}</p>
                </div>
            </div>


            {/* Container */}
            <div style={{height: '60%', flex: 1, fontSize: fontsize}}>
                <div style={{display: 'flex', justifyContent: 'right', alignItems: 'right', fontSize: (fontsize+6)}}>
                    <div style={{flex: 1}}></div>
                    <p style={{marginRight: 15}}>Niveau de priorité : {props.projet[6]}</p>
                </div>
    
                {/* Descriptif des enjeux */}
                <div style={{display: 'flex', marginBottom: fontsize-4, marginTop: 3}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Descriptif des enjeux</p>
                    </div>
                    <div style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{height: (height+20), overflow: 'hidden'}}>{props.projet[4] ? rescueHtml(props.projet[4]) : ""}</p>
                    </div>
                </div>
    
                {/* Politique publique de rattachement */}
                <div style={{display: 'flex', marginBottom: fontsize-4}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Politique publique de rattachement</p>
                    </div>
                    <div style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{height: height, overflow: 'hidden'}}>{props.projet[7] ? rescueHtml(props.projet[7]).replace('|', '') : ""}</p>
                    </div>
                </div>
    
                {/* Référent élu */}
                <div style={{display: 'flex', marginBottom: fontsize-4}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Référent élu</p>
                    </div>
                    <div style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{height: height, overflow: 'hidden'}}>{props.projet[8] ? rescueHtml(props.projet[8]) : ""}</p>
                    </div>
                </div>
    
                {/* Montant du projet */}
                <div style={{display: 'flex', marginBottom: fontsize-4}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Montant du projet (en € TTC)</p>
                    </div>
                    <div style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{height: height, overflow: 'hidden'}}>{props.projet[9] ? addSpace(parseFloat(props.projet[9])) + "€" : "0€"}</p>
                    </div>
                </div>
    
                {/* Maître d'ouvrage */}
                <div style={{display: 'flex', marginBottom: fontsize-4}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Maître d'ouvrage</p>
                    </div>
                    <div style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{height: height, overflow: 'hidden'}}>{props.projet[12] ? rescueHtml(props.projet[12]) : ""}</p>
                    </div>
                </div>
    
                {/* Partenaires à mobiliser */}
                <div style={{display: 'flex', marginBottom: fontsize-4}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Partenaires à mobiliser</p>
                    </div>
                    <div style={{flex: 1, padding: 5, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{height: height, overflow: 'hidden'}}>{props.projet[13] ? rescueHtml(props.projet[13]) : ""}</p>
                    </div>
                </div>

                {/* Description de l'action */}
                <div style={{display: 'flex', height: (height + 60), marginBottom: fontsize-4}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Description de l'action</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{flex: 1, padding: 5, height: (height + 60), maxHeight: (height + 60), overflow: 'hidden'}}>
                            {props.projet[10]?rescueHtml(props.projet[10]):''}
                        </p>
                    </div>
                </div>
                {/****/}
                
                {/* Questions/ Remarques */}
                <div style={{display: 'flex', height: (height + 60), marginBottom: fontsize-4}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Questions / Remarques</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{flex: 1, padding: 5, height: (height + 60), maxHeight: (height + 60), overflow: 'hidden'}}>
                            {props.projet[11]?rescueHtml(props.projet[11]):''}
                        </p>
                    </div>
                </div>
                {/****/}

                {/* Service référent */}
                <div style={{display: 'flex', marginTop: 30, marginBottom: fontsize-4}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Service référent</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{flex: 1, padding: 5, overflow: 'hidden'}}>
                            {props.projet[14]?rescueHtml(props.projet[14]):''}
                        </p>
                    </div>
                </div>
                {/****/}

                {/* Technicien référent */}
                <div style={{display: 'flex', marginBottom: fontsize-4}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Technicien référent</p>
                    </div>
                    <div style={{flex: 1, display: 'flex', backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        
                        <div style={{display: 'flex', flex: 0.6}}>
                            <div>
                                <p style={{padding: 5, overflow: 'hidden'}}>
                                    {props.projet[15]?rescueHtml(props.projet[15]):''}
                                </p>
                            </div>
                        </div>

                        <div style={{display: 'flex', flex: 1}}>
                            <div>
                                <p style={{padding: 5, overflow: 'hidden'}}>
                                    {props.projet[18]?rescueHtml(props.projet[18]):''}
                                </p>
                            </div>
                        </div>

                        <div style={{display: 'flex', flex: 0.4}}>
                            <div>
                                <p style={{padding: 5, overflow: 'hidden'}}>
                                    {props.projet[17]?rescueHtml(props.projet[17]):''}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/****/}

                {/* Fonction du technicien référent */}
                <div style={{display: 'flex'}}>
                    <div style={{flex: 0.4, padding: 5, backgroundColor: greyChapter, width: 175, borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}>
                        <p>Fonction du technicien référent</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                        <p style={{flex: 1, padding: 5, overflow: 'hidden'}}>
                            {props.projet[16]?rescueHtml(props.projet[16]):''}
                        </p>
                    </div>
                </div>
                {/****/}
            </div>
            

            {/* Footer */}
            <div style={{ display: 'flex', height: '20%', position: 'relative' }}>
                <div style={{ position: 'absolute', bottom: 0, display: 'flex', marginLeft: 25, alignItems: 'center', width: 200 }}>
                    <p style={{ fontSize: (fontsize - 2) }}>{props.date}</p>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: '47%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: fontsize }}>page : {props.pagination} / {props.countPageTotal}</p>
                </div>
                <div style={{position: 'absolute', bottom: 0, right: 0, display: 'flex', marginRight: 25, alignItems: 'center'}}>
                    <img style={{width: sizelogo}} src={Logo} />
                </div>
            </div>
            
        </div>
    );
}
  
  export default Page3Description;