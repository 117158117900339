import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import BilanProjets from "./BilanProjets/BilanProjets";
import { addSpace, rescueHtml } from "./components/Tools";
import { ReactComponent as IconPdfWhite } from './Img/icon-pdf-white.svg';


/*
    La variable ListeProjets contient toutes les infos du projet et est structurée ainsi:
    projet[0]  = id
    projet[1]  = id_budget
    projet[2]  = numero_projet
    projet[3]  = nom
    projet[4]  = enjeu
    projet[5]  = priorite
    projet[6]  = priorite_niveau
    projet[7]  = pol_pub_rat (politique publique de rattachement)
    projet[8]  = referent_elu
    projet[9]  = montant
    projet[10] = description
    projet[11] = remarques
    projet[12] = maitre_ouvrage
    projet[13] = partenaire_mobiliser
    projet[14] = service_referent
    projet[15] = technicien_ref_nom
    projet[16] = technicien_ref_fonction
    projet[17] = technicien_ref_telephone
    projet[18] = technicien_ref_mail
    projet[19] = ressources_humaines
    projet[20] = etudes
    projet[21] = outils
    projet[22] = investissements
    projet[23] = incidences_organisationnelle_administrative
    projet[24] = h1
    projet[25] = h2
    projet[26] = h3
    projet[27] = color
    projet[28] = checked
*/


const FichesProjetsListing = (props) =>
{
    const [listeProjets, setListeProjets] = useState([]);
    const [aff, setAff] = useState(false);
    const [numero_projet, setNumero_projet] = useState(false);
    const [intitule, setIntitule] = useState(false);
    const [pol_pub_rat, setPol_pub_rat] = useState(false);
    const [montant, setMontant] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    
    const purple = '#6571FE';
    const blue = '#069BD3';
    const green = '#59B44A';

    async function mount() {
        const params = {
            'getFichesProjets': true,
            'id_budget': props.user.id_budget
        }
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify(params))
        .then((result) => {
            setListeProjets(result.data);
        })
        .catch((err) => {
            console.log(err)
        })
    }

    
    // Récuperation de la liste des fiches projets
    useEffect(() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            mount();
        }
    }, [props.user])


    function sortBy(arr, criter) {
        // Index correspondant au critère
        const criterIndex = {
            id: 0,
            id_budget: 1,
            numero_projet: 2,
            intitule: 3,
            enjeu: 4,
            priorite: 5,
            priorite_niveau: 6,
            pol_pub_rat: 7,
            referent_elu: 8,
            montant: 9,
            description: 10,
            remarques: 11,
            maitre_ouvrage: 12,
            partenaire_mobiliser: 13,
            service_referent: 14,
            technicien_ref_nom: 15,
            technicien_ref_fonction: 16,
            technicien_ref_telephone: 17,
            technicien_ref_mail: 18,
            ressources_humaines: 19,
            etudes: 20,
            outils: 21,
            investissements: 22,
            incidences_organisationnelle_administrative: 23,
            h1: 24,
            h2: 25,
            h3: 26
        };
      
        // Utilisation de la méthode sort()
        return arr.sort((a, b) => {
            const valueA = a[criterIndex[criter]];
            const valueB = b[criterIndex[criter]];
    
            // Vérifie si les valeurs sont définies et non nulles
            if (valueA === undefined || valueB === undefined) {
                return 0; // Pas de tri si une des valeurs est undefined
            }
    
            // Convertir en nombre si on trie par id ou montant
            if (criter === 'id' || criter === 'id_budget' || criter === 'numero_projet' || criter === 'priorite_niveau' || criter === 'montant') {
                if (criter === 'numero_projet' && numero_projet) {
                    setNumero_projet(!numero_projet);
                    return parseInt(valueA) - parseInt(valueB);
                }
                else if (criter === 'numero_projet' && !numero_projet) {
                    setNumero_projet(!numero_projet);
                    return parseInt(valueB) - parseInt(valueA);
                }
                if (criter === 'montant' && montant) {
                    setMontant(!montant);
                    return parseFloat(valueA) - parseFloat(valueB);
                }
                else if (criter === 'montant' && !montant) {
                    setMontant(!montant);
                    return parseFloat(valueB) - parseFloat(valueA);
                }
            } else {
                if ((criter === 'intitule' && intitule) || (criter === 'pol_pub_rat' && pol_pub_rat)) {
                    if (criter === 'pol_pub_rat') {
                        setPol_pub_rat(!pol_pub_rat);
                    }
                    if (criter === 'intitule') {
                        setIntitule(!intitule);
                    }
                    return valueA.localeCompare(valueB);
                }
                else if ((criter === 'intitule' && !intitule) || (criter === 'pol_pub_rat' && !pol_pub_rat)) {
                    if (criter === 'pol_pub_rat') {
                        setPol_pub_rat(!pol_pub_rat);
                    }
                    if (criter === 'intitule') {
                        setIntitule(!intitule);
                    }
                    return valueB.localeCompare(valueA);
                }
            }
        });
    }

    
    async function allOrNoneChecked(list) {
        if (list.length === 0) {
            return 'liste vide';
        }
        
        // Vérifie l'état du premier élément.
        const firstState = list[0][28];
        
        for (let i = 1; i < list.length; i++) {
            // Si un élément diffère du premier état, ce n'est pas uniforme.
            if (list[i][28] !== firstState) {
                return 'pas uniforme';
            }
        }
        return list[0][28];
    }
    

    
    async function checkifitsok(list)
    {
        var ret = false
        for (let i = 0; i < list.length; i++) {
            if (list[i][28] === true) {
                ret = true;
            }
        }
        await setButtonVisible(ret);

        await allOrNoneChecked(list)
        .then((response) => {
            if (response === true || response === false) {
                setIsChecked(response)
            }
            if (response === 'pas uniforme' && isChecked === true) {
                setIsChecked(false);
            }
        })
        await setAff(!aff);
    }


    async function setCheck(list, id)
    {
        for (let i = 0; i < list.length; i++) {
            if (list[i][0] == id) {
                list[i][28] = !list[i][28];
                await setListeProjets(list);
            }
        }
        await checkifitsok(list);
    }


	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                <Navbar user={props.user} />

                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <div style={{flex: 1, display: 'flex'}}>
                            <p>Pilotage projets</p>
                            <p style={{marginLeft: 5}}>\</p>
                            <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Édition des projets</p>
                        </div>
                        {selectedList.length == 0?
                        <div style={{flex: 1}}>
                            {props.user?
                            <p style={{flex: 1, textAlign: 'center'}}>{props.user.nom_budget}</p>
                            :null}
                        </div>
                        :null}
                        <div style={{flex: 1}}></div>
                    </div>
                </div>

                {selectedList.length == 0?
                    buttonVisible?
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: purple, color: 'white', fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                            onClick={async () => {
                                var list_tmp = [];
                                for (let i = 0; i < listeProjets.length; i++) {
                                    if (listeProjets[i][28] === true) {
                                        await list_tmp.push(listeProjets[i]);
                                    }
                                }
                                await setSelectedList(list_tmp);
                                await setAff(!aff);
                            }}
                        >
                            <IconPdfWhite width="18"/>
                            <p style={{marginLeft: 10}}>Valider la liste</p>
                        </button>
                        <div style={{flex: 1}}></div>
                    </div>
                    :
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: 'white', color: purple, border: '1px solid '+purple, borderRadius: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                        >
                            <IconPdfWhite width="18"/>
                            <p style={{marginRight: 20}}>Sélectionnez les projets que vous souhaitez insérer dans le PDF</p>
                        </button>
                        <div style={{flex: 1}}></div>
                    </div>
                :null}

                {selectedList.length == 0?
                <div style={{flex: 1, textAlign: 'center'}}>

                    <div style={{flex: 1, display: 'flex'}}>

                        <div style={{flex: 1, margin: 15}}>
                            <div style={{display: 'flex', marginTop: 15, marginRight: 15}}>
                                <div style={{flex: 1}}></div>
                                <p style={{flex: 0.1}}>Hypothèses</p>
                            </div>

                            <div style={{display: 'flex', margin: 10}}>
                                
                                <input
                                    style={{marginLeft: 15}}
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={async() => {
                                        await setIsChecked(!isChecked);
                                        for (let i = 0; i < listeProjets.length; i++) {
                                            listeProjets[i][28] = !isChecked?true:false;
                                        }
                                        await setListeProjets(listeProjets);
                                        await checkifitsok(listeProjets);
                                        await setAff(!aff);
                                    }}
                                />
                                
                                <button
                                style={{flex: 0.1, minWidth: 100}}
                                onClick={async () => {
                                    await setListeProjets(sortBy(listeProjets, 'numero_projet'));
                                    await setAff(!aff);
                                }}
                                >
                                    <p>Numéro</p>
                                </button>
                                
                                <button style={{flex: 0.1, minWidth: 100}}>
                                    <p>État</p>
                                </button>
                                
                                <button
                                style={{flex: 1}}
                                onClick={async () => {
                                    await setListeProjets(sortBy(listeProjets, 'intitule'));
                                    await setAff(!aff);
                                }}
                                >
                                    <p>Intitulé du projet</p>
                                </button>

                                <button
                                style={{flex: 0.5, textAlign: 'center', marginRight: 50}}
                                onClick={async () => {
                                    await setListeProjets(sortBy(listeProjets, 'pol_pub_rat'));
                                    await setAff(!aff);
                                }}
                                >
                                    <p>Politique publique</p>
                                </button>

                                <button
                                style={{flex: 0.5, textAlign: 'right', marginRight: 50}}
                                onClick={async () => {
                                    await setListeProjets(sortBy(listeProjets, 'montant'));
                                    await setAff(!aff);
                                }}
                                >
                                    <p>Montant du projet</p>
                                </button>
                                <p style={{flex: 0.1}}>H1</p>
                                <p style={{flex: 0.1}}>H2</p>
                                <p style={{flex: 0.1}}>H3</p>
                            </div>

                            {/* Listing des fiches projets */}
                            {listeProjets.map((projet, i) =>
                            <div className='shadow-xl rounded-sm' key={i} style={{display: 'flex', margin: 10, backgroundColor: '#ffffff'}}>
                                <input
                                    style={{marginLeft: 15}}
                                    type="checkbox"
                                    checked={projet[28]}
                                    onChange={async () => {
                                        await setCheck(listeProjets, projet[0]);
                                    }}
                                />

                                <button
                                className='shadow-sm'
                                style={{flex: 0.1, minWidth: 100}}
                                onClick={async () => {
                                    await setCheck(listeProjets, projet[0]);
                                }}
                                >
                                    <p>{rescueHtml(projet[2])}</p>
                                </button>

                                <button
                                className='shadow-sm'
                                style={{display: 'flex', flex: 0.1, minWidth: 100}}
                                onClick={async () => {
                                    await setCheck(listeProjets, projet[0]);
                                }}
                                >
                                    <div style={{flex: 1}}></div>
                                    <div style={{flex: 1}}>
                                        <div style={{margin: 2, justifyContent: 'center', alignItems: 'center', display: 'flex', border: '1px solid '+projet[27]?projet[27]:'#ffffff', borderRadius: 100, backgroundColor: projet[27]?projet[27]:'#ffffff', minWidth: 20, minHeight: 20, maxWidth: 20, maxHeight: 20}}></div>
                                    </div>
                                    <div style={{flex: 1}}></div>
                                </button>

                                <button
                                className='shadow-sm'
                                style={{flex: 1}}
                                onClick={async () => {
                                    await setCheck(listeProjets, projet[0]);
                                }}
                                >
                                    <p>{rescueHtml(projet[3])}</p>
                                </button>

                                <button
                                className='shadow-sm'
                                style={{flex: 0.5, textAlign: 'center', paddingRight: 50}}
                                onClick={async () => {
                                    await setCheck(listeProjets, projet[0]);
                                }}
                                >
                                    <p>{projet[7]?rescueHtml(projet[7].replace('|', ' ').replace('|', ' ').replace('|', ' ').replace('|', ' ')):null}</p>
                                </button>

                                <button
                                className='shadow-sm'
                                style={{flex: 0.5, textAlign: 'right', paddingRight: 50}}
                                onClick={async () => {
                                    await setCheck(listeProjets, projet[0]);
                                }}
                                >
                                    <p>{projet[9]?addSpace(parseFloat(projet[9]))+' €':null}</p>
                                </button>

                                <p className='shadow-sm' style={{flex: 0.1}}>{<input disabled={true} type="checkbox" checked={projet[24] === "0"?false:true} />}</p>
                                <p className='shadow-sm' style={{flex: 0.1}}>{<input disabled={true} type="checkbox" checked={projet[25] === "0"?false:true} />}</p>
                                <p className='shadow-sm' style={{flex: 0.1}}>{<input disabled={true} type="checkbox" checked={projet[26] === "0"?false:true} />}</p>
                            </div>
                            )}
                        </div>

                    </div>
                </div>
                :
                <BilanProjets user={props.user} projets={selectedList}/>
                }

            </Access>
        </div>
	);
}

export default FichesProjetsListing;
