import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';
import React, { useEffect, useState } from 'react';
import { addSpace } from "../components/Tools";
import axios from 'axios';
import qs from 'qs';



const Page6_PDF_IncidencesFinancieres = (props) =>
{
    const blue = '#069BD3'
    const greyChapter = '#DBDBDB'

    const [incidences_financieres, setIncidences_financieres] = useState([]);
    const [annees, setAnnees] = useState([])

    async function mount()
    {
        // Recuperation des annees de prospective
        let prosp_an_deb = ''
        let prosp_an_fin = ''
        await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
        .then((result) => {
            result.data.forEach(element => {
                if (element[2] === 'prosp_an_deb') {
                    prosp_an_deb = parseInt(element[3])
                }
                if (element[2] === 'prosp_an_fin') {
                    prosp_an_fin = parseInt(element[3])
                }
            });
        }).catch((err) => {console.log(err)})
        
        if (prosp_an_deb === '' || prosp_an_fin === '')
        {
            await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
            .then(async function (res) {
                if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                    if (prosp_an_deb === '') {
                        prosp_an_deb = parseInt(res.data.prosp_an_deb)
                    }
                    if (prosp_an_fin === '') {
                        prosp_an_fin = parseInt(res.data.prosp_an_fin)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        var liste_an = []
        var i = 0
        while (prosp_an_deb <= prosp_an_fin)
        {
            liste_an[i] = parseInt(prosp_an_deb)
            prosp_an_deb++;
            i++;
        }
        await setAnnees(liste_an)

        await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
            'get': true,
            'annees': liste_an,
            'id_projet': props.projet[0]
        }))
        .then(async(res) => {
            var total = [];
            await res.data.forEach((element, i) => {
                total[i] = 0;
                element.forEach((elem, j) => {
                    if (j > 1) {
                        if (!parseFloat(elem[1])) {
                            elem[1] = 0;
                        }
                        else {
                            elem[1] = parseFloat(elem[1]);
                        }
                        total[i] += (elem[1]);
                    }
                });
            });
            await res.data.forEach((line, k) => {
                res.data[k].push(['Total', total[k]])
            });
            setIncidences_financieres(res.data)
        })
    }

    useEffect(() => {
        mount();
    }, [props.user])

    return (
            <Page size="A4" style={{flexDirection: 'column', backgroundColor: 'white', padding: 20, fontSize: 14}} orientation="landscape">
                
                {/* Header */}
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 0.5, flexDirection: 'row'}}>
                        {props.logo && <Image style={{maxHeight: 75, borderRadius: 5}} src={props.logo} alt="Logo" />}
                    </View>
                    <View style={{flex: 1,justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{fontSize: 16, color: blue}}>{rescueHtml(props.projet[3]?props.projet[3]:'')}</Text>
                    </View>
                    <View style={{flex: 0.5, flexDirection: 'row', fontSize: 14}}>
                        <View style={{flex: 1}}></View>
                        <Text style={{color: 'grey'}}>{props.nom?props.nom:''}</Text>
                    </View>
                </View>

                {/* Container */}
                <View style={{flex: 1, marginTop: 15}}>
                
                    {/* Tableau des incidences financieres */}
                    <View style={{flexDirection: 'row', backgroundColor: blue, color: 'white', fontSize: 11}}>
                        <View style={{flex: 1, padding: 4}}></View>

                        {annees.map((annee, i) =>
                        <View key={i} style={{flexDirection: 'row', justifyContent: 'center', flex: 0.5, borderLeft: '1px solid white'}}>
                            <Text>{annee}</Text>
                        </View>)}

                        <View style={{flexDirection: 'row', justifyContent: 'center', flex: 0.5, borderLeft: '1px solid white', fontWeight: 'bold', backgroundColor: '#2980b9'}}>
                            <Text>Total</Text>
                        </View>
                    </View>

                    {/* <View style={{flex: 1, backgroundColor: 'white', border: '1px solid '+greyChapter, borderRadius: 2}}> */}
                        {incidences_financieres.length !== 0?
                        incidences_financieres.map((line, i) =>
                        <View key={i}
                        style={i === 0?
                            {flexDirection: 'row'}
                            :
                            {flexDirection: 'row'}
                        }>
                            {line.map((val, j) =>
                                j === 0?
                                    <View
                                    key={j}
                                    style={
                                        line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?
                                            {flex: 1, flexDirection: 'row', padding: 4, backgroundColor: greyChapter}
                                        :
                                            {flex: 1, flexDirection: 'row', padding: 4, borderTop: '1px solid '+greyChapter}
                                    }>
                                        {val && typeof val === 'string'?
                                            <Text
                                            style={
                                                line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?
                                                    {fontWeight: 'bold', fontSize: 9}
                                                :
                                                    {textAlign: 'right', fontSize: 9}
                                            }>
                                                {
                                                val.replace('_', "'")
                                                .replace('Dépenses d\'équipement', "Dépenses d\'équip.")
                                                .replace('Subventions de fonctionnement', "Subv. de fonct.")
                                                .replace('Autres recettes d\'investissement', "Autres recettes d\'invest.")
                                                }
                                            </Text>
                                        :
                                            null
                                        }
                                    </View>
                                :
                                    j > 1 && (
                                    <View
                                        key={j}
                                        style={
                                            val[0] !== 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                                {flex: 0.5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 9, borderLeft: '1px solid white', backgroundColor: greyChapter, fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}
                                            :
                                                val[0] === 'Total'?
                                                    line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement" ?
                                                        {flex: 0.5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 10, borderLeft: '1px solid '+greyChapter, borderTop: '1px solid white', backgroundColor: '#BABABA', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}
                                                    :
                                                        {flex: 0.5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 10, borderLeft: '1px solid '+greyChapter, borderTop: '1px solid white', backgroundColor: greyChapter, fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}
                                                :
                                                    {flex: 0.5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 9, borderLeft: '1px solid '+greyChapter, borderTop: '1px solid '+greyChapter, backgroundColor: 'white'}
                                        }
                                    >
                                        <Text
                                        style={{flex: 1, flexDirection: 'row', textAlign: 'right', alignItems: 'center', padding: 5}}
                                        >
                                            {val[1] === null || val[1] === '' || val[1] === 0 || val[1] === '0'?'':addSpace(Math.round(val[1]*100)/100)}
                                        </Text>
                                    </View>
                                    )
                            )}
                        </View>)
                        :null}
                    {/* </View> */}
                    {/****/}

                </View>
    
    
                {/* Footer */}
                <View style={{position: 'absolute', bottom: 20, left: 20}}>
                    <Text style={{fontSize: 10}}>{props.date}</Text>
                </View>
    
                <View style={{position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-25%)'}}>
                    <Text style={{fontSize: 10}}>page : {props.pagination} / {props.countPageTotal}</Text>
                </View>
    
                <View style={{position: 'absolute', bottom: 20, right: 20}}>
                    <Image style={{width: 150, borderRadius: 5}} src={Logo} />
                </View>

            </Page>
    );
}
  
  export default Page6_PDF_IncidencesFinancieres;