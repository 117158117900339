import { rescueHtml } from '../components/Tools.js';
import React, { useEffect, useState } from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import Logo from '../Img/Logo_projets&finances.png';

const Page4_PDF_Pilotage = (props) => {
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
            setIsLoaded(true);
        }, []);

    if (!isLoaded) {
        return null;
    }

    return (
        <Page 
            size="A4" 
            style={{backgroundColor: 'white', width: '100%', height: '100%', padding: 20}} 
            orientation="landscape"
        >
            {/* Header - Logo */}
            <View style={{flexDirection: 'row'}}>
                <View style={{flex: 0.5, flexDirection: 'row', height: 75}}>
                    {props.logo && <Image style={{maxHeight: 75, borderRadius: 5}} src={props.logo} alt="Logo" />}
                </View>
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                    <Text style={{color: blue, fontSize: 18}}>{props.nom?props.nom:''}</Text>
                    <Text style={{color: blue, fontSize: 12, marginLeft: 5, fontFamily: 'Helvetica-Oblique'}}>{props.index !== null ? '('+props.index+'/'+props.indexTotal+')' : ''}</Text>
                </View>
                <View style={{flex: 0.5, flexDirection: 'row', fontSize: 12}}>
                    <Text style={{color: 'grey'}}>Synthèse globale des projets</Text>
                </View>
            </View>

            {/* Container */}
            <View style={{flex: 1, marginTop: 20, marginBottom: 40}}>
                
                {/* Entete */}
                <View style={{flexDirection: 'row', fontSize: 9, fontFamily: 'Helvetica-Bold'}}>
                    <View style={{flex: 0.1, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>N°</Text>
                    </View>
                    <View style={{flex: 0.2, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                        <Text>État</Text>
                    </View>
                    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Intitulé</Text>
                    </View>
                    <View style={{flex: 0.4, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Date de la</Text>
                        <Text>dernière décision</Text>
                    </View>
                    <View style={{flex: 0.9, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Décision</Text>
                    </View>
                    <View style={{flex: 0.4, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Date limite</Text>
                        <Text>de l'action</Text>
                    </View>
                    <View style={{flex: 0.9, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Action suivante</Text>
                    </View>
                </View>

                <View style={{flex: 1}}>
                {props.projets.map((projet, i) => (
                    projet && (
                    <View
                        key={i}
                        style={{flexDirection: 'row', marginTop: 4, border: '1px solid ' + greyChapter, borderRadius: 5, fontSize: 9, height: 37}}
                    >
                        
                        {/* Numéro du projet */}
                        <View style={{flex: 0.1, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{}}>{projet[2] ? projet[2] : ''}</Text>
                        </View>

                        {/* État / couleur */}
                        <View style={{flex: 0.2, justifyContent: 'center', alignItems: 'center'}}>
                            <View style={{
                                border: '1px solid ' + (projet[27] ? projet[27] : '#ffffff'), 
                                backgroundColor: projet[27] ? projet[27] : '#ffffff', 
                                borderRadius: '50%', 
                                width: 25, 
                                height: 25
                            }}></View>
                        </View>

                        {/* Intitulé */}
                        <View style={{flex: 1, justifyContent: 'center'}}>
                            <Text style={{padding: 5, overflow: 'hidden', fontFamily: 'Helvetica-Bold'}}>{projet[3] ? rescueHtml(projet[3]) : ''}</Text>
                        </View>

                        {/* Date de la dernière décision */}
                        <View style={{flex: 0.4, justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                            <Text style={{padding: 5, overflow: 'hidden'}}>{projet['pilotage_date'] && projet['pilotage_date'] !== '0000-00-00'? (projet['pilotage_date'].split('-')[2]+'/'+projet['pilotage_date'].split('-')[1]+'/'+projet['pilotage_date'].split('-')[0]) : ''}</Text>
                        </View>

                        {/* Décision */}
                        <View style={{flex: 0.9}}>
                            <Text style={{padding: 5, overflow: 'hidden', fontSize: 6.7}}>{projet['pilotage_decision']?rescueHtml(projet['pilotage_decision'].trim().replace(/\s+/g, ' ')):''}</Text>
                        </View>

                        {/* Date limite de l'action */}
                        <View style={{flex: 0.4, justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                            <Text style={{padding: 5, overflow: 'hidden'}}>{projet['pilotage_date_limite'] && projet['pilotage_date_limite'] !== '0000-00-00'?(projet['pilotage_date_limite'].split('-')[2]+'/'+projet['pilotage_date_limite'].split('-')[1]+'/'+projet['pilotage_date_limite'].split('-')[0]):''}</Text>
                        </View>

                        {/* Action suivante */}
                        <View style={{flex: 0.9}}>
                            <Text style={{padding: 5, overflow: 'hidden', fontSize: 6.7}}>{projet['pilotage_action_suivante']?rescueHtml(projet['pilotage_action_suivante'].trim().replace(/\s+/g, ' ')):''}</Text>
                        </View>

                    </View>
                    )
                ))}
                </View>
            </View>
            
            {/* Footer */}
            <View style={{position: 'absolute', bottom: 20, left: 20}}>
                <Text style={{fontSize: 8}}>{props.date}</Text>
            </View>

            <View style={{position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-25%)'}}>
                <Text style={{fontSize: 8}}>page : {props.pagination} / {props.countPageTotal}</Text>
            </View>

            <View style={{position: 'absolute', bottom: 20, right: 20}}>
                <Image style={{width: 150, borderRadius: 5}} src={Logo} />
            </View>
            
        </Page>
    );
}

export default Page4_PDF_Pilotage;