import React from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { addSpace, rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';

// Importation de la police Montserrat
Font.register({
    family: 'Montserrat',
    src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2'
});

const Page2_PDF_NomDonneesGenerales = (props) => {
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';
    const greenpastel = '#b8e994';
    const orangepastel = '#ffbe76';
    const redpastel = '#ff7979';

    return (
        <Page
            size="A4"
            style={{flexDirection: 'column', backgroundColor: 'white', padding: 20, fontSize: 10}}
            orientation="landscape"
        >
            
            {/* Header - Logo */}
            <View style={{height: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                <View style={{flex: 1}}></View>
                {props.logo && <Image style={{objectFit: 'contain', maxHeight: '100%', maxWidth: '100%', borderRadius: 5}} src={props.logo} alt="Logo" />}
                <View style={{flex: 1}}></View>
            </View>
            {/* <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Image style={{maxHeight: 125, borderRadius: 5}} src={props.logo} />
            </View> */}

            {/* Container */}
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <View>
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <View
                        style={props.projet[27] === greenpastel?
                        {margin: 3, border: '1px solid '+greenpastel, borderRadius: 100, width: 35, height: 35, backgroundColor: greenpastel}
                        :
                        {margin: 3, border: '1px solid '+greenpastel, borderRadius: 100, width: 35, height: 35, backgroundColor: 'white'}}>
                        </View>
                        <View
                        style={props.projet[27] === orangepastel?
                        {margin: 3, border: '1px solid '+orangepastel, borderRadius: 100, width: 35, height: 35, backgroundColor: orangepastel}
                        :
                        {margin: 3, border: '1px solid '+orangepastel, borderRadius: 100, width: 35, height: 35, backgroundColor: 'white'}}>
                        </View>
                        <View
                        style={props.projet[27] === redpastel?
                        {margin: 3, border: '1px solid '+redpastel, borderRadius: 100, width: 35, height: 35, backgroundColor: redpastel}
                        :
                        {margin: 3, border: '1px solid '+redpastel, borderRadius: 100, width: 35, height: 35, backgroundColor: 'white'}}>
                        </View>
                    </View>
                    <View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 5}}>
                        <Text style={{fontSize: 17}}>Projet n° {props.projet[2]}</Text>
                    </View>
                    <Text style={{marginTop: 10, color: blue, fontSize: 20}}>{props.projet[3]}</Text>
                </View>
            </View>

            {/* Footer */}
            <View style={{height: '20%', position: 'relative'}}>
                <View style={{position: 'absolute', bottom: 0}}>
                    <Text style={{fontSize: 10}}>{props.date}</Text>
                </View>
                <View style={{position: 'absolute', bottom: 0, left: '45%', display: 'flex'}}>
                    <Text style={{fontSize: 12}}>page : {props.pagination} / {props.countPageTotal}</Text>
                </View>
                <View style={{position: 'absolute', bottom: 0, right: 0, display: 'flex'}}>
                    <Image style={{objectFit: 'contain', width: 200, borderRadius: 5}} src={Logo} alt="Logo" />
                </View>
            </View>
        </Page>
    );
};

export default Page2_PDF_NomDonneesGenerales;
