import { rescueHtml, addSpace } from '../components/Tools.js';
import React, { useEffect, useState } from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import Logo from '../Img/Logo_projets&finances.png';
import axios from 'axios';
import qs from 'qs';

const Page5_PDF_BilanFinancier = (props) => {
    const blue = '#069BD3';
    const smoothGrey = '#f4f4f4';
    const greyChapter = '#DBDBDB';

    const [objet, setObjet] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (props.user.id_budget && props.hypothese && props.annees.length > 0) {
            mount();
            setIsLoaded(true);
        }
    }, [props.user.id_budget, props.hypothese, props.annees]);

    if (!isLoaded) {
        return null;
    }


    async function mount()
    {
        await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
            'getSumIncidencesFinancieres': true,
            'annees': props.annees,
            'id_budget': props.user.id_budget,
            'hypothese': props.hypothese
        }))
        .then(async (res) => {
            var total = [];
            await res.data.forEach((element, i) => {
                total[i] = 0;
                element.forEach((elem, j) => {
                    if (j > 0) {
                        elem[1] = parseFloat(elem[1]);
                        total[i] += (elem[1]);
                    }
                });
            });
            await res.data.forEach((line, k) => {
                res.data[k].push(['Total', total[k]])
            });
            await setObjet(res.data)
            await console.log(res.data)
        })
    }


    return (
        <Page 
            size="A4" 
            style={{backgroundColor: 'white', width: '100%', height: '100%', padding: 20}} 
            orientation="landscape"
        >
            {/* Header - Logo */}
            <View style={{flexDirection: 'row'}}>
                <View style={{flex: 0.5, flexDirection: 'row', height: 75}}>
                    {props.logo && <Image style={{maxHeight: 75, borderRadius: 5}} src={props.logo} alt="Logo" />}
                </View>
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                    <Text style={{color: blue, fontSize: 18}}>{props.nom?props.nom:''}</Text>
                </View>
                <View style={{flex: 0.5, flexDirection: 'row', fontSize: 12}}>
                    <Text style={{color: 'grey'}}>Synthèse globale des projets</Text>
                </View>
            </View>

            {/* Container */}
            <View style={{flex: 1, marginTop: 20, marginBottom: 40, fontSize: 12}}>
                
                {/* Entete */}
                <View style={{flexDirection: 'row', backgroundColor: blue, color: 'white', textAlign: 'center', borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                    <View style={{padding: 3, width: 150}}></View>

                    {props.annees.map((annee, i) => 
                    <View key={i} style={{flex: 0.5, paddingTop: 3, paddingBottom: 3, borderLeft: '1px solid white'}}>
                        <Text>{annee}</Text>
                    </View>
                    )}
                    
                    <View style={{flex: 0.5, borderLeft: '1px solid white', fontFamily: 'Helvetica-Bold', backgroundColor: '#2980b9', borderTopRightRadius: 5}}>
                        <Text>Total</Text>
                    </View>
                </View>

                {/* Lignes */}
                <View style={{flex: 1, borderBottomLeftRadius: 2, borderBottomRightRadius: 2, fontSize: 8}}>
                    {objet.length !== 0?
                    objet.map((line, i) =>
                    <View
                        key={i}
                        style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?
                            {flexDirection: 'row', borderBottom: '1px solid '+blue}
                        :
                        {flexDirection: 'row', borderBottom: '1px solid '+blue}}
                    >
                        {line.map((val, j) =>
                            /* Intitulé des lignes */
                            j === 0?
                            <View
                                key={j}
                                style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?
                                {padding: 3, width: 150, backgroundColor: greyChapter, borderLeft: '1px solid '+blue}
                                :
                                {padding: 3, width: 150, backgroundColor: smoothGrey, borderLeft: '1px solid '+blue}}
                            >
                                <Text
                                    style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?
                                    {fontFamily: 'Helvetica-Bold'}
                                    :
                                    {textAlign: 'right'}}
                                >
                                    {val
                                    .replace('_', "'")
                                    .replace('Subventions', "Subv.")
                                    .replace('équipement', "équip.")
                                    .replace("Autres recettes d'investissement", "Autres recettes d'invest.")
                                    }
                                </Text>
                            </View>
                            :
                            /* Montants des lignes */
                            j > 0?
                            <View key={j} style={
                                val[0] !== 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                    {flex: 0.5, padding: 3, flexDirection: 'row', borderLeft: '1px solid '+blue, minWidth: 50, backgroundColor: greyChapter}
                                    :
                                    val[0] === 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                        {flex: 0.5, padding: 3, flexDirection: 'row', borderLeft: '1px solid '+blue, borderRight: '1px solid '+blue, minWidth: 50, backgroundColor: '#BABABA', fontFamily: 'Helvetica-Bold'}
                                        :
                                        val[0] === 'Total'?
                                            {flex: 0.5, padding: 3, flexDirection: 'row', borderLeft: '1px solid '+blue, borderRight: '1px solid '+blue, minWidth: 50, backgroundColor: greyChapter, fontFamily: 'Helvetica-Bold'}
                                            :
                                            {flex: 0.5, padding: 3, flexDirection: 'row', borderLeft: '1px solid '+blue, minWidth: 50}
                            }>
                                <Text
                                    style={val[0] === 'Total' || line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50, fontFamily: 'Helvetica-Bold'}:{flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50}}
                                >
                                {val[1] === null || val[1] === '' || val[1] === '0' || val[1] === 0?'':addSpace(Math.round(val[1] * 100) / 100, true)}
                                </Text>
                            </View>
                            :null
                        )}
                    </View>)
                    :null}
                </View>

            </View>
            
            {/* Footer */}
            <View style={{position: 'absolute', bottom: 20, left: 20}}>
                <Text style={{fontSize: 8}}>{props.date}</Text>
            </View>

            <View style={{position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-25%)'}}>
                <Text style={{fontSize: 8}}>page : {props.pagination} / {props.countPageTotal}</Text>
            </View>

            <View style={{position: 'absolute', bottom: 20, right: 20}}>
                <Image style={{width: 150, borderRadius: 5}} src={Logo} />
            </View>
            
        </Page>
    );
}

export default Page5_PDF_BilanFinancier;