import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { addSpace, rescueHtml } from '../components/Tools';


const Page4RestitutionFct = (props) =>
{
    const blue = '#069BD3'
    const smoothGrey = '#f4f4f4'
    const greyChapter = '#DBDBDB'

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={Logo} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginLeft: 25, height: '80%'}}>
                <div style={{flex: 1, borderRadius: 2, marginRight: 25}}>
                    <div style={{flex: 1, backgroundColor: blue, color: 'white', display: 'flex', fontSize: 13}}>

                        <div style={{flex: 3.5, textAlign: 'center'}}>
                            <p>Montants en €</p>
                        </div>

                        {/* Affichage des années */}
                        {props.annees !== null?
                        props.annees.map((an, j) =>
                        (props.evolution && j > 0) || !props.evolution?
                        <div key={j} style={{flex: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                            <p>{an}</p>
                        </div>
                        :null
                        ):null}

                        {/* Affichage du titre de la colonne % annuel moyen */}
                        {!props.evolution?
                        <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: '#7f8c8d'}}>
                            <p style={{fontSize: 11}}>% annuel moy.</p>
                        </div>
                        :null}

                    </div>


                    {/* Parcourt le tableau en lignes */}
                    {props.restitution !== null?
                    props.restitution.map((line, i) => 
                    <div key={i} style={line[0].chapitre === '1'?{flex: 1, borderTop: '1px solid white', display: 'flex', backgroundColor: blue}:i%2?{flex: 1, borderTop: '1px solid white', display: 'flex', backgroundColor: smoothGrey}:{flex: 1, borderTop: '1px solid white', display: 'flex'}}>

                        {/* Affichage de la colonne intitulé */}
                        <div style={{flex: 3.5, justifyContent: 'center'}}>
                            <p style={
                            line[0].chapitre === '1'?
                                {fontSize: 10, paddingLeft: 2, color: 'white'}
                            :
                                line[0].indentation === '2'?
                                    {fontSize: 8, paddingLeft: (line[0].indentation + 1)*2, fontStyle: 'italic'}
                                :
                                    {fontSize: 10, paddingLeft: (line[0].indentation + 1)*2}
                            }>{line[0].intitule}</p>
                        </div>
                        

                        {/* Affichage des colonnes */}
                        {line.map((col, l) =>
                        l !== 0?
                        <div key={l} style={{flex: 1, justifyContent: 'center', borderLeft: '1px solid white'}}>
                            <p style={
                            line[0].chapitre === '1'?
                                {fontSize: 11, textAlign: 'right', paddingRight: 2, color: 'white'}
                            :
                                line[0].indentation === '2'?
                                    {fontSize: 8, textAlign: 'right', paddingRight: 2, fontStyle: 'italic'}
                                :
                                    {fontSize: 11, textAlign: 'right', paddingRight: 2}
                            }>
                                {props.evolution && (Math.round(col[1]*100)/100) > 0?'+':null}
                                {(props.evolution && (Math.round(col[1]*100)/100) != 0) || (!props.evolution && Math.round(col[1]) != 0)?
                                    addSpace(props.evolution?(Math.round(col[1]*100)/100):(Math.round(col[1])), true)
                                    :
                                    '.'
                                }
                                {props.evolution && (Math.round(col[1]*100)/100) != 0?' %':''}
                            </p>
                        </div>
                        :null)}


                        {/* Affichage de la colonne % annul moyen */}
                        {!props.evolution?
                        <div style={{flex: 1, justifyContent: 'center', borderLeft: '1px solid white'}}>
                            <p style={
                            line[0].chapitre === '1'?
                                {fontSize: 11, textAlign: 'right', paddingRight: 2, color: 'white', backgroundColor: '#7f8c8d'}
                            :
                                line[0].indentation === '2'?
                                    {fontSize: 8, textAlign: 'right', paddingRight: 2, fontStyle: 'italic'}
                                :
                                    {fontSize: 11, textAlign: 'right', paddingRight: 2}
                            }>
                                {line[1][1] != 0 && Math.pow( (line[line.length-1][1]/line[1][1]) , (1/(props.annees.length-1)))?
                                Math.round((Math.pow((line[line.length-1][1]/line[1][1]) , (1/(props.annees.length-1))) - 1) * 10000) / 100+'%'
                                :'.'}
                            </p>
                        </div>
                        :null}

                    </div>
                    )
                    :
                    <div style={{flex: 10, borderTop: '1px solid white', display: 'flex'}}></div>
                    }
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
}
  
  export default Page4RestitutionFct;