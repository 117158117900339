import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import Dialog from "./components/Dialog";
import Gantt from "./components/GanttComponent";
import ModalPilotage from "./components/ModalPilotage";
import { addSpace, rescueHtml } from "./components/Tools";
import {NotificationManager} from 'react-notifications';
import { ReactComponent as Check } from './Img/icon-check.svg';
import { ReactComponent as Add } from './Img/icon-add.svg';
import { ReactComponent as Trash } from './Img/icon-trash.svg';

/*
    La variable projet contient toutes les infos du projet et est structurée ainsi:
    projet[0] = id
    projet[1] = id_budget
    projet[2] = numero_projet
    projet[3] = nom
    projet[4] = enjeu
    projet[5] = priorite
    projet[6] = priorite_niveau
    projet[7] = pol_pub_rat (politique publique de rattachement)
    projet[8] = referent_elu
    projet[9] = montant
    projet[10] = description
    projet[11] = remarques
    projet[12] = maitre_ouvrage
    projet[13] = partenaire_mobiliser
    projet[14] = service_referent
    projet[15] = technicien_ref_nom
    projet[16] = technicien_ref_fonction
    projet[17] = technicien_ref_telephone
    projet[18] = technicien_ref_mail
    projet[19] = ressources_humaines
    projet[20] = etudes
    projet[21] = outils
    projet[22] = investissements
    projet[23] = incidences_organisationnelle_administrative
    projet[24] = h1
    projet[25] = h2
    projet[26] = h3
    projet[27] = couleur
*/
const FicheProjet = (props) =>
{
    let id = window.location.pathname.split('/')[4];
    const [projet, setProjet] = useState([]);
    const [incidences_financieres, setIncidences_financieres] = useState([]);
    const [echeancier_realisation, setEcheancier_realisation] = useState([]);
    const [pilotage_projet, setPilotage_projet] = useState([]);
    const [annees, setAnnees] = useState([]);
    const [tags, setTags] = useState([]);
    const [aff, setAff] = useState(false);
    const [details, setDetails] = useState(true);
    const [echeancier, setEcheancier] = useState(false);
    const [moyens, setMoyens] = useState(false);
    const [incidences, setIncidences] = useState(false);
    const [pilotage, setPilotage] = useState(false);
    const [objetPilotage, setObjetPilotage] = useState(false);
    const [gantt, setGantt] = useState(false);
    const [pilotageLineSelected, setPilotageLineSelected] = useState(null);
    const heightObject = window.innerHeight/1.9;
    const [modalPilotage, setModalPilotage] = useState(false);

    const blue = '#069BD3';
    const purple = '#6571FE';
    const greyChapter = '#DBDBDB';
    const green = '#59B44A';
    const orange = '#FFA500';
    const red = '#FF0000';
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    async function callbackDialog(ret) {
        if (ret === true) {
            await suppressLinePilotage();
        }
        else {
            await setPilotageLineSelected(null)
        }
    }

    async function suppressLinePilotage()
    {
        pilotage_projet.forEach((element, i) => {
            if (element[0] === pilotageLineSelected) {
                delete pilotage_projet[i];
            }
        });
        await setPilotageLineSelected(null)
    }
    

    async function mount()
    {
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify({
            'getFichesProjets': true,
            'id': id,
            'id_budget': props.user.id_budget
        }))
        .then((result) => {
            result.data[0][3] = rescueHtml(result.data[0][3]);
            setProjet(result.data[0])
        })

        await axios.post('https://app-slfinance.fr/api/pilotage_projet.php', qs.stringify({
            'get': true,
            'id_projet': id,
        }))
        .then((res) => {
            setPilotage_projet(res.data);
        })

        await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
            'getTags': true,
            'id_budget': props.user.id_budget
        }))
        .then(async(result) => {
            await setTags(result.data)
            await setAff(!aff)
        })

        // Recuperation des annees de prospective
        let prosp_an_deb = ''
        let prosp_an_fin = ''
        await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+props.user.id_budget)
        .then((result) => {
            result.data.forEach(element => {
                if (element[2] === 'prosp_an_deb') {
                    prosp_an_deb = parseInt(element[3])
                }
                if (element[2] === 'prosp_an_fin') {
                    prosp_an_fin = parseInt(element[3])
                }
            });
        }).catch((err) => {console.log(err)})
        
        if (prosp_an_deb === '' || prosp_an_fin === '')
        {
            await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
            .then(async function (res) {
                if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                    if (prosp_an_deb === '') {
                        prosp_an_deb = parseInt(res.data.prosp_an_deb)
                    }
                    if (prosp_an_fin === '') {
                        prosp_an_fin = parseInt(res.data.prosp_an_fin)
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        var liste_an = []
        var i = 0
        while (prosp_an_deb <= prosp_an_fin)
        {
            liste_an[i] = parseInt(prosp_an_deb)
            prosp_an_deb++;
            i++;
        }
        await setAnnees(liste_an)

        await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
            'get': true,
            'annees': liste_an,
            'id_projet': id
        }))
        .then(async(res) => {
            var total = [];
            await res.data.forEach((element, i) => {
                total[i] = 0;
                element.forEach((elem, j) => {
                    if (j > 1) {
                        if (!parseFloat(elem[1])) {
                            elem[1] = 0;
                        }
                        else {
                            elem[1] = parseFloat(elem[1]);
                        }
                        total[i] += (elem[1]);
                    }
                });
            });
            await res.data.forEach((line, k) => {
                res.data[k].push(['Total', total[k]])
            });
            setIncidences_financieres(res.data)
        })

        await axios.post('https://app-slfinance.fr/api/echeancier_realisation.php', qs.stringify({
            'get': true,
            'annees': liste_an,
            'id_projet': id
        }))
        .then((res) => {
            setEcheancier_realisation(res.data)
        })
    }


    // Récuperation de la fiche projet
    useEffect(() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            mount();
        }
        
    }, [props.user])


    function calcul_totaux(obj)
    {
        var tot = 0;
        obj.forEach((line, i) => {
            tot = 0;
            line.forEach((val, j) => {
                if (j > 1) {
                    if (val[0] === 'Total') {
                        obj[i][j][1] = tot;
                    }
                    else {
                        tot += parseFloat(val[1]);
                    }
                }
            })
            
        })
        setIncidences_financieres(obj);
    }

    
    function sumChapters(obj)
    {
        obj.forEach((line, i) => {
            line.forEach((val, j) => {
                if (j > 1) {
                    if (line[1] === '') {
                        obj[i][j][1] = 0;
                    }
                }
            });
        });
        obj.forEach((line) => {
            line.forEach((val, j) => {
                if (j > 1) {
                    var cel = 0
                    if (val[1] !== null && val[1] !== '') {
                        cel = parseFloat(val[1])
                    }
                    if (line[1] === "DF") {
                        obj[0][j][1] = (parseFloat(obj[0][j][1]) + cel).toString();
                    }
                    if (line[1] === "RF") {
                        obj[5][j][1] = (parseFloat(obj[5][j][1]) + cel).toString();
                    }
                    if (line[1] === "DI") {
                        obj[10][j][1] = (parseFloat(obj[10][j][1]) + cel).toString();
                    }
                    if (line[1] === "RI") {
                        obj[15][j][1] = (parseFloat(obj[15][j][1]) + cel).toString();
                    }
                }
            });
        });
        calcul_totaux(obj);
    }

    async function updateObjetLine(matricule, annee, valeur)
    {
        var tmp = incidences_financieres;
        await tmp.forEach(async (line, i) => {
            if (line[0] === matricule) {
                await line.forEach((cel, j) => {
                    if (cel[0] === annee) {
                        tmp[i][j][1] = valeur;
                    }
                });
            }
        });
        await sumChapters(tmp);
        await setAff(!aff);
    }
    

    async function valider()
    {
        var ret = true;

        // Requette pour modifier une fiche projet
        await axios.post('https://app-slfinance.fr/api/fiches_projets.php', qs.stringify({'updateProjet': true, 'projet': projet}))
        .then(async (result) => {

            if (result.data['status'] === 1) {
                NotificationManager.success(result.data['status_message'], '', 700);


                /* Si la fiche projet a pu etre modifiee, alors requette pour modifier Incidences financières */
                await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({'update': true, 'id_projet': id, 'incidences_financieres': incidences_financieres}))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 800);
                    }
                    else {
                        ret = false;
                        NotificationManager.warning("La modification du tableau des Incidences financières n'a pas pu se faire", '', 3000);
                    }
                })

                /* Si la fiche projet a pu etre modifiee, alors requette pour modifier Pilotage projet */
                await axios.post('https://app-slfinance.fr/api/pilotage_projet.php', qs.stringify({'update': true, 'id_projet': id, 'objet': pilotage_projet}))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 800);
                    }
                    else {
                        ret = false;
                        NotificationManager.warning("La modification du tableau du pilotage des projets n'a pas pu se faire", '', 3000);
                    }
                })

                /* Si la fiche projet a pu etre modifiee, alors requette pour modifier Echeancier Realisation */
                await axios.post('https://app-slfinance.fr/api/echeancier_realisation.php', qs.stringify({'update': true, 'id_projet': id, 'echeancier_realisation': echeancier_realisation}))
                .then((res) => {
                    if (res.data['status'] === 1) {
                        NotificationManager.success(res.data['status_message'], '', 800);
                    }
                    else {
                        ret = false;
                        NotificationManager.warning("La modification du tableau des Echeancier de Realisation n'a pas pu se faire", '', 3000);
                    }
                })
            }
            else {
                ret = false;
                NotificationManager.warning("La modification de la fiche projet n'a pas pu se faire", '', 3000);
            }
        })
        return ret;
    }


    async function addLinePilotage()
    {
        let date = new Date();
        let yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();
        if (dd < 10) {
            dd = '0'+dd;
        }
        if (mm < 10) {
            mm = '0'+mm;
        }
        
        var tmp = [
            'tmp_'+pilotage_projet.length,//id
            id,//                           id_projet
            yyyy+'-'+mm+'-'+dd,//           date
            '',//                           instance reunie
            '',//                           releve decision
            '',//                           action suivante
            '0000-00-00',//                 date limite
            '',//                           responsable action
            '',//                           liste mails
            ''//                     couleur
        ];
        await pilotage_projet.push(tmp)
        await setAff(!aff);
    }


	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
            <Navbar user={props.user} />

                <Dialog
                    setModalVisible={setDialogModalVisible}
                    modalVisible={dialogModalVisible}
                    callbackDialog={callbackDialog}
                    message={"Confirmez la suppression"}
                />

                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, margin: 20, display: 'flex'}}>
                        <div style={{flex: 1, display: 'flex'}}>
                            <p>Prospective \ Fiches projets \ Projet n°{projet[2]} :</p>
                            <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>{projet && projet[3]?rescueHtml(projet[3]):''}</p>
                        </div>
                        <div>
                            {props.user?
                            <p style={{flex: 1, textAlign: 'center'}}>{props.user.nom_budget}</p>
                            :null}
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>
                </div>

                <div style={{flex: 1, textAlign: 'center'}}>

                    {projet && projet.length !== 0?
                    <div style={{marginBottom: 100, marginRight: 100, marginLeft: 100}}>

                        <div style={{display: 'flex', marginBottom: 20}}>

                            <button
                            style={projet[27] === green?{border: '2px solid '+green, borderRadius: 100, minWidth: 50, backgroundColor: green}:{border: '2px solid '+green, borderRadius: 100, minWidth: 50, backgroundColor: 'white'}}
                            onClick={async() => {
                                if (projet[27] === green) {
                                    projet[27] = '#ffffff';
                                    await setAff(!aff);
                                }
                                else {
                                    projet[27] = green;
                                    await setAff(!aff);
                                }
                            }}
                            >
                            </button>

                            <button
                            style={projet[27] === orange?{marginLeft: 20, border: '2px solid '+orange, borderRadius: 100, minWidth: 50, backgroundColor: orange}:{marginLeft: 20, border: '2px solid '+orange, borderRadius: 100, minWidth: 50, backgroundColor: 'white'}}
                            onClick={async() => {
                                if (projet[27] === orange) {
                                    projet[27] = '#ffffff';
                                    await setAff(!aff);
                                }
                                else {
                                    projet[27] = orange;
                                    await setAff(!aff);
                                }
                            }}
                            >
                            </button>

                            <button
                            style={projet[27] === red?{marginLeft: 20, border: '2px solid '+red, borderRadius: 100, minWidth: 50, backgroundColor: red}:{marginLeft: 20, border: '2px solid '+red, borderRadius: 100, minWidth: 50, backgroundColor: 'white'}}
                            onClick={async() => {
                                if (projet[27] === red) {
                                    projet[27] = '#ffffff';
                                    await setAff(!aff);
                                }
                                else {
                                    projet[27] = red;
                                    await setAff(!aff);
                                }
                            }}
                            >
                            </button>
                            
                            <div style={{flex: 1}}></div>

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={details?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(true)
                                    setEcheancier(false)
                                    setGantt(false)
                                    setMoyens(false)
                                    setIncidences(false)
                                    setPilotage(false)
                                }}
                            >
                                Données générales
                            </button>

                            {/* <button
                                className='shadow-md p-2 rounded-md'
                                style={echeancier?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(true)
                                    setGantt(false)
                                    setMoyens(false)
                                    setIncidences(false)
                                    setPilotage(false)
                                }}
                            >
                                Échéancier de réalisation
                            </button> */}

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={gantt?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(false)
                                    setGantt(true)
                                    setMoyens(false)
                                    setIncidences(false)
                                    setPilotage(false)
                                }}
                            >
                                Échéancier
                            </button>

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={moyens?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(false)
                                    setGantt(false)
                                    setMoyens(true)
                                    setIncidences(false)
                                    setPilotage(false)
                                }}
                            >
                                Moyens mis en œuvre
                            </button>

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={incidences?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(false)
                                    setGantt(false)
                                    setMoyens(false)
                                    setIncidences(true)
                                    setPilotage(false)
                                }}
                            >
                                Incidences Financières
                            </button>

                            <button
                                className='shadow-md p-2 rounded-md'
                                style={pilotage?{backgroundColor: purple, color: 'white', fontSize: 17, margin: 5}:{backgroundColor: 'white', color: purple, fontSize: 17, margin: 5, border: '1px solid '+purple}}
                                onClick={() => {
                                    setDetails(false)
                                    setEcheancier(false)
                                    setGantt(false)
                                    setMoyens(false)
                                    setIncidences(false)
                                    setPilotage(true)
                                }}
                            >
                                Pilotage
                            </button>
                            <div style={{flex: 1}}></div>

                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: green, color: 'white', fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                                onClick={async () => {
                                    if (projet.length !== 0) {
                                        await valider()
                                        .then((res) => {
                                            if (res) {
                                                setTimeout(() => {window.location = window.location.origin + '/saisie/prospective/fichesprojets'}, 1000);
                                            }
                                        })
                                    }
                                }}
                            >
                                <Check width="12" />
                                <p style={{marginLeft: 10}}>Valider</p>
                            </button>

                        </div>

                        {/****************************** Données générales ********************************/}
                        {details?
                        <div>

                            {/* Ligne 1: Intitulé du projet */}
                            <div style={{display: 'flex', margin: 10}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Intitulé du projet</p>
                                    <small>{`${projet[3]?.length || 0}/50 caractères`}</small><br/>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                <input
                                    style={{ flex: 1, padding: 5 }}
                                    onChange={async (e) => {
                                        // Autorise la modification uniquement si la nouvelle valeur est <= 50 caractères
                                        // if (e.target.value.length <= 50) {
                                            projet[3] = e.target.value;
                                            await setAff(!aff);
                                        // }
                                    }}
                                    onBlur={async () => {
                                        projet[3] = projet[3]?projet[3].trim().replace(/\s+/g, ' '):'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[3] === null ? "" : rescueHtml(projet[3])}
                                />

                                    <div style={{padding: 10, minWidth: 200}}>
                                        <p>Projet numéro : </p>
                                        <input
                                        style={{marginLeft: 10, width: 100, textAlign: 'center', border: '1px solid '+blue, borderRadius: 3}}
                                        onChange={async (e) => {
                                            projet[2] = (e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[2] === null?"":rescueHtml(projet[2])}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/****/}

                            {/* Ligne 2: Enjeu & Priorité */}
                            <div style={{display: 'flex', margin: 10}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Descriptif des enjeux</p>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <input
                                    style={{flex: 1, padding: 5}}
                                    onChange={async (e) => {
                                        projet[4] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[4] = projet[4]?projet[4].trim().replace(/\s+/g, ' '):'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[4] === null?"":rescueHtml(projet[4])}
                                    />
                                    <div style={{padding: 10, minWidth: 200}}>
                                        <p>Niveau de priorité : </p>
                                        <select
                                        style={{marginLeft: 10, minWidth: 100, textAlign: 'center', border: '1px solid '+blue, borderRadius: 3}}
                                        value={projet[6]}
                                        onChange={async (e) => {
                                            projet[6] = (e.target.value)
                                            await setAff(!aff)
                                        }}
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            {/****/}

                            {/* Ligne 3: Politique publique de rattachement */}
                            <div style={{display: 'flex', margin: 10}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Politique publique de rattachement</p>
                                </div>
                                <div style={{display: 'flex', flex: 1, backgroundColor: 'white'}}>
                                    <select
                                    className='shadow-md'
                                    style={{flex: 1, padding: 5, margin: 2, fontSize: 18}}
                                    onChange={async (e) => {
                                        projet[7] = e.target.value;
                                        await setAff(!aff)
                                    }}
                                    value={projet[7]}
                                    >
                                        <option value=''><p>Politique publique de rattachement</p></option>
                                        {tags.map((t, i) =>
                                        <option key={i} value={t}><p>{t}</p></option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            {/****/}

                            {/* Ligne 4: Référent élu */}
                            <div style={{display: 'flex', margin: 10}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Référent élu</p>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <input
                                    style={{flex: 1, padding: 5}}
                                    onChange={async (e) => {
                                        projet[8] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[8] = projet[8]?projet[8].trim().replace(/\s+/g, ' '):'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[8] === null?"":rescueHtml(projet[8])}
                                    />
                                </div>
                            </div>
                            {/****/}

                            {/* Ligne 5: Montant du projet (en € TTC) */}
                            <div style={{display: 'flex', margin: 10}} className='shadow-md rounded-md'>
                                <div style={{display: 'flex', flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175, textAlign: 'center'}}>
                                    <div style={{flex: 1}}></div>
                                    <p>Montant du projet</p>
                                    <p style={{fontSize: 14, marginLeft: 5}}>(en € TTC)</p>
                                    <div style={{flex: 1}}></div>
                                </div>
                                {projet[9]?
                                <div style={{display: 'flex', flex: 1}}>
                                    <input
                                    type="float"
                                    style={{flex: 1, padding: 5}}
                                    onBlur={async (e) => {
                                        if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                            projet[9] = (Math.round(parseFloat(e.target.value) * 100) / 100).toString().replace(',', '.').replace('€', '').replace(' ', '').replace("'", '').replace('"', '')
                                            if (!e.target.value.includes('€')) {
                                                e.target.value = addSpace((Math.round(parseFloat(e.target.value) * 100) / 100).toString().replace("'", '').replace('"', ''))+'€'
                                            }
                                            await setAff(!aff)
                                        }
                                        e.target.value = ''
                                    }}
                                    placeholder={projet[9] !== null?addSpace(parseFloat(projet[9]))+'€':null}
                                    />
                                </div>
                                :null}
                            </div>
                            {/****/}

                            {/* Ligne 6: Description de l'action */}
                            <div style={{display: 'flex', margin: 10, minHeight: 100}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Description de l'action</p>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <textarea
                                    style={{flex: 1, padding: 5}}
                                    onChange={async (e) => {
                                        projet[10] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[10] = projet[10]?projet[10].trim():'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[10] === null?"":rescueHtml(projet[10])}
                                    />
                                </div>
                            </div>
                            {/****/}

                            {/* Ligne 7: Questions/ Remarques  */}
                            <div style={{display: 'flex', margin: 10, minHeight: 100}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Questions / Remarques</p>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <textarea
                                    style={{flex: 1, padding: 5}}
                                    onChange={async (e) => {
                                        projet[11] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[11] = projet[11]?projet[11].trim():'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[11] === null?"":rescueHtml(projet[11])}
                                    />
                                </div>
                            </div>
                            {/****/}

                            {/* Ligne 8: Maître d'ouvrage */}
                            <div style={{display: 'flex', margin: 10}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Maître d'ouvrage</p>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <input
                                    style={{flex: 1, padding: 5}}
                                    onChange={async (e) => {
                                        projet[12] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[12] = projet[12]?projet[12].trim().replace(/\s+/g, ' '):'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[12] === null?"":rescueHtml(projet[12])}
                                    />
                                </div>
                            </div>
                            {/****/}

                            {/* Ligne 9: Partenaires à mobiliser */}
                            <div style={{display: 'flex', margin: 10}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Partenaires à mobiliser</p>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <input
                                    style={{flex: 1, padding: 5}}
                                    onChange={async (e) => {
                                        projet[13] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[13] = projet[13]?projet[13].trim().replace(/\s+/g, ' '):'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[13] === null?"":rescueHtml(projet[13])}
                                    />
                                </div>
                            </div>
                            {/****/}

                            {/* Ligne 10: Service référent */}
                            <div style={{display: 'flex', margin: 10, marginTop: 50}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Service référent</p>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <input
                                    style={{flex: 1, padding: 5}}
                                    onChange={async (e) => {
                                        projet[14] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[14] = projet[14]?projet[14].trim().replace(/\s+/g, ' '):'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[14] === null?"":rescueHtml(projet[14])}
                                    />
                                </div>
                            </div>
                            {/****/}

                            {/* Ligne 11: Technicien référent */}
                                <div style={{display: 'flex', margin: 10}} className='shadow-md rounded-md'>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Technicien référent</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1}}>

                                        <div style={{flex: 1, padding: 5}}>
                                            <div style={{display: 'flex'}}>
                                                <input
                                                className='shadow-md rounded-md'
                                                style={{flex: 1, padding: 5, textAlign: 'center'}}
                                                onChange={async (e) => {
                                                    projet[15] = (e.target.value)
                                                    await setAff(!aff)
                                                }}
                                                onBlur={async () => {
                                                    projet[15] = projet[15]?projet[15].trim().replace(/\s+/g, ' '):'';
                                                    await setAff(!aff);
                                                }}
                                                value={projet[15] === null?"":rescueHtml(projet[15])}
                                                placeholder='Nom'
                                                />
                                            </div>
                                        </div>

                                        <div style={{flex: 1, padding: 5}}>
                                            <div style={{display: 'flex'}}>
                                                <input
                                                className='shadow-md rounded-md'
                                                style={{flex: 1, padding: 5, textAlign: 'center'}}
                                                onChange={async (e) => {
                                                    projet[18] = (e.target.value)
                                                    await setAff(!aff)
                                                }}
                                                onBlur={async () => {
                                                    projet[18] = projet[18]?projet[18].trim().replace(/\s+/g, ''):'';
                                                    await setAff(!aff);
                                                }}
                                                value={projet[18] === null?"":rescueHtml(projet[18])}
                                                placeholder='Email'
                                                />
                                            </div>
                                        </div>
                                        <div style={{padding: 5}}>
                                            <input
                                            className='shadow-md rounded-md'
                                            style={{padding: 5, textAlign: 'center'}}
                                            onChange={async (e) => {
                                                projet[17] = (e.target.value)
                                                await setAff(!aff)
                                            }}
                                            onBlur={async () => {
                                                projet[17] = projet[17]?projet[17].trim():'';
                                                await setAff(!aff);
                                            }}
                                            value={projet[17] === null?"":rescueHtml(projet[17])}
                                            placeholder='Téléphone'
                                            />
                                        </div>
                                    </div>
                                </div>
                            {/****/}

                            {/* Ligne 12: Fonction technicien référent */}
                            <div style={{display: 'flex', margin: 10}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                    <p>Fonction technicien référent</p>
                                </div>
                                <div style={{display: 'flex', flex: 1}}>
                                    <input
                                    style={{flex: 1, padding: 5}}
                                    onChange={async (e) => {
                                        projet[16] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[16] = projet[16]?projet[16].trim().replace(/\s+/g, ' '):'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[16] === null?"":rescueHtml(projet[16])}
                                    />
                                </div>
                            </div>
                            {/****/}
                        </div>
                        :null}
                        {/**************************************************************/}
                        

                        {/****************************** ANCIEN ECHEANCIER ********************************/}
                        {/* Ne s'affiche plus mais j'ai laissé le code au cas ou une ancienne collectivité demandait a revoir ce qu'ils avaient marqué dessus */}
                        {/* Enlever les commentaires sur le bouton plus haut dans le code pour afficher cette partie */}
                        {echeancier?
                        <div>
                            {/* Tableau des Echeancier realisation */}
                            <div style={{display: 'flex', marginTop: 10}}>
                                <div style={{flex: 1}}>
                                    <p>Actions à engager</p>
                                </div>
                                {annees.map((annee, i) => 
                                <div key={i} style={{flex: 0.7}}>
                                    <p>{annee}</p>
                                </div>)}
                            </div>
                            <div style={{flex: 1,  backgroundColor: 'white', border: '1px solid '+blue, borderRadius: 2}}>
                                {echeancier_realisation.length !== 0?
                                echeancier_realisation.map((line, i) =>
                                <div key={i} style={i === 0?{display: 'flex', minHeight: 80}:{display: 'flex', borderTop: '1px solid '+blue, minHeight: 80}}>
                                    {line.map((val, j) =>
                                        j < 1?
                                        <div key={j} style={{flex: 1, display: 'flex', fontSize: 14, minWidth: 50}}>
                                            <textarea
                                            style={{flex: 1, padding: 2, margin: 5, fontSize: 16}}
                                            onChange={async (e) => {
                                                line[1] = e.target.value
                                                await setAff(!aff)
                                            }}
                                            value={rescueHtml(line[1])}
                                            placeholder={"Action "+(i+1)}
                                            />
                                        </div>
                                        :
                                        j >= 2?
                                        <div key={j} style={{flex: 0.7, display: 'flex', fontSize: 14, borderLeft: '1px solid '+blue, minWidth: 50}}>
                                            <textarea
                                            style={{flex: 1, padding: 2, margin: 5, fontSize: 16}}
                                            onChange={async (e) => {
                                                line[j][1] = e.target.value
                                                await setAff(!aff)
                                            }}
                                            value={rescueHtml(val[1])}
                                            />
                                        </div>
                                        :null
                                    )}
                                </div>)
                                :null}
                            </div>
                            {/****/}

                            <div style={{ marginTop: 50, backgroundColor: 'white', border: '1px solid '+blue, borderRadius: 2}}>
                            
                                {/* Ligne 11: Service référent */}
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Service référent</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[14] = (e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[14] === null?"":rescueHtml(projet[14])}
                                        />
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 12: Technicien référent */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Fonction du technicien référent</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <textarea
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[16] = (e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[16] === null?"":rescueHtml(projet[16])}
                                        />

                                        <div style={{flex: 1, padding: 5, borderLeft: '1px solid '+blue}}>
                                            <p>Nom : </p>
                                            <div style={{display: 'flex'}}>
                                                <input
                                                style={{flex: 1, padding: 5, textAlign: 'center'}}
                                                onChange={async (e) => {
                                                    projet[15] = (e.target.value)
                                                    await setAff(!aff)
                                                }}
                                                value={projet[15] === null?"":rescueHtml(projet[15])}
                                                />
                                            </div>
                                        </div>
                                        <div style={{padding: 5, borderLeft: '1px solid '+blue}}>
                                            <p>Téléphone : </p>
                                            <input
                                            style={{padding: 5, textAlign: 'center'}}
                                            onChange={async (e) => {
                                                if (e.target.value) {
                                                    projet[17] = (e.target.value)
                                                    await setAff(!aff)
                                                }
                                            }}
                                            value={projet[17] === null?"":rescueHtml(projet[17])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/****/}

                                {/* Ligne 13: Technicien référent (Mail) */}
                                <div style={{display: 'flex', borderTop: '1px solid '+blue}}>
                                    <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175}}>
                                        <p>Technicien référent (Mail)</p>
                                    </div>
                                    <div style={{display: 'flex', flex: 1, borderLeft: '1px solid '+blue}}>
                                        <input
                                        style={{flex: 1, padding: 5}}
                                        onChange={async (e) => {
                                            projet[18] = (e.target.value)
                                            await setAff(!aff)
                                        }}
                                        value={projet[18] === null?"":rescueHtml(projet[18])}
                                        />
                                    </div>
                                </div>
                                {/****/}
                            </div>
                        </div>
                        :null}
                        {/**************************************************************/}
                        

                        {/****************************** GANTT ********************************/}
                        {gantt?
                        <div>
                            <Gantt user={props.user} id_projet={id}/>
                        </div>
                        :null}
                        {/**************************************************************/}


                        {/****************************** Moyens mis en œuvre ********************************/}
                        {moyens?
                        <div>
                            {/* Ligne 14: Ressources humaines */}
                            <div style={{display: 'flex', margin: 10, borderRadius: 5}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175, borderRadius: 5}}>
                                    <p>Ressources humaines</p>
                                </div>
                                <div style={{display: 'flex', flex: 1, backgroundColor: 'white', borderRadius: 5}}>
                                    <textarea
                                    style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                    onChange={async (e) => {
                                        projet[19] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[19] = projet[19]?projet[19].trim():'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[19] === null?"":rescueHtml(projet[19])}
                                    />
                                </div>
                            </div>
                            {/****/}
                            
                            {/* Ligne 15: Etudes */}
                            <div style={{display: 'flex', margin: 10, borderRadius: 5}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175, borderRadius: 5}}>
                                    <p>Études</p>
                                </div>
                                <div style={{display: 'flex', flex: 1, backgroundColor: 'white', borderRadius: 5}}>
                                    <textarea
                                    style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                    onChange={async (e) => {
                                        projet[20] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[20] = projet[20]?projet[20].trim():'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[20] === null?"":rescueHtml(projet[20])}
                                    />
                                </div>
                            </div>
                            {/****/}
                            
                            {/* Ligne 16: Outils, matériels */}
                            <div style={{display: 'flex', margin: 10, borderRadius: 5}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175, borderRadius: 5}}>
                                    <p>Outils, matériels</p>
                                </div>
                                <div style={{display: 'flex', flex: 1, backgroundColor: 'white', borderRadius: 5}}>
                                    <textarea
                                    style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                    onChange={async (e) => {
                                        projet[21] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[21] = projet[21]?projet[21].trim():'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[21] === null?"":rescueHtml(projet[21])}
                                    />
                                </div>
                            </div>
                            {/****/}
                            
                            {/* Ligne 17: Investissements (moyens internes/externes) */}
                            <div style={{display: 'flex', margin: 10, borderRadius: 5}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175, borderRadius: 5}}>
                                    <p>Investissements (moyens internes/externes)</p>
                                </div>
                                <div style={{display: 'flex', flex: 1, backgroundColor: 'white', borderRadius: 5}}>
                                    <textarea
                                    style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                    onChange={async (e) => {
                                        projet[22] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[22] = projet[22]?projet[22].trim():'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[22] === null?"":rescueHtml(projet[22])}
                                    />
                                </div>
                            </div>
                            {/****/}
                            
                            {/* Ligne 18: Incidences organisationnelle et administrative */}
                            <div style={{display: 'flex', margin: 10, borderRadius: 5}} className='shadow-md rounded-md'>
                                <div style={{flex: 0.3, padding: 10, backgroundColor: greyChapter, alignItems: 'center', minWidth: 175, borderRadius: 5}}>
                                    <p>Incidences organisationnelle et administrative</p>
                                </div>
                                <div style={{display: 'flex', flex: 1, backgroundColor: 'white', borderRadius: 5}}>
                                    <textarea
                                    style={{flex: 1, padding: 2, margin: 5, fontSize: 16, minHeight: 100}}
                                    onChange={async (e) => {
                                        projet[23] = (e.target.value)
                                        await setAff(!aff)
                                    }}
                                    onBlur={async () => {
                                        projet[23] = projet[23]?projet[23].trim():'';
                                        await setAff(!aff);
                                    }}
                                    value={projet[23] === null?"":rescueHtml(projet[23])}
                                    />
                                </div>
                            </div>
                            {/****/}
                        </div>
                        :null}
                        {/**************************************************************/}



                        {/****************************** Incidences financières ********************************/}
                        {incidences?
                        <div>
                            {/* Tableau des incidences financieres */}
                            <div style={{display: 'flex', marginTop: 20, backgroundColor: blue, color: 'white'}}>
                                <div style={{flex: 1, padding: 5}}>
                                    <p></p>
                                </div>
                                {annees.map((annee, i) =>
                                <div key={i} style={{flex: 0.5, borderLeft: '1px solid white'}}>
                                    <p>{annee}</p>
                                </div>)}
                                <div style={{flex: 0.5, borderLeft: '1px solid white', fontWeight: 'bold', backgroundColor: '#2980b9'}}>
                                    <p>Total</p>
                                </div>
                            </div>
                            <div style={{flex: 1, backgroundColor: 'white', border: '1px solid '+blue, borderRadius: 2}}>
                                {incidences_financieres.length !== 0?
                                incidences_financieres.map((line, i) =>
                                <div key={i} style={i === 0?{display: 'flex'}:line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{display: 'flex', borderTop: '1px solid '+blue}:{display: 'flex', borderTop: '1px solid '+blue}}>
                                    {line.map((val, j) =>
                                        j === 0?
                                        <div key={j} style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{flex: 1, padding: 5, minWidth: 50, backgroundColor: greyChapter}:{flex: 1, padding: 5, minWidth: 50}}>
                                            {val && typeof val === 'string' ?
                                            <p style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{fontWeight: 'bold', fontSize: 15}:{textAlign: 'right', fontSize: 15}}>{val.replace('_', "'")}</p>
                                            :null}
                                        </div>
                                        :
                                        j > 1?
                                        <div key={j} style={{flex: 0.5, display: 'flex', fontSize: 14, borderLeft: '1px solid '+blue, minWidth: 50}}>
                                            {val[0] === 'Total' || line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?
                                            <input
                                            type="float"
                                            style={
                                                val[0] === 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                                                {flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50, backgroundColor: '#BABABA', fontWeight: 'bold'}
                                                :
                                                val[0] === 'Total'?
                                                {flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50, backgroundColor: greyChapter, fontWeight: 'bold'}
                                                :
                                                {flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50, backgroundColor: greyChapter}
                                            }
                                            value={val[1] === null || val[1] === '' || val[1] === 0?'':addSpace(Math.round(val[1]*100)/100)}
                                            disabled
                                            />
                                            :
                                            <input
                                            type="float"
                                            style={{flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50}}
                                            onBlur={async (e) => {
                                                if (e.target.value === '0' || e.target.value === 0) {
                                                    await updateObjetLine(line[0], val[0], '0')
                                                    await setAff(!aff)
                                                }
                                                else {
                                                    e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                                    if (e.target.value !== '' && (parseFloat(e.target.value)))
                                                    {
                                                        e.target.value = parseFloat(e.target.value)
                                                        await updateObjetLine(line[0], val[0], parseFloat(Math.round(e.target.value * 100) / 100).toString())
                                                        await setAff(!aff)
                                                    }
                                                }
                                                e.target.value = ''
                                            }}
                                            placeholder={val[1] === null || val[1] === '' || val[1] === '0' || val[1] === 0?'':addSpace(Math.round(val[1]*100)/100)}
                                            />
                                            }
                                        </div>:null
                                    )}
                                </div>)
                                :null}
                            </div>
                            {/****/}
                        </div>
                        :null}
                        {/**************************************************************/}



                        <ModalPilotage
                            modalVisible={modalPilotage}
                            setModalVisible={setModalPilotage}
                            objet={objetPilotage}
                            user={props.user}
                        />

                        {/****************************** Pilotage ********************************/}
                        {pilotage?
                        <div>
                            <div style={{flex: 1, display: 'flex', borderTopLeftRadius: 2}}>
                                <div style={{flex: 0.15, minWidth: 20, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <Trash width="15" />
                                </div>
                                <div style={{flex: 0.6, minWidth: 90, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{}}>Date</p>
                                </div>
                                <div style={{flex: 1, minWidth: 180, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{}}>Instance réunie</p>
                                </div>
                                <div style={{flex: 1.5, minWidth: 300, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{}}>Relevé de décisions</p>
                                </div>
                                <div style={{flex: 1.5, minWidth: 300, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{}}>Action suivante</p>
                                </div>
                                <div style={{flex: 0.6, minWidth: 90, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{}}>Date limite de l'action</p>
                                </div>
                                <div style={{flex: 1, minWidth: 130, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <p style={{}}>Responsable de l'action</p>
                                </div>
                            </div>

                            <div style={{flex: 1, height: heightObject, borderBottomRightRadius: 3, borderBottomLeftRadius: 3}}>
                                {pilotage_projet.map((line, i) =>
                                
                                <div key={i} style={{flex: 1, display: 'flex', marginTop: 5, backgroundColor: line[9] == 1? green:line[9] == 0 && (new Date(line[6])) <= (new Date()) ? red : '#ffffff'}} className='shadow-md'>
                                    
                                    <button
                                    style={{flex: 0.15, minWidth: 20, justifyContent: 'center', alignItems: 'center', display: 'flex'}}
                                    onClick={async () => {
                                        await setDialogModalVisible(true);
                                        await setPilotageLineSelected(line[0]);
                                    }}
                                    >
                                        <Trash width="16" />
                                    </button>
                                    

                                    {/* Date */}
                                    <button
                                    onClick={(e) => {
                                        setModalPilotage(true);
                                        setObjetPilotage(line);
                                    }}
                                        style={{display: 'flex', flex: 0.6, minWidth: 90, alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
                                    >
                                        <p>{line[2]?line[2].split('-')[2]+'/'+line[2].split('-')[1]+'/'+line[2].split('-')[0]:null}</p>
                                    </button>



                                    {/* Instance reunie */}
                                    <button
                                    onClick={(e) => {
                                        setModalPilotage(true);
                                        setObjetPilotage(line);
                                    }}
                                        style={{display: 'flex', flex: 1, minWidth: 180, alignItems: 'center', justifyContent: 'center'}}
                                    >
                                        <p>{rescueHtml(line[3])}</p>
                                    </button>


                                    {/* Releve de decision */}
                                    <button
                                    onClick={(e) => {
                                        setModalPilotage(true);
                                        setObjetPilotage(line);
                                    }}
                                        style={{display: 'flex', flex: 1.5, minWidth: 300, alignItems: 'center', justifyContent: 'center'}}
                                    >
                                        <p>
                                            {
                                            rescueHtml(line[4])
                                            .split('\n')[0] // Prendre seulement la première ligne
                                            .slice(0, 35) + (rescueHtml(line[4]).split('\n')[0].length > 35 ? '...' : '')
                                            }
                                        </p>
                                    </button>


                                    {/* Action suivante */}
                                    <button
                                    onClick={(e) => {
                                        setModalPilotage(true);
                                        setObjetPilotage(line);
                                    }}
                                        style={{display: 'flex', flex: 1.5, minWidth: 300, alignItems: 'center', justifyContent: 'center'}}
                                    >
                                        <p>
                                            {
                                            rescueHtml(line[5])
                                            .split('\n')[0] // Prendre seulement la première ligne
                                            .slice(0, 35) + (rescueHtml(line[5]).split('\n')[0].length > 35 ? '...' : '')
                                            }
                                        </p>
                                    </button>


                                    {/* Date limite */}
                                    <button
                                    onClick={(e) => {
                                        setModalPilotage(true);
                                        setObjetPilotage(line);
                                    }}
                                        style={{display: 'flex', flex: 0.6, minWidth: 90, alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
                                    >
                                        <p>{line[6]?line[6].split('-')[2]+'/'+line[6].split('-')[1]+'/'+line[6].split('-')[0]:null}</p>
                                    </button>

                                    
                                    {/* Responsable de saisie */}
                                    <button
                                    onClick={(e) => {
                                        setModalPilotage(true);
                                        setObjetPilotage(line);
                                    }}
                                        style={{display: 'flex', flex: 1, minWidth: 130, alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
                                    >
                                        <p>{line[7]}</p>
                                    </button>

                                </div>
                                )}
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}}></div>
                                    <div style={{marginTop: 10, marginBottom: 10}}>
                                    <button
                                        className='shadow-md rounded-md'
                                        style={{backgroundColor: purple, color: 'white', fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                                        onClick={async () => {
                                            addLinePilotage();
                                        }}
                                    >
                                        <Add width="12" />
                                        <p style={{marginLeft: 10}}>Ajouter une ligne</p>
                                    </button>
                                    </div>
                                    <div style={{flex: 1}}></div>
                                </div>
                            </div>
                            
                        </div>
                        :null}

                        {/**************************************************************/}
                    </div>
                    :
                    <p style={{margin: 20, fontSize: 20, color: 'orange'}}>Fiche Projet non trouvée</p>}

                </div>
            </Access>
        </div>
	);
}

export default FicheProjet;
