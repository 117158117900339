import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import MatriculeTableauBord from '../Graph/MatriculeTableauBord';
import { rescueHtml } from '../components/Tools.js';

const Page2DepensesFct = (props) => {
    const blue = '#069BD3';

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={Logo} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25, display: 'flex', alignItems: 'center'}}>
                <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, height: '80%'}}>
                <div style={{flex: 1, display: 'flex', marginLeft: 25, marginRight: 25, marginTop: 15}}>
                {props.objet.map((annee) => 
                parseInt(annee) == parseInt(props.year)?
                annee[1].map((line, j) =>
                    line[0] === '2014M57REELDEBFCT' || line[0] === '2014M57011' || line[0] === '2014M57012'?
                    <div key={j} style={{flex: 1}}>
                        {console.log(line)}
                        <MatriculeTableauBord user={props.user} annee={annee[0]} intitule={line[1]['intitule'] + (line[1]['compte'] != ''?' ('+line[1]['compte']+')':'')} grille={annee[1][j]} color={j===1?'#8e44ad':j===2?'#27ae60':'#c0392b'}/>
                    </div>
                    :null)
                :null)}
                </div>
                <div style={{flex: 1, display: 'flex', marginLeft: 25, marginRight: 25, marginTop: 5}}>
                {props.objet.map((annee) => 
                parseInt(annee) == parseInt(props.year)?
                annee[1].map((line, j) =>
                    line[0] === '2014M5765 - 2014M57656' || line[0] === '2014M57014' || line[0] === '2014M5766'?
                    <div key={j} style={{flex: 1}}>
                        <MatriculeTableauBord user={props.user} annee={annee[0]} intitule={line[1]['intitule'] + (line[1]['compte'] != ''?' ('+line[1]['compte']+')':'')} grille={annee[1][j]} color={j===4?'#e67e22':j===5?'#f1c40f':'#3498db'}/>
                    </div>
                    :null)
                :null)}
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
};

export default Page2DepensesFct;
