import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import { addSpace, rescueHtml } from '../components/Tools';


const Page33RestitutionProsp = (props) =>
{
    const blue = '#069BD3'
    const smoothGrey = '#f4f4f4'

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={Logo} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '0px solid '+blue, marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>Hypothèse n°{props.hypothese.num} - {props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1}}>
                <div style={{flex: 3.5, marginLeft: 25, marginRight: 25, borderRadius: 2}}>
                    <div style={{flex: 1, backgroundColor: blue, color: 'white', display: 'flex'}}>

                        <div style={{flex: 3.5, justifyContent: 'center', textAlign: 'center'}}>
                            <p style={{fontSize: 12}}>Montants en €</p>
                        </div>


                        {/* Affichage des années */}
                        {props.annees?
                        props.annees.map((an, j) =>
                        (props.evolution && j > 0) || !props.evolution?
                        <div key={j} style={{flex: 1, justifyContent: 'center', textAlign: 'center'}}>
                            <p style={{fontSize: 12}}>{an}</p>
                        </div>
                        :null
                        ):null}

                        {/* Affichage du titre de la colonne % annuel moyen */}
                        {!props.evolution?
                        <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: '#7f8c8d'}}>
                            <p style={{fontSize: 12}}>% annuel moy.</p>
                        </div>
                        :null}

                    </div>


                    {/* Parcourt le tableau en lignes */}
                    {props.restitution?
                    props.restitution.map((line, i) => 
                    <div key={i} style={line[0].chapitre === '1'?{flex: 1, borderTop: '1px solid white', display: 'flex'}:i%2?{flex: 1, borderTop: '1px solid white', display: 'flex', backgroundColor: smoothGrey}:{flex: 1, borderTop: '1px solid white', display: 'flex'}}>
                        
                        {/* Affichage de la colonne intitulé */}
                        <div style={{flex: 3.5, justifyContent: 'center'}}>
                                <p style={line[0].chapitre === '1'?{fontSize: 11, paddingLeft: 2, color: 'white', backgroundColor: blue}
                            :
                                line[0].indentation === '2'?
                                    {fontSize: 9, paddingLeft: (line[0].indentation + 1)*2}
                                :
                                    {fontSize: 11, paddingLeft: (line[0].indentation + 1)*2}
                            }>{line[0].nom}</p>
                        </div>


                        {/* Affichage des colonnes */}
                        {line.map((col, l) =>
                        l !== 0?
                        <div key={l} style={line[0].chapitre === '1'?{flex: 1, justifyContent: 'center', borderLeft: '1px solid white', backgroundColor: blue}:{flex: 1, justifyContent: 'center', borderLeft: '1px solid white'}}>
                            <p style={line[0].chapitre === '1'?
                                {fontSize: 10, textAlign: 'right', paddingRight: 2, color: 'white'}
                            :
                                {fontSize: 9, textAlign: 'right', paddingRight: 2}}>
                                {props.evolution && (Math.round(col[1]*100)/100) > 0?'+':null}
                                {(props.evolution && col[1] !== 0) || (!props.evolution && col[1] !== 0)?
                                    line[0].matricule !== "ProspInv130"
                                    && line[0].matricule !== "M4ProspInv115"
                                    && line[0].matricule !== "M41ProspInv115"
                                    && line[0].matricule !== "M43ProspInv115"
                                    && line[0].matricule !== "M49ProspInv115"?
                                        props.evolution?addSpace((Math.round(col[1]*100)/100)):addSpace(Math.round(col[1]), true)
                                        :
                                        addSpace(parseFloat(Math.round(col[1]*100)/100), true)
                                :
                                    '.'
                                }
                                {props.evolution && col[1] !== 0?' %':''}
                                
                                {(line[0].matricule == "ProspInv130"
                                || line[0].matricule == "M4ProspInv115"
                                || line[0].matricule == "M41ProspInv115"
                                || line[0].matricule == "M43ProspInv115"
                                || line[0].matricule == "M49ProspInv115")
                                && col[1] !== 0?
                                " an(s)":null}

                                {(line[0].matricule == "ProspInv135"|| line[0].matricule == "ProspInv140"
                                || line[0].matricule == "M4ProspInv120"|| line[0].matricule == "M4ProspInv125"
                                || line[0].matricule == "M41ProspInv120"|| line[0].matricule == "M41ProspInv125"
                                || line[0].matricule == "M43ProspInv120"|| line[0].matricule == "M43ProspInv125"
                                || line[0].matricule == "M49ProspInv120"|| line[0].matricule == "M49ProspInv125"
                                ) && col[1] !== 0?
                                " %":null}

                                {line[0].matricule == "ProspInv145"
                                || line[0].matricule == "M4ProspInv130"
                                || line[0].matricule == "M41ProspInv130"
                                || line[0].matricule == "M43ProspInv130"
                                || line[0].matricule == "M49ProspInv130"
                                && col[1] !== 0?
                                " jour(s)":null}
                            </p>
                        </div>
                        :null)}


                        {/* Affichage de la colonne % annul moyen */}
                        {!props.evolution?
                        <div style={line[0].chapitre === '1'?{flex: 1, borderLeft: '1px solid white', backgroundColor: '#7f8c8d'}:{flex: 1, borderLeft: '1px solid white'}}>
                            <p style={
                            line[0].chapitre === '1'?
                                {fontSize: 10, textAlign: 'right', paddingRight: 2, color: 'white'}
                            :
                                line[0].indentation === '2'?
                                    {fontSize: 9, textAlign: 'right', paddingRight: 2, fontStyle: 'italic'}
                                :
                                    {fontSize: 10, textAlign: 'right', paddingRight: 2}
                            }>
                                {line[1][1] != 0 && Math.pow( (line[line.length-1][1]/line[1][1]) , (1/(props.annees.length-1)) )?
                                Math.round((Math.pow( (line[line.length-1][1]/line[1][1]) , (1/(props.annees.length-1)) ) - 1) * 10000) / 100+'%'
                                :'.'}
                            </p>
                        </div>
                        :null}


                    </div>
                    )
                    :null}
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
}
  
  export default Page33RestitutionProsp;