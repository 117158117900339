import React, { useEffect, useState } from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { rescueHtml } from '../components/Tools.js';
import Logo from '../Img/Logo_projets&finances.png';

Font.register({
    family: 'Montserrat',
    src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2'
});

const Page1_PDF_Accueil = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (props.user && props.date) {
            setIsLoaded(true);
        }
    }, [props.user, props.date]);

    if (!isLoaded) {
        return null;
    }

    return (
        <Page 
            size="A4" 
            style={{backgroundColor: 'white', width: '100%', height: '100%', padding: 20}} 
            orientation="landscape"
        >
            {/* Header */}
            <View style={{height: '20%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                {props.logo && (
                <Image style={{objectFit: 'contain', borderRadius: 5}} src={props.logo} alt="Logo" />
                )}
                <View style={{flex: 1}}></View>
            </View>

            {/* Contenu Principal */}
            <View style={{height: '60%', alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{ fontSize: 22, textAlign: 'center'}}>
                    {props.user.nom_organisme ? rescueHtml(props.user.nom_organisme) : ''}
                </Text>
                <Text style={{fontSize: 13, marginTop: 10, textAlign: 'center'}}>
                    {props.user.nom_budget ? rescueHtml(props.user.nom_budget) : ''}
                </Text>
                <Text style={{fontSize: 20, color: '#069BD3', marginTop: 20, textAlign: 'center'}}>
                    Synthèse globale des projets
                </Text>
            </View>

            {/* Footer */}
            <View style={{position: 'absolute', bottom: 20, left: 20}}>
                <Text style={{fontSize: 8}}>{props.date ? props.date : ''}</Text>
            </View>
            
            <View style={{position: 'absolute', bottom: 20, right: 20}}>
                <Image style={{width: 200, borderRadius: 5}} src={Logo} />
            </View>
        </Page>
    );
};

export default Page1_PDF_Accueil;
