import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import Logo from './Img/Logo_projets&finances.png';
import Access from "./components/Access";
import ClockLoader from "react-spinners/ClockLoader";
import { escapeHtml } from './components/Tools';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from 'axios';
import qs from 'qs';

const Login = () =>
{
    const [response, setResponse] = useState(false);
    const [mise_a_jour, setMise_a_jour] = useState(undefined);

    const purple = '#6571FE'
    const green = '#59B44A'


    function validEmail(email) {
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(email.match(mailformat)) {
            return true;
        }
        else {
            NotificationManager.warning('Mauvais format de mail', '', 2000);
            return false;
        }
    }


    useEffect(() => {
        axios.post('https://app-slfinance.fr/api/application.php', qs.stringify({'get': true}))
        .then(async function (res) {
            setMise_a_jour(res.data.mise_a_jour);
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])

    // Resultat du formulaire
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        if(data.email === "") {
            NotificationManager.warning('Champ manquant', '', 2000);
        }
        else {
            if (validEmail(data.email))
            {
                const url = 'https://app-slfinance.fr/api/auth.php';
                const params = {
                    'generateToken': true,
                    'email': escapeHtml(data.email),
                    'url': window.location.host
                };
                axios.post(url, qs.stringify(params))
                .then(async function (res) {
                    if (res.data.status === 1) {
                        await NotificationManager.success(res.data.status_message, '', 5000);
                        await setResponse(res.data.status_message)
                    }
                    else {
                        await NotificationManager.warning("Erreur "+res.data.code+": ["+res.data.status_message+"]", '', 5000);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
    };

	return (
        <Access needConnection={false}>
            <NotificationContainer />

            <div style={{display: 'flex', paddingTop: 100}}>
                <div style={{flex: 1}}></div>

                <div style={{justifyContent: 'center', minWidth: 450, backgroundColor: 'white'}}>
                    <div className='shadow-md' style={{flex: 1, borderRadius: 3, padding: 5}}>
                        <div style={{textAlign: 'center'}}>
                            <img style={{ height: 70, margin: 15 }} src={Logo} alt="Logo" />

                            {mise_a_jour && mise_a_jour === 'false'?
                            <div style={{display: 'flex', margin: 50}}>
                                <form onSubmit={handleSubmit(onSubmit)} style={{flex: 1}}>
                                    <div style={{margin: 25, textAlign: 'left'}}>
                                        <p style={{fontSize: 15}}>Adresse email</p>
                                        <input style={{width: 400, marginTop: 10, border: '1px solid #E9ECEF', borderRadius: 5, padding: 10}} placeholder='Email' type='email' {...register("email")} />
                                    </div>

                                    {response === false?
                                    <div style={{margin: 10}}>
                                        <input className='shadow-md p-2 rounded-md' style={{backgroundColor: green, color: 'white', fontSize: 14, fontSize: 14}} type="submit" value="Se connecter" />
                                    </div>
                                    :
                                    <div style={{margin: 10}}>
                                        <p style={{fontSize: 15, color: green}}>{response}</p>
                                        <p style={{fontSize: 15, color: green}}>Cliquez sur le lien reçu par mail</p>
                                    </div>
                                    }
                                </form>
                            </div>
                            :
                            mise_a_jour && mise_a_jour === 'true'?
                            <div style={{margin: 25}}>
                                <p style={{fontSize: 20, margin: 25, fontWeight: 'bold', color: purple}}>Maintenance</p>
                                <p>Notre application est actuellement en maintenance pour améliorer votre expérience.</p>
                                <p>Merci de votre patience, nous serons de retour bientôt !</p><br/>
                                <p>L'équipe de Strategies Locales est à votre écoute si besoin</p>
                                <p>contact@strategies-locales.fr</p>
                                <div style={{flex: 1, margin: 25, display: 'flex'}}>
                                    <div style={{flex: 1}}></div>
                                    <ClockLoader color={purple} />
                                    <div style={{flex: 1}}></div>
                                </div>
                            </div>
                            :null}
                        </div>
                    </div>
                </div>

                <div style={{flex: 1}}></div>
            </div>
        </Access>
	);
}

export default Login;
