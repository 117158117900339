import { addSpace, rescueHtml } from '../components/Tools.js';
import React, { useEffect, useState } from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import Logo from '../Img/Logo_projets&finances.png';
import Checked from '../Img/icon-checked-blue.png';

const Page2_PDF_DonneesGenerales = (props) => {
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    if (!isLoaded) {
        return null;
    }

    return (
        <Page 
            size="A4" 
            style={{backgroundColor: 'white', width: '100%', height: '100%', padding: 20}} 
            orientation="landscape"
        >
            {/* Header - Logo */}
            <View style={{flexDirection: 'row'}}>
                <View style={{flex: 0.5, flexDirection: 'row', height: 75}}>
                    {props.logo && <Image style={{maxHeight: 75, borderRadius: 5}} src={props.logo} alt="Logo" />}
                </View>
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                    <Text style={{color: blue, fontSize: 18}}>{props.nom?props.nom:''}</Text>
                    <Text style={{color: blue, fontSize: 12, marginLeft: 5, fontFamily: 'Helvetica-Oblique'}}>{props.index !== null ? '('+props.index+'/'+props.indexTotal+')' : ''}</Text>
                </View>
                <View style={{flex: 0.5, flexDirection: 'row', fontSize: 12}}>
                    <Text style={{color: 'grey'}}>Synthèse globale des projets</Text>
                </View>
            </View>

            {/* Container */}
            <View style={{flex: 1, marginTop: 20, marginBottom: 40}}>
                
                {/* Entete */}
                <View style={{flexDirection: 'row', marginRight: 10, justifyContent: 'center', alignItems: 'center', fontSize: 9, fontFamily: 'Helvetica-Bold'}}>
                    <View style={{flex: 1}}>
                    </View>
                    <View style={{flex: 0.1, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Hypothèses</Text>
                    </View>
                </View>
                <View style={{flexDirection: 'row', fontSize: 9, fontFamily: 'Helvetica-Bold'}}>
                    <View style={{flex: 0.1, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>N°</Text>
                    </View>
                    <View style={{flex: 0.2, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                        <Text>État</Text>
                    </View>
                    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Intitulé</Text>
                    </View>
                    <View style={{flex: 0.3, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Date de fin</Text>
                        <Text>prévisionnelle</Text>
                    </View>
                    <View style={{flex: 0.8, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Politique publique</Text>
                    </View>
                    <View style={{flex: 0.2, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Priorité</Text>
                    </View>
                    <View style={{flex: 0.4, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>Montant</Text>
                    </View>
                    <View style={{flex: 0.1, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>H1</Text>
                    </View>
                    <View style={{flex: 0.1, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>H2</Text>
                    </View>
                    <View style={{flex: 0.1, justifyContent: 'center', alignItems: 'center'}}>
                        <Text>H3</Text>
                    </View>
                </View>

                <View style={{flex: 1}}>
                {props.projets.map((projet, i) => (
                    projet && (
                    <View
                        key={i}
                        style={{flexDirection: 'row', marginTop: 4, border: '1px solid ' + greyChapter, borderRadius: 5, fontSize: 9, height: 37}}
                    >
                        
                        {/* Numéro du projet */}
                        <View style={{flex: 0.1, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{}}>{projet[2] ? projet[2] : ''}</Text>
                        </View>

                        {/* État / couleur */}
                        <View style={{flex: 0.2, justifyContent: 'center', alignItems: 'center'}}>
                            <View style={{
                                border: '1px solid ' + (projet[27] ? projet[27] : '#ffffff'), 
                                backgroundColor: projet[27] ? projet[27] : '#ffffff', 
                                borderRadius: '50%', 
                                width: 25, 
                                height: 25
                            }}></View>
                        </View>

                        {/* Intitulé */}
                        <View style={{flex: 1, justifyContent: 'center'}}>
                            <Text style={{padding: 5, overflow: 'hidden', fontFamily: 'Helvetica-Bold'}}>{projet[3] ? rescueHtml(projet[3]) : ''}</Text>
                        </View>

                        {/* Date de fin previsionnelle GANTT */}
                        <View style={{flex: 0.3, justifyContent: 'center', alignItems: 'center'}}>
                            <Text>{projet['end'] ? (projet['end'].split('-')[2] + '/' + projet['end'].split('-')[1] + '/' + projet['end'].split('-')[0]) : ''}</Text>
                        </View>

                        {/* Politique publique */}
                        <View style={{flex: 0.8, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{padding: 5, overflow: 'hidden'}}>{projet[7] ? projet[7].replace(/\|/g, ' ') : ''}</Text>
                        </View>

                        {/* Priorité */}
                        <View style={{flex: 0.2, justifyContent: 'center', alignItems: 'center'}}>
                            <Text>{projet[6] ? projet[6] : ''}</Text>
                        </View>

                        {/* Montant */}
                        <View style={{flex: 0.4, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{overflow: 'hidden'}}>{projet[9] ? addSpace(projet[9], true) + ' €' : ''}</Text>
                        </View>

                        {/* hypothèse 1 */}
                        <View style={{flex: 0.1, alignItems: 'center', justifyContent: 'center'}}>
                            {projet[24] === '0'?
                            <View style={{width: 10, height: 10, border: '1px solid grey', borderRadius: 2}}></View>
                            :
                            <Image style={{width: 10}} src={Checked} />
                            }
                        </View>

                        {/* hypothèse 2 */}
                        <View style={{flex: 0.1, alignItems: 'center', justifyContent: 'center'}}>
                        {projet[25] === '0'?
                            <View style={{width: 10, height: 10, border: '1px solid grey', borderRadius: 2}}></View>
                            :
                            <Image style={{width: 10}} src={Checked} />
                            }
                        </View>

                        {/* hypothèse 3 */}
                        <View style={{flex: 0.1, alignItems: 'center', justifyContent: 'center'}}>
                        {projet[26] === '0'?
                            <View style={{width: 10, height: 10, border: '1px solid grey', borderRadius: 2}}></View>
                            :
                            <Image style={{width: 10}} src={Checked} />
                            }
                        </View>

                    </View>
                    )
                ))}
                </View>
            </View>
            
            {/* Footer */}
            <View style={{position: 'absolute', bottom: 20, left: 20}}>
                <Text style={{fontSize: 8}}>{props.date}</Text>
            </View>

            <View style={{position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-25%)'}}>
                <Text style={{fontSize: 8}}>page : {props.pagination} / {props.countPageTotal}</Text>
            </View>

            <View style={{position: 'absolute', bottom: 20, right: 20}}>
                <Image style={{width: 150, borderRadius: 5}} src={Logo} />
            </View>
            
        </Page>
    );
}

export default Page2_PDF_DonneesGenerales;