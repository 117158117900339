import React, { useEffect, useState } from 'react';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import Dialog from "./components/Dialog";
import BounceLoader from "react-spinners/BounceLoader";
import { ReactComponent as Check } from './Img/icon-check.svg';
import axios from 'axios';
import qs from 'qs';
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import { addSpace, getyears } from "./components/Tools";

const CalculEmpruntsAuto = (props) =>
{
    const green = '#59B44A';
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';
    const smoothGrey = '#f4f4f4';
    const purple = '#6571FE';

    const [aff, setAff] = useState(false);
    const [h1, setH1] = useState(true);
    const [h2, setH2] = useState(false);
    const [h3, setH3] = useState(false);
    const [years, setYears] = useState([]);
    
    const [txi, setTxi] = useState([]);
    const [txa, setTxa] = useState([]);
    const [duree, setDuree] = useState([]);
    const [dureeDiff, setDureeDiff] = useState([]);
    const [resClo, setResClo] = useState([]);
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    
    function callbackDialog(ret) {
        if (ret === true) {
            setLoading(true);
            validation();
        }
    }

    
    useEffect(() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            // Récupération des années de prospective
            getyears(props.user.id_budget, 'P')
            .then(async (res) => {
                await res.shift()
                await setYears(res)

                // Initialisation des variables du tableau
                await res.forEach((element, index) => {
                    txi[index] = [];
                    txi[index][0] = element;
                    txi[index][1] = 3.5;

                    txa[index] = [];
                    txa[index][0] = element;
                    txa[index][1] = 3.5;

                    duree[index] = [];
                    duree[index][0] = element;
                    duree[index][1] = 20;

                    dureeDiff[index] = [];
                    dureeDiff[index][0] = element;
                    dureeDiff[index][1] = 0;

                    resClo[index] = [];
                    resClo[index][0] = element;
                    resClo[index][1] = 30;
                });
                await setTxi(txi)
                await setTxa(txa)
                await setDuree(duree)
                await setDureeDiff(dureeDiff)
                await setResClo(resClo)
                await setAff(!aff)
            })
        }
        
    }, [props.user])


    async function update(line, annee, val) {
        if (line === 'txi') {
            await txi.forEach((element, i) => {
                if (parseInt(element[0]) >= parseInt(annee)) {
                    txi[i][1] = val;
                }
            });
            await setTxi(txi)
        }
        else if (line === 'txa') {
            await txa.forEach((element, i) => {
                if (parseInt(element[0]) >= parseInt(annee)) {
                    txa[i][1] = val;
                }
            });
            await setTxa(txa)
        }
        else if (line === 'duree') {
            await duree.forEach((element, i) => {
                if (parseInt(element[0]) >= parseInt(annee)) {
                    if (val > dureeDiff[i][1]) {
                        duree[i][1] = val;
                    }
                    else {
                        NotificationManager.warning("Durée de l'emprunt inférieure ou égale à la durée du différé", '', 5000);
                    }
                }
            });
            await setDuree(duree)
        }
        else if (line === 'dureeDiff') {
            await dureeDiff.forEach((element, i) => {
                if (parseInt(element[0]) >= parseInt(annee)) {
                    if (val < duree[i][1]) {
                        dureeDiff[i][1] = val;
                    }
                    else {
                        NotificationManager.warning("Durée du différé supérieure ou égale à la durée de l'emprunt", '', 5000);
                    }
                }
            });
            await setDureeDiff(dureeDiff)
        }
        else if (line === 'resClo') {
            await resClo.forEach((element, i) => {
                if (parseInt(element[0]) >= parseInt(annee)) {
                    resClo[i][1] = val;
                }
            });
            await setResClo(resClo)
        }
        setAff(!aff);
    }


    async function validation()
    {
        var tab = {};
        for (let i = 0; years[i]; i++) {
            const year = years[i];
            tab[i] = {
                'annee': year,
                'txi': txi[i][1],
                'txa': txa[i][1],
                'duree': duree[i][1],
                'dureediff': dureeDiff[i][1],
                'resclo': resClo[i][1],
            };
        }
        const params = {
            'calcul_emprunts_auto': true,
            'id_budget': props.user.id_budget,
            'hypotheses': {'h1': h1, 'h2': h2, 'h3': h3},
            'tab': tab
        }
        
        await axios.post("https://app-slfinance.fr/api/emprunts.php", qs.stringify(params))
        .then((r) => {
            console.log(r.data)
            setLoading(false);
            NotificationManager.success("Emprunts d'équilibre créés", '', 3000);
        })
        .catch((err) => {
            console.log(err)
        })
    }


	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                
                <Navbar user={props.user} />

                <Dialog
                    setModalVisible={setDialogModalVisible}
                    modalVisible={dialogModalVisible}
                    callbackDialog={callbackDialog}
                    message={"En validant, vous allez écraser les emprunts déjà saisis manuellement pour les hypothèses séléctionnées"}
                />

                {props.user?
                <div style={{display: 'flex'}}>

                    <div style={{flex: 1, margin: 20, display: 'flex', textAlign: 'center'}}>
                        <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Calcul automatique des emprunts d'équilibre</p>
                    </div>

                    <div style={{flex: 1, textAlign: 'center'}}>
                        <p style={{flex: 1, margin: 20}}>{props.user.nom_budget}</p>
                    </div>

                    <div style={{flex: 1, margin: 20, display: 'flex', textAlign: 'center'}}>
                        <p>Appliquer à :</p>
                        <p style={{flex: 1}}>{<input type="checkbox" checked={h1 === false?false:true} onChange={() => setH1(!h1)}/>} H1</p>
                        <p style={{flex: 1}}>{<input type="checkbox" checked={h2 === false?false:true} onChange={() => setH2(!h2)}/>} H2</p>
                        <p style={{flex: 1}}>{<input type="checkbox" checked={h3 === false?false:true} onChange={() => setH3(!h3)}/>} H3</p>
                    </div>

                </div>
                :null}

                <div style={{display: 'flex', marginTop: 50, marginLeft: 5}}>

                    {/* volet gauche */}
                    <div style={{flex: 0.05}}>

                    </div>

                    {/* volet central */}
                    <div style={{flex: 1, backgroundColor: 'rgba(52, 52, 52, alpha)', padding: 10}}>

                        <div className='shadow-md rounded-md' style={{flex: 1, borderRadius: 5}}>
                            <div style={{display: 'flex', backgroundColor: blue}}>
                                <div style={{flex: 1}}>
                                </div>

                                <div style={{textAlign: 'center', display: 'flex', flex: 3}}>
                                    {years.length !== 0?
                                    years.map((year, i) =>
                                    <div key={i} style={{flex: 1, borderLeft: '1px solid white'}}>
                                        <p style={{color: 'white', margin: 5}}>{year}</p>
                                    </div>
                                    )
                                    :null}
                                </div>

                            </div>
                            
                            <div style={{display: 'flex', borderTop: '1px solid white'}}>
                                <div style={{flex: 1, backgroundColor: greyChapter}}>
                                    <p style={{margin: 5}}>Taux d'intérêts prévisionnels</p>
                                </div>

                                <div style={{textAlign: 'center', display: 'flex', flex: 3}}>
                                    {txi.length !== 0?
                                    txi.map((year, i) =>
                                    <div key={i} style={{flex: 1, display: 'flex', borderLeft: '1px solid white'}}>
                                        <input
                                        type="float"
                                        style={{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', backgroundColor: smoothGrey}}
                                        onBlur={async (e) => {
                                            var val = e.target.value.replace(',', '.');
                                            if (parseFloat(val) || e.target.value === '0') {
                                                update('txi', year[0], val);
                                            }
                                            e.target.value = ''
                                        }}
                                        placeholder={addSpace(year[1])+' %'}
                                        />
                                    </div>
                                    )
                                    :null}
                                </div>
                                
                            </div>
                            
                            <div style={{display: 'flex', borderTop: '1px solid white'}}>
                                <div style={{flex: 1, backgroundColor: greyChapter}}>
                                    <p style={{margin: 5}}>Taux d'amortissement</p>
                                </div>

                                <div style={{textAlign: 'center', display: 'flex', flex: 3}}>
                                    {txa.length !== 0?
                                    txa.map((year, i) =>
                                    <div key={i} style={{flex: 1, display: 'flex', borderLeft: '1px solid white'}}>
                                        <input
                                        type="float"
                                        style={{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right'}}
                                        onBlur={async (e) => {
                                            var val = e.target.value.replace(',', '.');
                                            if (parseFloat(val) || e.target.value === '0') {
                                                update('txa', year[0], val);
                                            }
                                            e.target.value = ''
                                        }}
                                        placeholder={addSpace(year[1])+' %'}
                                        />
                                    </div>
                                    )
                                    :null}
                                </div>
                                
                            </div>

                            <div style={{display: 'flex', borderTop: '1px solid white'}}>
                                <div style={{flex: 1, backgroundColor: greyChapter}}>
                                    <p style={{margin: 5}}>Durée totale (en années)</p>
                                </div>

                                <div style={{textAlign: 'center', display: 'flex', flex: 3}}>
                                    {duree.length !== 0?
                                    duree.map((year, i) =>
                                    <div key={i} style={{flex: 1, display: 'flex', borderLeft: '1px solid white'}}>
                                        <input
                                        type="float"
                                        style={{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', backgroundColor: smoothGrey}}
                                        onBlur={async (e) => {
                                            if (parseInt(e.target.value)) {
                                                var val = parseInt(e.target.value);
                                                if (val >= 0) {
                                                    update('duree', year[0], val);
                                                }
                                            }
                                            e.target.value = ''
                                        }}
                                        placeholder={parseInt(year[1])===1?year[1]+' an':year[1]+' ans'}
                                        />
                                    </div>
                                    )
                                    :null}
                                </div>
                                
                            </div>

                            <div style={{display: 'flex', borderTop: '1px solid white'}}>
                                <div style={{flex: 1, backgroundColor: greyChapter}}>
                                    <p style={{margin: 5}}>Durée du différé d'amortissement (en années)</p>
                                </div>

                                <div style={{textAlign: 'center', display: 'flex', flex: 3}}>
                                    {dureeDiff.length !== 0?
                                    dureeDiff.map((year, i) =>
                                    <div key={i} style={{flex: 1, display: 'flex', borderLeft: '1px solid white'}}>
                                        <input
                                        type="float"
                                        style={{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right'}}
                                        onBlur={async (e) => {
                                            if ((parseInt(e.target.value) || e.target.value === '0')) {
                                                var val = parseInt(e.target.value);
                                                if (val >= 0) {
                                                    update('dureeDiff', year[0], val);
                                                }
                                            }
                                            e.target.value = ''
                                        }}
                                        placeholder={parseInt(year[1])<=1?year[1]+' an':year[1]+' ans'}
                                        />
                                    </div>
                                    )
                                    :null}
                                </div>
                                
                            </div>

                            <div style={{display: 'flex', borderTop: '1px solid white'}}>
                                <div style={{flex: 1, backgroundColor: greyChapter}}>
                                    <p style={{margin: 5}}>Résultats de clôture souhaité (en jours de dépenses totales)</p>
                                </div>

                                <div style={{textAlign: 'center', display: 'flex', flex: 3}}>
                                    {resClo.length !== 0?
                                    resClo.map((year, i) =>
                                    <div key={i} style={{flex: 1, display: 'flex', borderLeft: '1px solid white'}}>
                                        <input
                                        type="float"
                                        style={{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', backgroundColor: smoothGrey}}
                                        onBlur={async (e) => {
                                            if (parseInt(e.target.value) || e.target.value === '0') {
                                                var val = parseInt(e.target.value);
                                                update('resClo', year[0], val);
                                            }
                                            e.target.value = ''
                                        }}
                                        placeholder={parseInt(year[1])<=1 && parseInt(year[1])>=-1?year[1]+' jour':year[1]+' jours'}
                                        />
                                    </div>
                                    )
                                    :null}
                                </div>
                                
                            </div>
                        </div>


                        <div style={{display: 'flex', marginTop: 25}}>
                            <div style={{flex: 1}}></div>

                            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {loading === false?
                                <button
                                    className='shadow-md rounded-md'
                                    style={{backgroundColor: green, color: 'white', marginRight: 20, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                                    onClick={async () => {
                                        if (h1 || h2 || h3)
                                        {
                                            await setDialogModalVisible(true)
                                        }
                                        else {
                                            NotificationManager.warning("Veuillez cocher au moins une hypothèse", '', 3000);
                                        }
                                    }}
                                >
                                    <Check width="12"/>
                                    <p style={{marginLeft: 5}}>Valider</p>
                                </button>
                                :
                                <BounceLoader color={purple} />
                                }
                            </div>

                            <div style={{flex: 1}}></div>
                        </div>

                    </div>

                    {/* volet droit */}
                    <div style={{flex: 0.05, display: 'flex', justifyContent: 'center'}}>

                    </div>

                </div>

            </Access>
        </div>
	);
}

export default CalculEmpruntsAuto;
