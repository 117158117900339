import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { addSpace } from "./Tools";

function IncidencesFinancieres(props) {
    
    // const [hypothese, setHypothese] = useState('h1')
    const [objet, setObjet] = useState([]);

    const smoothGrey = '#f4f4f4';
    const green = '#59B44A';
    const blue = '#069BD3';
    const purple = '#6571FE';
    const greyChapter = '#DBDBDB';

    async function refresh()
    {
        await axios.post('https://app-slfinance.fr/api/incidences_financieres.php', qs.stringify({
            'getSumIncidencesFinancieres': true,
            'annees': props.annees,
            'id_budget': props.user.id_budget,
            'hypothese': 'h'+props.user.hypothese
        }))
        .then(async (res) => {
            var total = [];
            await res.data.forEach((element, i) => {
                total[i] = 0;
                element.forEach((elem, j) => {
                    if (j > 0) {
                        elem[1] = parseFloat(elem[1]);
                        total[i] += (elem[1]);
                    }
                });
            });
            await res.data.forEach((line, k) => {
                res.data[k].push(['Total', total[k]])
            });

            await setObjet(res.data)
        })
    }

    useEffect(() => {
        if (props.user.id_budget !== null && props.annees !== null) {
            refresh();
        }
    }, [props.hypothese, props.aff])

    return (
        <div>
            <div style={{display: 'flex', marginLeft: 0, marginRight: 0, marginTop: 15, backgroundColor: blue, color: 'white'}}>
                <div style={{flex: 1, padding: 5}}>
                    <p></p>
                </div>
                {props.annees.map((annee, i) => 
                <div key={i} style={{flex: 0.5, borderLeft: '1px solid white'}}>
                    <p>{annee}</p>
                </div>)}
                <div style={{flex: 0.5, borderLeft: '1px solid white', fontWeight: 'bold', backgroundColor: '#2980b9'}}>
                    <p>Total</p>
                </div>
            </div>
            <div className='shadow-2xl' style={{flex: 1, border: '1px solid '+blue}}>
                {objet.length !== 0?
                objet.map((line, i) =>
                <div key={i} style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?i === 0?{display: 'flex'}:{display: 'flex', borderTop: '1px solid '+blue}:{display: 'flex', borderTop: '1px solid '+blue}}>
                    {line.map((val, j) =>
                        j === 0?
                        <div key={j} style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{flex: 1, padding: 5, minWidth: 50, backgroundColor: greyChapter}:{flex: 1, padding: 5, minWidth: 50, backgroundColor: smoothGrey}}>
                            <p style={line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{fontWeight: 'bold'}:{textAlign: 'right', fontSize: 15}}>{val.replace('_', "'")}</p>
                        </div>
                        :
                        j > 0?
                        <div key={j} style={
                            val[0] !== 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                            {flex: 0.5, display: 'flex', fontSize: 14, borderLeft: '1px solid '+blue, minWidth: 50, backgroundColor: greyChapter}
                            :
                            val[0] === 'Total' && (line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement")?
                            {flex: 0.5, display: 'flex', fontSize: 14, borderLeft: '1px solid '+blue, minWidth: 50, backgroundColor: '#BABABA', fontWeight: 'bold'}
                            :
                            val[0] === 'Total'?
                            {flex: 0.5, display: 'flex', fontSize: 14, borderLeft: '1px solid '+blue, minWidth: 50, backgroundColor: greyChapter, fontWeight: 'bold'}
                            :
                            {flex: 0.5, display: 'flex', fontSize: 14, borderLeft: '1px solid '+blue, minWidth: 50}
                        }>
                            <input
                            type="float"
                            style={val[0] === 'Total' || line[0] === "Dépenses de fonctionnement" || line[0] === "Recettes de fonctionnement" || line[0] === "Dépenses d_investissement" || line[0] === "Recettes d_investissement"?{flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50, fontWeight: 'bold'}:{flex: 1, border: '0px solid black', textAlign: 'right', paddingRight: 5, minWidth: 50}}
                            value={val[1] === null || val[1] === '' || val[1] === '0' || val[1] === 0?'':addSpace(Math.round(val[1] * 100) / 100, true)}
                            disabled
                            />
                        </div>
                        :null
                    )}
                </div>)
                :null}

            </div>

        </div>
    );
}

export default IncidencesFinancieres;