import React from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';


const Page3_PDF_Description = (props) =>
{
    const blue = '#069BD3';
    const greyChapter = '#DBDBDB';
    const greyclair = '#FAFAFA';

    return (
            <Page size="A4" style={{flexDirection: 'column', backgroundColor: 'white', padding: 20, fontSize: 11}} orientation="landscape">
                
                {/* Header - Logo */}
                <View style={{flexDirection: 'row'}}>
                    <View style={{flex: 0.5, flexDirection: 'row'}}>
                        {props.logo && <Image style={{maxHeight: 75, borderRadius: 5}} src={props.logo} alt="Logo" />}
                    </View>
                    <View style={{flex: 1,justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{fontSize: 16, color: blue}}>{rescueHtml(props.projet[3]?props.projet[3]:'')}</Text>
                    </View>
                    <View style={{flex: 0.5, flexDirection: 'row', fontSize: 14}}>
                        <View style={{flex: 1}}></View>
                        <Text style={{color: 'grey'}}>{props.nom?props.nom:''}</Text>
                    </View>
                </View>

                {/* Container */}

                {/* Ressources humaines */}
                <View style={{flexDirection: 'row', height: 80, marginTop: 15}}>
                    <View style={{flex: 0.1, padding: 10, backgroundColor: greyChapter, minWidth: 110, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <Text>Ressources humaines</Text>
                    </View>
                    <View style={{flexDirection: 'row', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <Text style={{flex: 1, padding: 5}}>
                            {props.projet[19]?rescueHtml(props.projet[19]):''}
                        </Text>
                    </View>
                </View>
                {/****/}

                {/* Études */}
                <View style={{flexDirection: 'row', height: 80, marginTop: 5}}>
                    <View style={{flex: 0.1, padding: 10, backgroundColor: greyChapter, minWidth: 110, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <Text>Études</Text>
                    </View>
                    <View style={{flexDirection: 'row', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <Text style={{flex: 1, padding: 5}}>
                            {props.projet[20]?rescueHtml(props.projet[20]):''}
                        </Text>
                    </View>
                </View>
                {/****/}

                {/* Outils, matériels */}
                <View style={{flexDirection: 'row', height: 80, marginTop: 5}}>
                    <View style={{flex: 0.1, padding: 10, backgroundColor: greyChapter, minWidth: 110, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <Text>Outils, matériels</Text>
                    </View>
                    <View style={{flexDirection: 'row', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <Text style={{flex: 1, padding: 5}}>
                            {props.projet[21]?rescueHtml(props.projet[21]):''}
                        </Text>
                    </View>
                </View>
                {/****/}

                {/* Investissements (moyens internes/externes) */}
                <View style={{flexDirection: 'row', height: 80, marginTop: 5}}>
                    <View style={{flex: 0.1, padding: 10, backgroundColor: greyChapter, minWidth: 110, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <Text>Investissements{'\n'}(moyens internes/externes)</Text>
                    </View>
                    <View style={{flexDirection: 'row', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <Text style={{flex: 1, padding: 5}}>
                            {props.projet[22]?rescueHtml(props.projet[22]):''}
                        </Text>
                    </View>
                </View>
                {/****/}

                {/* Incidences organisationnelle et administrative */}
                <View style={{flexDirection: 'row', height: 80, marginTop: 5}}>
                    <View style={{flex: 0.1, padding: 10, backgroundColor: greyChapter, minWidth: 110, borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                        <Text>Incidences organisationnelle et administrative</Text>
                    </View>
                    <View style={{flexDirection: 'row', flex: 1, backgroundColor: greyclair, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <Text style={{flex: 1, padding: 5}}>
                            {props.projet[23]?rescueHtml(props.projet[23]):''}
                        </Text>
                    </View>
                </View>
                {/****/}
   
    
    
                {/* Footer */}
                <View style={{position: 'absolute', bottom: 20, left: 20}}>
                    <Text style={{fontSize: 10}}>{props.date}</Text>
                </View>
    
                <View style={{position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-25%)'}}>
                    <Text style={{fontSize: 10}}>page : {props.pagination} / {props.countPageTotal}</Text>
                </View>
    
                <View style={{position: 'absolute', bottom: 20, right: 20}}>
                    <Image style={{width: 150, borderRadius: 5}} src={Logo} />
                </View>

            </Page>
    );
}
  
  export default Page3_PDF_Description;