import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Modal from 'react-modal';
import BounceLoader from "react-spinners/BounceLoader";
import { useHistory } from 'react-router-dom';
import { rescueHtml, feedRei, siphonnage } from './Tools';
import { ReactComponent as Download } from '../Img/icon-download.svg';
import {NotificationManager} from 'react-notifications';
import Dialog from "./Dialog";

const SiphonnageDataOrg = (props) =>
{
    const purple = '#6571FE';
    const green = '#59B44A';

    let history = useHistory();
    const [organismes, setOrganismes] = useState([]);
    const [budgets, setBudgets] = useState([]);
    const [id_budget, setId_budget] = useState('');
    const [siren, setSiren] = useState('');
    const [siret, setSiret] = useState('');
    const [id_organisme, setId_organisme] = useState('');
    const [debut, setDebut] = useState(undefined);
    const [fin, setFin] = useState(undefined);
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    function callbackDialog(ret) {
        if (ret === true) {
            CleanBudget();
        }
    }


    async function mount() {
        await axios.post('https://app-slfinance.fr/api/application.php', qs.stringify({'get': true, 'parametre': 'retro_an_deb'}))
        .then((res) => {
            setDebut(res.data.retro_an_deb);
        })
        await axios.post('https://app-slfinance.fr/api/application.php', qs.stringify({'get': true, 'parametre': 'retro_an_fin'}))
        .then((res) => {
            setFin(res.data.retro_an_fin);
        })

        await axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true, 'ASC': true}))
        .then((res) => {
            var tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                tmp[i] = {
                    id: res.data[i].id,
                    nom: rescueHtml(res.data[i].nom),
                    siren: res.data[i].siren,
                    code_geo: res.data[i].code_geo,
                    ComGfp: res.data[i].id_organisme_type === '1'?'COM':'GFP',
                }
            }
            setOrganismes(tmp);
        })
        .catch((err) => {
            console.log(err)
        })
    }


    useEffect(() => {
        mount();
    }, [])


    function siphonnage_get_params(params)
    {
        axios.post("https://app-slfinance.fr/api/datas_brut.php", qs.stringify({
            'siphonnage_get_params': true,
            'id_organisme': params.id_organisme,
            'id_budget': params.id_budget,
            'debut': params.debut,
            'fin': params.fin,
        }))
        .then(async (res) => {
            await siphonnage(res.data);
            await setTimeout(() => window.location = window.location.href, 500);
        })
        .catch((err) => {
            console.log(err)
        })
    }


    function CleanBudget()
    {
        var d = parseInt(debut);
        var f = parseInt(fin);
        
        if (id_budget !== '' && siret !== '' && id_organisme !== '' && debut !== '' && fin !== '' && d <= f && d >= 2013)
        {
            setSpinner(true);
            axios.post("https://app-slfinance.fr/api/datas_brut.php", qs.stringify({'clean_budget_pour_siphonnage': true, 'id_budget': id_budget, 'debut': debut, 'fin': fin}))
            .then(async (res) => {
                if (res.data['status'] === 1) {
                    var params = {
                        'id_organisme': id_organisme,
                        'id_budget': id_budget,
                        'siret': siret,
                        'debut': d,
                        'fin': f,
                    };
                    await siphonnage_get_params(params);
                }
                else {
                    NotificationManager.warning(res.data['status_message'], '', 5000);
                }
            })
            .catch((err) => {
                console.log(err)
            })

        }
        else {
            if (debut === '' || fin === '') {
                NotificationManager.warning("Veuillez renseigner tous les champs", '', 5000);
            }
            if (debut !== '' && fin !== '' && f < d) {
                NotificationManager.warning("L'année de fin ne pas être inferieure à l'année de debut", '', 5000);
            }
            if (debut !== '' && fin !== '' && d < 2013) {
                NotificationManager.warning("L'année de debut ne peut être inferieure à 2014", '', 5000);
            }
        }
    }

	return (
        <div className='shadow-xl' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>

            <Dialog
                setModalVisible={setDialogModalVisible}
                modalVisible={dialogModalVisible}
                callbackDialog={callbackDialog}
                message={"Êtes-vous sûr de vouloir écraser les données existantes ?"}
            />

            <Modal isOpen={spinner} style={customStyles} ariaHideApp={false}>
                <BounceLoader color={purple} />
            </Modal>

            <p>Siphonnage Organisme :</p>
            <div style={{margin: 10, textAlign: 'center'}}>
                <select
                style={{padding: 5}}
                    className='shadow-md'
                    value={id_organisme}
                    onChange={async e => {
                        await setId_organisme(e.target.value);

                        await axios.get("https://app-slfinance.fr/api/budgets.php?id_organisme="+e.target.value+'&list=true')
                        .then(async (result) => {
                            var tmp = []
                            var i = 0
                            await result.data.forEach(async budget => {
                                if (budget.budget_annexe === '0') {
                                    tmp[i] = {
                                        'id_budget': budget.id,
                                        'budget_annexe': budget.budget_annexe,
                                        'nom': budget.nom,
                                        'siret': budget.siret
                                    }
                                    i++;
                                }
                            });
                            
                            await result.data.forEach(async budget => {
                                if(budget.budget_annexe === '1') {
                                    tmp[i] = {
                                        'id_budget': budget.id,
                                        'budget_annexe': budget.budget_annexe,
                                        'nom': budget.nom,
                                        'siret': budget.siret
                                    }
                                    i++;
                                }
                            });
                            await setId_budget(tmp[0].id_budget)
                            await setSiret(tmp[0].siret)
                            await setBudgets(tmp);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    }}
                >
                    <option value="">...</option>
                    {
                        organismes.map(
                        (organisme) =>
                            <option key={organisme['id']} value={organisme['id']} >{organisme['nom']}</option>
                        )
                    }
                </select>

                {id_organisme != '' && budgets.length != 0?
                <div style={{marginTop: 10, textAlign: 'center'}}>
                    <div>
                        <select
                        className='shadow-md'
                        value={id_budget}
                        onChange={async (e) => {
                            await setId_budget(e.target.value);
                        }}>
                            {budgets.map((elem, i) =>
                            <option key={i} value={elem.id_budget}>{elem.nom}</option>
                            )}
                        </select>
                    </div>

                    <div style={{marginTop: 20}}>
                        <p>Années à siphonner :</p>
                    </div>
                    <div style={{display: 'flex'}}>
                        <input className='shadow-md' style={{flex: 1, margin: 5, textAlign: 'center'}} placeholder='Debut' value={debut}
                        onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 9999) {
                                setDebut(e.target.value);
                            }
                        }}
                        />
                        <input className='shadow-md' style={{flex: 1, margin: 5, textAlign: 'center'}} placeholder='Fin' value={fin}
                        onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 9999) {
                                setFin(e.target.value);
                            }
                        }}
                        />
                    </div>


                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <button
                        style={{marginTop: 10, display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}
                        onClick={() => {setDialogModalVisible(true)}}
                        >
                            <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                                <Download width="12" />
                                <p style={{marginLeft: 5}}>Siphonnage !</p>
                            </div>
                        </button>
                        <div style={{flex: 1}}></div>
                    </div>

                </div>
                :null}
            </div>
        </div>
	);
}

const customStyles = {
    content: {
        flex: 1,
        border: '0px solid rgba(0, 0, 0, 0)',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.01)'
    },
};

export default SiphonnageDataOrg;
