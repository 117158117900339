import React, { useState, useEffect } from 'react';
import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import axios from 'axios';
import qs from 'qs';
import { rescueHtml } from "../components/Tools";
import Logo from '../Img/Logo_projets&finances.png';


const Page4Echeancier = (props) =>
{
    const green = '#59B44A';
    
    const [aff, setAff] = useState(false);
    const [data, setData] = useState(undefined);
    const heightTask = (props.height*30/950);
    const fontsize = (props.height*12/890);
    const [sizecolone, setSizecolone] = useState(props.width/4);


    async function getDatas(id_budget, id_projet) {
        await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
            'getTasks': true,
            'id_projet': id_projet,
            'id_budget': id_budget
        }))
        .then(async (res) => {
            let tmp = [];
            let minStartDate = null;
            let maxEndDate = null;

            if (res.data) {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    const startDate = element.start ? new Date(element.start) : new Date();
                    const endDate = element.end ? new Date(element.end) : new Date();

                    if (!minStartDate || startDate < minStartDate) {
                        minStartDate = startDate;
                    }
                    if (!maxEndDate || endDate > maxEndDate) {
                        maxEndDate = endDate;
                    }

                    await tmp.push({
                        start: startDate,
                        end: endDate,
                        name: element.name || "Sans nom",
                        id: element.id,
                        type: element.type || "task",
                        progress: element.progress || 0,
                        styles: {
                            backgroundColor: element.color,
                            backgroundSelectedColor: element.color,
                            progressColor: green,
                            progressSelectedColor: green,
                        },
                        dependencies: [parseInt(element.dependencies)],
                        commentaire: element.commentaire,
                    });
                }
            }

            if (minStartDate && maxEndDate) {
                const durationInDays = Math.ceil((maxEndDate - minStartDate) / (1000 * 60 * 60 * 24));
                console.log(durationInDays);

                // Ajuster la valeur de sizecolone en fonction de la durée totale
                let newSizecolone;
                if (durationInDays > 900) {
                    newSizecolone = props.width / 15; // Par exemple, plus de jours, plus petites colonnes
                } else if (durationInDays > 500) {
                    newSizecolone = props.width / 11; // Par exemple, plus de jours, plus petites colonnes
                } else if (durationInDays > 300) {
                    newSizecolone = props.width / 8;
                } else {
                    newSizecolone = props.width / 3; // Moins de jours, plus grandes colonnes
                }
                setSizecolone(newSizecolone);
            }

            await setData(tmp);
            await setAff(!aff);
        })
    }


    useEffect(() => {
        if (props.user) {
            getDatas(props.user.id_budget, props.projet[0]);
        }
    }, [])

    return (
            data && data.length !== 0 && props.user && props.projet[0]?
            <Gantt
            todayColor={'#c8eae9'}
            handleWidth={5}
            rowHeight={heightTask}
            tasks={data}
            locale={'fr'}
            preStepsCount={0}
            columnWidth={sizecolone}
            TaskListHeader={
                () => (
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        fontSize: (fontsize+2),
                        backgroundColor: '#f5f5f5',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        minWidth: props.width/4,
                        height: 50,
                        borderBottom: '1px solid white',
                        borderLeft: '1px solid white',
                        borderTop: '1px solid white'
                    }}>
                        {/* <span style={{flex: 1}}>Intitulé</span> */}
                        <span style={{flex: 1}}>Debut</span>
                        <span style={{flex: 1}}>Fin</span>
                    </div>
                )
            }
            TaskListTable={
                (e) => (
                    e.tasks.map((task) =>
                    <div key={task.id} style={{
                        flex: 1,
                        display: 'flex',
                        fontSize: (fontsize+2),
                        backgroundColor: '#f5f5f5',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        minWidth: props.width/4,
                        height: heightTask,
                        borderBottom: '1px solid white',
                        borderLeft: '1px solid white',
                        borderTop: '1px solid white'
                }}>
                        {/* <span style={{flex: 1, fontSize: (fontsize+2), marginLeft: 5}}>{task.name.substring(0, 25)+(task.name.length>25?'..':'')}</span> */}
                        <span style={{flex: 1}}>{task.start.getDate()<10?'0'+task.start.getDate():task.start.getDate()}/{(task.start.getMonth()+1)<10?'0'+(task.start.getMonth()+1):(task.start.getMonth()+1)}/{task.start.getFullYear()}</span>
                        <span style={{flex: 1}}>{task.end.getDate()<10?'0'+task.end.getDate():task.end.getDate()}/{(task.end.getMonth()+1)<10?'0'+(task.end.getMonth()+1):(task.end.getMonth()+1)}/{task.end.getFullYear()}</span>
                    </div>
                ))
            }
            viewMode={'Year'}
            />
            :
            <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p>Cette fiche projet n'a aucune tâche renseignée.</p>
            </div>
    );
}
  
  export default Page4Echeancier;