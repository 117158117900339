import React from 'react';
import Logo from '../Img/Logo_projets&finances.png';
import BilanDepensesEquipement from '../Graph/Bilan/BilanDepensesEquipement';
import BilanTauxSubvMoy from '../Graph/Bilan/BilanTauxSubvMoy';
import BilanDepInvSurDepTot from '../Graph/Bilan/BilanDepInvSurDepTot';
import { rescueHtml } from '../components/Tools.js';

const Page12Graph = (props) => {
    const blue = '#069BD3';
    const smoothGrey = '#f4f4f4';

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={Logo} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginTop: 25, height: '80%'}}>
                <div style={{flex: 1, display: 'flex', margin: 25}}>
                    <div style={{flex: 1, margin: 5, marginTop: 50}} className='shadow-md rounded'>
                        <BilanDepensesEquipement user={props.user} annees={props.annees} height={400}/>
                    </div>
                    <div style={{flex: 1, margin: 5, marginTop: 50}} className='shadow-md rounded'>
                        <BilanTauxSubvMoy user={props.user} annees={props.annees} height={400}/>
                    </div>
                    <div style={{flex: 1, margin: 5, marginTop: 50}} className='shadow-md rounded'>
                        <BilanDepInvSurDepTot user={props.user} annees={props.annees} height={400}/>
                    </div>
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
};

export default Page12Graph;
